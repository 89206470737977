<br>
<!-- <div style="float: left; height: 100%;">
    <div *ngFor="let bet of betsTaken; let i = index;">
        <app-model-detail-performance-circle-view [color]="colors[i]" [value]="bet.winPercentage" [units]="bet.units" [name]="bet.bet"></app-model-detail-performance-circle-view>
    </div>
</div> -->


<div class="grid-container" *ngIf="betsTaken && performanceLoaded && hasGames">
    <div class="circle1">
        <app-model-detail-performance-circle-view [color]="colors[0]" [value]="betsTaken[0].winPercentage" [units]="betsTaken[0].units" [name]="betsTaken[0].bet"></app-model-detail-performance-circle-view>
    </div>
    <div class="circle2" *ngIf="betsTaken[1]">
        <app-model-detail-performance-circle-view [color]="colors[1]" [value]="betsTaken[1].winPercentage" [units]="betsTaken[1].units" [name]="betsTaken[1].bet"></app-model-detail-performance-circle-view>
    </div>
    <div class="betsTaken">
        <!-- <p>Bets taken: {{betsTakenString}}</p> -->
        <p>{{betsTaken[0].bet}}: {{betsTaken[0].wins}}-{{betsTaken[0].losses}}-{{betsTaken[0].pushes}}</p>
        <p *ngIf="betsTaken[1]">{{betsTaken[1].bet}}: {{betsTaken[1].wins}}-{{betsTaken[1].losses}}-{{betsTaken[1].pushes}}</p>
    </div>
    <div class='individualSeason' *ngIf="chartComplete">
        <div style="display: block;">
            <canvas baseChart id="performanceChart"
                    [datasets]="chartData"
                    [labels]="chartLabels"
                    [options]="chartOptions"
                    [legend]="chartLegend"
                    [chartType]="chartType"
                    height="250"
                    width="200">
            </canvas>
        </div>
  </div>
</div>

<div *ngIf="betsTaken && performanceLoaded && !hasGames">
    No games meet model requirements!
</div>

<div class='spinner' *ngIf="!performanceLoaded">
    <mat-spinner></mat-spinner>
</div>


<!-- <div id="chartContainer" style="height: 300px; width: 70%; float: left;" ></div> -->


<!-- <div class="progress">
	<svg class="progress__svg" width="120" height="120" viewBox="0 0 120 120">
		<circle class="progress__meter" cx="60" cy="60" [attr.r]="radius" stroke-width="12" />
		<circle class="progress__value" [style.strokeDasharray]="circumference" [style.strokeDashoffset]="dashoffset" cx="60" cy="60"
		 [attr.r]="radius" stroke-width="12" />
	</svg>

  <div class="progress__value-text">{{value}}%</div>
</div> -->