import { Component, OnInit } from '@angular/core';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { ThisReceiver } from '@angular/compiler';

@Component({
  selector: 'app-description-selector',
  templateUrl: './description-selector.component.html',
  styleUrls: ['./description-selector.component.css']
})
export class DescriptionSelectorComponent implements OnInit {

  description;
  charactersRemaining;
  charactersRemainingText;
  maxCharacters = 150;
  constructor(private modelPickerService: ModelPickerService) { }

  ngOnInit(): void {
    this.modelPickerService.currentModel.subscribe(model => {
      this.description = model.modelDescription;
    }, error => {
      //console.log("Error getting model description");
    })
  }

  updateDescription() {
    this.charactersRemaining = this.maxCharacters - this.description.length;
    var x = document.getElementById("characterCount");
    if (this.charactersRemaining >= 0) {
      if (x) {
        x.className = "characterCount under";
      }
    } else {
      if (x) {
        x.className = "characterCount over";
      }
    }

    this.charactersRemainingText = this.charactersRemaining + " character(s) remaining";
    this.modelPickerService.setModelDescription(this.description);
  }

}
