import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StandingsService {

  constructor(private http: HttpClient) { }

  getStandingsForStat(league, statName, year) {
    //console.log("inside create model.");
    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/leagues/" + league + "/stats/" + statName + "/" + year;

    return this.http.get<any>(url, {});
  }
}
