import { Component, OnInit, Input, ViewChild, AfterContentInit, AfterViewInit } from '@angular/core';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { ModelDetailPerformanceViewComponent } from '../model-detail-performance-view/model-detail-performance-view.component';
import { EditModelRequirementsViewComponent } from '../edit-model-requirements-view/edit-model-requirements-view.component';
import { MatTabGroup } from '@angular/material/tabs';
import { ModelDetailGameViewComponent } from '../model-detail-game-view/model-detail-game-view.component';

@Component({
  selector: 'app-model-detail-tab-view',
  templateUrl: './model-detail-tab-view.component.html',
  styleUrls: ['./model-detail-tab-view.component.css']
})
export class ModelDetailTabViewComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    //throw new Error("Method not implemented.");
    // this.tabGroup.selectedIndexChange.subscribe(index => {
    //   //console.log("selected Index changed?: ", index);
    //   if (index == 1) {
    //     //this.modelDetailPerformanceView.onTabChange();
    //   } else if (index == 2) {
    //     this.modelDetailGameView.onTabChange();
    //   }
    // })
  }
  @ViewChild(ModelDetailPerformanceViewComponent) modelDetailPerformanceView: ModelDetailPerformanceViewComponent
  @ViewChild(ModelDetailGameViewComponent) modelDetailGameView: ModelDetailGameViewComponent;
  @ViewChild("tabGroup") tabGroup: MatTabGroup
  selectedTab;
  //private modelDetailPerformanceView: ModelDetailPerformanceViewComponent;
  constructor() { }

  ngOnInit() {
    //this.selectedTab = 0;
    
  }

  viewPerformanceTab() {
    this.tabGroup.selectedIndex = 0;
    //this.modelDetailPerformanceView.onRunTabChange();
  }

  changeTab() {
    //console.log("Changing tab");
  }
}
