import { Component, OnInit, Input } from '@angular/core';
import { StandingsService } from 'src/app/services/standings.service';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { LeagueService } from 'src/app/services/league.service';
import { MatDialog } from '@angular/material/dialog';
import { StatStandingsPopupComponent } from '../stat-standings-popup/stat-standings-popup.component';

@Component({
  selector: 'app-requirement-category-picker',
  templateUrl: './requirement-category-picker.component.html',
  styleUrls: ['./requirement-category-picker.component.css']
})
export class RequirementCategoryPickerComponent implements OnInit {

  @Input() title;
  @Input() statName;
  @Input() image;

  teamsAvailable;
  standings;
  constructor(public dialog: MatDialog, private leagueService: LeagueService, private modelPickerService: ModelPickerService, private standingsService: StandingsService) { }

  ngOnInit(): void {
    this.teamsAvailable = [];
    var leagueTeams = this.leagueService.getTeams(this.modelPickerService.getModelLeague());
    //console.log("Teams available: ", this.teamsAvailable)
    Object.keys(leagueTeams).forEach (team => {
      var teamName = team;
      var logo = leagueTeams[team]['logo'];
      this.teamsAvailable.push({
        'teamName': teamName,
        'logo': logo
      })
    })

    var standingsList: any[] = [];

    if (this.title.includes("Rank")) {
      this.standingsService.getStandingsForStat(this.modelPickerService.getModelLeague(), this.statName, this.leagueService.getLastSeasonAvailable(this.modelPickerService.getModelLeague())).subscribe(data => {
        console.log("Standings loaded: " + data);
        var standings = data.standings;
        for (var i = 0; i < standings.length; i ++) {
          var teamObject = standings[i];
          var teamValue = Math.round((teamObject['teamValue'] * 10000)) / 10000;
          teamObject['teamValue'] = teamValue;
          var teamLogo = this.getTeamLogo(teamObject['teamName']);
          teamObject['teamLogo'] = teamLogo;
          standingsList.push(teamObject);
        }
      })

      this.standings = standingsList;
    }
  }

  getTeamLogo(teamName) {
    for (var i = 0; i < this.teamsAvailable.length; i ++) {
      var team = this.teamsAvailable[i];
      if (teamName == team['teamName']) {
        return team['logo'];
      }
    }
  }

  showStandings(event) {
    event.stopPropagation();
    this.dialog.open(StatStandingsPopupComponent, {
      data: {
        "standings": this.standings,
        "stat": this.title
      }
    });
  }
}