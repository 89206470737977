import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  constructor(private http: HttpClient) { }

  getBatchGameInfo(user, league, gameIds, fieldsToGrab) {
    var headers = {
      "Authorization": user.signInUserSession.idToken.jwtToken
    }

    var body = {
      "league": league,
      "gameIds": gameIds,
      "fieldsToGrab": fieldsToGrab
    }

    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/games/getbatchgameinfo";

    return this.http.post<any>(url, body, {headers});
  }

  getGameDetails(league, gameId) {
    var encodedGameId = btoa(gameId);

    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/games/" + league + "/" + encodedGameId;

    return this.http.get<any>(url, {});
  }

  getUpcomingGamesForLeague(league) {
    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/games/" + league + "/getupcominggames";

    return this.http.get<any>(url, {});
  }

  getLiveGamesForLeague(league) {
    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/games/" + league + "/getlivegames";

    return this.http.get<any>(url, {});
  }
}
