import { Component, OnInit } from '@angular/core';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { ModelService } from 'src/app/services/model.service';

@Component({
  selector: 'app-model-detail-performance-tab-view',
  templateUrl: './model-detail-performance-tab-view.component.html',
  styleUrls: ['./model-detail-performance-tab-view.component.css']
})
export class ModelDetailPerformanceTabViewComponent implements OnInit {
  modelDetails;
  modelPerformance;
  chart;
  betsTaken;
  betsTakenList;
  modelBetsTaken;
  betsTakenString;
  wagerString
  colors;
  performanceLoaded;
  hasGames;
  seasonSelected;
  gamesMap;
  finalGamesArray;
  gamesStored;
  seasonShowing;
  constructor(private modelService: ModelService, private modelPickerService: ModelPickerService) { }

  ngOnInit(): void {
    this.gamesMap = {}
    this.modelPickerService.currentModel.subscribe(model => {
      console.log("Model found in performance tab view: ", model);
      if (model.modelConfiguration &&  !('modelPerformance' in model)) {
        this.modelDetails = model.modelConfiguration;
      } else {
        this.modelDetails = model;
      }

      var wager = model.wager;
      if (wager == "TO_WIN") {
        this.wagerString = "to win";
      } else if (wager == "TO_WAGER") {
        this.wagerString = "to wager";
      }

      var modelPerformance = model.modelPerformance;
      if (modelPerformance) {
        if (modelPerformance.overallProfitability.length > 0) {
          this.hasGames = true;
        } else {
          this.hasGames = false;
        }

        this.gamesStored = model.gamesStored;
        this.seasonShowing = this.gamesStored[0];
        this.modelPerformance = modelPerformance;
        this.performanceLoaded = true;
      } else {
        this.performanceLoaded = false;
      }

      this.betsTakenList = this.modelDetails.betsTaken;

      this.finalGamesArray = new Array();

      var previousGamesStored = this.gamesStored;

      const equals = (a, b) => {
        if (a.length !== b.length) return false;
        const uniqueValues = new Set([...a, ...b]);
        for (const v of uniqueValues) {
          const aCount = a.filter(e => e === v).length;
          const bCount = b.filter(e => e === v).length;
          if (aCount !== bCount) return false;
        }
        return true;
      }


      if (this.modelPickerService.didJustRunModel()) {
        var gamesAfterRun = this.modelPickerService.getGamesThatMeetModelAfterRun();
        console.log("Games already exist after running MODEL PERFORMANCE VIEW...: ", gamesAfterRun);
        this.gamesMap = gamesAfterRun;
      }

      var newGamesStored = this.modelPickerService.getGamesStored();
      console.log("New games stored: " + newGamesStored);
      //var newGamesArray = this.modelPickerService.getGamesThatMeetModel();
      if (newGamesStored && !previousGamesStored || (previousGamesStored && newGamesStored && !equals(previousGamesStored, newGamesStored))) {
        //window.alert("Have to update games array: " + (previousGamesArray ? "previous games array existed too." : "previous games array did not exist."));
        //console.log("Have to update games array: ", newGamesStored.length , " old length: ", (previousGamesStored ? previousGamesStored.length:0));
        console.log("Have to update games stored!");
        this.gamesStored = newGamesStored;
        this.gamesStored = this.gamesStored.slice().reverse();
        this.seasonShowing = this.gamesStored[0];
      } else {
        if (this.gamesStored) {
          this.seasonShowing = this.gamesStored[0];
        }
      }

      console.log("Updating season showing to: "  + this.seasonShowing);

      //now we need to get the games for the first season to show.

      if (!this.gamesMap[this.seasonShowing]) {
        console.log("Games map did not contain games for this season: ", this.seasonShowing);
        this.getGamesForSeason(this.seasonShowing);
      }

      var self = this;
      setTimeout(function () {
        var button = document.getElementById(self.seasonShowing);
        //console.log("year to show?: ", self.seasonShowing);
        if (button) {
          //console.log("button found")
          button.className += " selected";
        } else {
          //console.log("no button found")
        }
      }, 50)
      console.log("Games stored: " + this.gamesStored);
    })
  }

  toggleSeason(season) {
    //console.log("Toggling season: " + season);

    if (season === "All") {
      //we need to show overall Model Perfmance, and Individual Season Profitability
    } else {
      //we need to show individual season performance, and individual game performance.
      for (var x = 0; x < this.gamesStored.length; x ++) {
        var year = this.gamesStored[x];
        if (year == season) {
          var button = document.getElementById(year);
          if (button) {
            button.className += " selected";
          }
  
          this.seasonShowing = this.gamesStored[x];
        } else {
          var button = document.getElementById(year);
          if (button) {
            button.className = "sideNavSeason";
          }
        }
      }
    }

    //need to get the games for this season
    if (!this.gamesMap[season]) {
      console.log("Games map does not contain the toggled season: " + season);
      this.getGamesForSeason(season);
    }
  }

  getGamesForSeason(season) {
    console.log("Getting games for this season.");
    this.modelService.getGamesThatMeetModel(this.modelPickerService.getModelIdToEdit(), season).subscribe(games => {
      console.log("Model detail performance TAB VIEW WAS CALLED!!!");
      var gamesForSeason = JSON.parse(games['games']);
      this.gamesMap[season] = this.getGamesIntoViewFormat(season, gamesForSeason);
      console.log("Loaded gamesMap for this season: ", this.gamesMap[season]);
    }, error => {
      console.log("Error getting games for season: ", error);
    });
  }

  /*
    This function takes a list of games, and calculates the overall performance of the season, and gets the 
    data into the format that is required for the View Component.
  */
  getGamesIntoViewFormat(season, games) {
    var bets = {};

    var currentWins = {};
    var currentLosses = {};
    var currentWinPercentage = {};

    this.betsTakenList.forEach((bet) => {
      bets[bet] = [];
      currentWins[bet] = 0;
      currentLosses[bet] = 0;
      currentWinPercentage[bet] = 0;
    });

    games.forEach(game => {
      var gameDate = game.gameStats.gameDate;
      this.betsTakenList.forEach((bet) => {
        var betResult = game.bets[bet]
        if (betResult === "WIN") {
          currentWins[bet] += 1; 
        } else if (betResult === "LOSE") {
          currentLosses[bet] += 1;
        }

        currentWinPercentage[bet] = Math.round(Number(currentWins[bet]/(currentWins[bet]+currentLosses[bet])) * 100);
        bets[bet].push({
          "gameDate": gameDate,
          "winPercentage": currentWinPercentage[bet]
        });
      });
    })

    this.modelPerformance.individualSeasonProfitability[season].forEach((bet) => {
      var wins = bet.wins;
      var losses = bet.losses;
      var winPercentage = Math.round(Number(wins/(wins+losses)) * 100);
      bet.winPercentage = winPercentage;
      bet.units = Math.round(bet.units)
    });

    return {
      "betsTaken": this.betsTakenList,
      "bets": bets,
      "overallPerformance": this.modelPerformance.individualSeasonProfitability[season]
    };
  }
}
