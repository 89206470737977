import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TeamMetadataService {

  constructor() { }

  nbaStatMap = {
    "teamDivision": [
      "ATLANTIC", "CENTRAL", "SOUTHEAST", "NORTHWEST", "PACIFIC", "SOUTHWEST"
    ],
    "teamConference": [
      "EASTERN", "WESTERN"
    ],
    "teamRegion": [
      "EASTERN", "CENTRAL", "MOUNTAIN", "PACIFIC"
    ]
  };

  nflStatMap = {
    "teamDivision": [
      "AFC NORTH", "AFC EAST", "AFC WEST", "AFC SOUTH", "NFC NORTH", "NFC EAST", "NFC WEST", "NFC SOUTH"
    ],
    "teamConference": [
      "AFC", "NFC"
    ],
    "teamRegion": [
      "EASTERN", "CENTRAL", "MOUNTAIN", "PACIFIC"
    ]
  }

  mlbStatMap = {
    "teamDivision": [
      "AL EAST", "AL CENTRAL", "AL WEST", "NL EAST", "NL CENTRAL", "NL WEST"
    ],
    "teamConference": [
      "AL", "NL"
    ],
    "teamRegion": [
      "EASTERN", "CENTRAL", "MOUNTAIN", "PACIFIC"
    ]
  }

  getPossibleValuesForStat(league, stat) {
    if (league == "NBA") {
      return this.nbaStatMap[stat];
    } else if (league == "NFL") {
      return this.nflStatMap[stat];
    } else if (league == "MLB") {
      return this.mlbStatMap[stat];
    }
    
    return null;
  }
}
