import { Component, OnInit, Input } from '@angular/core';
import { LeagueService } from 'src/app/services/league.service';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { button } from 'aws-amplify';

@Component({
  selector: 'app-time-range-selector',
  templateUrl: './time-range-selector.component.html',
  styleUrls: ['./time-range-selector.component.css']
})
export class TimeRangeSelectorComponent implements OnInit {

  firstSeasonAvailable;
  lastSeasonAvailable;
  league;
  seasonsAvailableArray;
  customTimeRangeSelected;
  customTimeRange;
  timeRangeSelected;
  timeRangeDescription;
  season;

  customText;
  constructor(private modelPickerService: ModelPickerService, private leagueService: LeagueService) { }

  ngOnInit(): void {
    this.customText = "Custom";
    this.modelPickerService.currentModel.subscribe(model => {
      if (model.modelConfiguration) {
        model = model.modelConfiguration;
      }
      this.league = model.league;
      if (this.league) {
        this.firstSeasonAvailable = Number(this.leagueService.getFirstSeasonAvailable(this.league));
        this.lastSeasonAvailable = Number(this.leagueService.getLastSeasonAvailable(this.league));
        //console.log("First season available: ", this.firstSeasonAvailable);
        //console.log("Last season available: ", this.lastSeasonAvailable);
        this.createSeasonsAvailableArray();
      }

      this.timeRangeDescription = model.modelTimeRangeDescription;

      var allTimeButton = document.getElementById("allTime");
      var thisSeasonButton = document.getElementById("thisSeason");
      var past5Button = document.getElementById("past5");
      var past10Button = document.getElementById("past10");
      var customButton = document.getElementById("custom");

      if (allTimeButton) {
        allTimeButton.className = "timeRange";
      }

      if (thisSeasonButton) {
        thisSeasonButton.className = "timeRange";
      }

      if (past5Button) {
        past5Button.className = "timeRange";
      }

      if (past10Button) {
        past10Button.className = "timeRange";
      }

      if (customButton) {
        customButton.className = "timeRange";
      }


      if (this.timeRangeDescription) {
        //console.log("Time range description has been set to: ", this.timeRangeDescription);
        var buttonToActivate = document.getElementById(this.timeRangeDescription);
        //console.log("Button found?: ", buttonToActivate)
        if (buttonToActivate) {
          buttonToActivate.className = "timeRange buttonSelected";
        }

        if (this.timeRangeDescription == "custom") {
          var start = model.modelStartRange;
          this.customText = "Custom: Since " + start;
          this.season = start;
        } else {
          this.season = null;
          this.customText = "Custom"
        }
      }
    })

    this.customTimeRangeSelected = false;
  }

  createSeasonsAvailableArray() {
    this.seasonsAvailableArray = new Array();
    for (var i = this.firstSeasonAvailable; i <= this.lastSeasonAvailable; i ++) {
      this.seasonsAvailableArray.push(i);
    }
    //console.log("Seasons array: ", this.seasonsAvailableArray);
  }

  selectAllTime() {
    this.customTimeRangeSelected = false;
    this.modelPickerService.setModelStartRagne(String(this.firstSeasonAvailable));
    this.modelPickerService.setModelEndRange(String(this.lastSeasonAvailable));
    this.modelPickerService.setModelTimeDescription("allTime");
  }

  selectThisSeason() {
    this.customTimeRangeSelected = false;
    this.modelPickerService.setModelStartRagne(String(this.lastSeasonAvailable));
    this.modelPickerService.setModelEndRange(String(this.lastSeasonAvailable));
    this.modelPickerService.setModelTimeDescription("thisSeason");
  }

  selectPast5() {
    this.customTimeRangeSelected = false;
    this.modelPickerService.setModelStartRagne(String(this.lastSeasonAvailable - 5));
    this.modelPickerService.setModelEndRange(String(this.lastSeasonAvailable));
    this.modelPickerService.setModelTimeDescription("past5");
  }

  selectPast10() {
    this.customTimeRangeSelected = false;
    this.modelPickerService.setModelStartRagne(String(this.lastSeasonAvailable - 10));
    this.modelPickerService.setModelEndRange(String(this.lastSeasonAvailable));
    this.modelPickerService.setModelTimeDescription("past10");
  }

  selectCustom() {
    this.customTimeRangeSelected = true;
  }

  selectCustomTimeRange() {
    this.modelPickerService.setModelStartRagne(String(this.customTimeRange - 10));
    this.modelPickerService.setModelEndRange(String(this.lastSeasonAvailable));
    this.modelPickerService.setModelTimeDescription("custom");
  }
}
