import { Component, OnInit } from '@angular/core';
import { ModelPickerService } from 'src/app/services/model-picker.service';

@Component({
  selector: 'app-bets-taken-selector',
  templateUrl: './bets-taken-selector.component.html',
  styleUrls: ['./bets-taken-selector.component.css']
})
export class BetsTakenSelectorComponent implements OnInit {

  betsTaken;
  league;
  constructor(private modelPickerService: ModelPickerService) { }

  ngOnInit(): void {
    this.betsTaken = new Array();

    this.modelPickerService.currentModel.subscribe(model => {

      var currentBetsTaken = model.betsTaken;
      this.league = model.league;

      var overButton = document.getElementById("OVER");
      var underButton = document.getElementById("UNDER");
      var spreadButton = document.getElementById("SPREAD");
      var moneyLineButton = document.getElementById("MONEY_LINE");

      if (overButton) {
        overButton.className = "betTaken";
      }

      if (underButton) {
        underButton.className = "betTaken";
      }

      if (spreadButton) {
        spreadButton.className = "betTaken";
      }

      if (moneyLineButton) {
        moneyLineButton.className = "betTaken";
      }

      if (currentBetsTaken) {
        for (var i = 0; i < currentBetsTaken.length; i ++) {
          var bet = currentBetsTaken[i];
          var betButton = document.getElementById(bet);
          if (betButton) {
            betButton.className = "betTaken buttonSelected";
          }
        }
      }
    })
  }

  toggleOver() {
    if (this.betsTaken.includes("OVER")) {
      var index = this.betsTaken.indexOf("OVER");
      this.betsTaken.splice(index, 1);
    } else {
      this.betsTaken.push("OVER")
    }
    //console.log("bets taken: ", this.betsTaken);
    this.modelPickerService.setModelBetsTaken(this.betsTaken);
  }

  toggleUnder() {
    
    if (this.betsTaken.includes("UNDER")) {
      var index = this.betsTaken.indexOf("UNDER");
      this.betsTaken.splice(index, 1);
    } else {
      this.betsTaken.push("UNDER")
    }
    //console.log("bets taken: ", this.betsTaken);
    this.modelPickerService.setModelBetsTaken(this.betsTaken);
  }

  toggleSpread() {
    if (this.betsTaken.includes("SPREAD")) {
      var index = this.betsTaken.indexOf("SPREAD");
      this.betsTaken.splice(index, 1);
    } else {
      this.betsTaken.push("SPREAD")
    }
    //console.log("bets taken: ", this.betsTaken);
    this.modelPickerService.setModelBetsTaken(this.betsTaken);
  }

  toggleMoneyLine() {
    if (this.betsTaken.includes("MONEY_LINE")) {
      var index = this.betsTaken.indexOf("MONEY_LINE");
      this.betsTaken.splice(index, 1);
    } else {
      this.betsTaken.push("MONEY_LINE")
    }
    //console.log("bets taken: ", this.betsTaken);
    this.modelPickerService.setModelBetsTaken(this.betsTaken);
  }
}
