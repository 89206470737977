import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-requirement-detail-view',
  templateUrl: './requirement-detail-view.component.html',
  styleUrls: ['./requirement-detail-view.component.css']
})
export class RequirementDetailViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
