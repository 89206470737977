import { Component, OnInit, ContentChildren } from '@angular/core';
import { RequirementPickerService } from 'src/app/services/requirement-picker.service';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { Router } from '@angular/router';
import { RequirementHomeAwayPickerComponent } from '../requirement-home-away-picker/requirement-home-away-picker.component';

@Component({
  selector: 'app-requirement-picker',
  templateUrl: './requirement-picker.component.html',
  styleUrls: ['./requirement-picker.component.css']
})
export class RequirementPickerComponent implements OnInit {

  currentRequirement;
  constructor(private route: Router, private modelPickerService: ModelPickerService, private requirementPickerService: RequirementPickerService) { }

  ngOnInit(): void {

    // this.requirementPickerService.currentRequirement.subscribe(requirement => {
    //   this.currentRequirement = requirement;
    // })
  }

  addRequirement() {
    this.modelPickerService.addRequirement({"test": "hello"});
    this.route.navigate(["/model-picker"]);
  }

}
