<div class="container">
    <!-- User Sign up -->

    <div class="row sign-up row-center" *ngIf="signstatus == 'signup'">
        <div class="col-sm-6">
                <div class="form-group">
                  <label for="username">Username</label>
                  <input type="text" class="form-control" id="username" placeholder="Username" #username>
                </div>
                <div class="form-group">
                  <label for="email">Email address</label>
                  <input type="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email" #email>
                </div>
                <div class="form-group">
                  <label for="firstName">First Name</label>
                  <input type="text" class="form-control" id="firstName" placeholder="First Name" #firstName>
                </div>
                <div class="form-group">
                  <label for="lastName">Last Name</label>
                  <input type="text" class="form-control" id="lastName" placeholder="Last Name" #lastName>
                </div>
                <div class="form-group">
                  <label for="phoneNumber">Phone Number</label>
                  <input type="text" class="form-control" id="phoneNumber" placeholder="(123)-456-7890" #phoneNumber>
                </div>
                <div class="form-group">
                  <label for="birthday">Birthday</label>
                  <input type="date" id="birthday" name="birthday"
                          value="2021-03-16"
                          min="1950-01-01" max="2020-12-31" #birthday>
                </div>
                <div class="form-group">
                  <label for="password">Password</label>
                  <input type="password" class="form-control" id="password" placeholder="Password" #password>
                </div>
                <button type="button" class="btn btn-primary" (click)="signUpToAWS(username, email, firstName, lastName, phoneNumber, birthday, password)">Sign Up</button>
        </div>
    </div>

</div>