import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ModelService } from 'src/app/services/model.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  userLoggedIn;
  modelsSubscribed;
  modelsCreated;

  searchText;
  allUsers;
  autoCompleteItems;

  constructor(private route: Router, private modelPickerService: ModelPickerService, private userService: UserService, private modelService: ModelService, private http: HttpClient, private authenticateService: AuthenticationService) { 
    this.autoCompleteItems = new Array();
  }



  ngOnInit(): void {
    this.authenticateService.currentUser.subscribe(user => {
      this.userLoggedIn = user;
    })
  }

  createModel() {
    if (this.userLoggedIn && this.userLoggedIn.username) {
      this.modelPickerService.setModelToEdit({}, true);
      this.route.navigate(["/model-picker"]);
    } else {
      this.route.navigate(['/signin'])
    }
  }
}
