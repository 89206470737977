import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { ModelService } from 'src/app/services/model.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { UserListService } from 'src/app/services/user-list.service';

@Component({
  selector: 'app-profile-detail-view',
  templateUrl: './profile-detail-view.component.html',
  styleUrls: ['./profile-detail-view.component.css']
})
export class ProfileDetailViewComponent implements OnInit {
  userId;
  userLoggedIn;
  userDetails;
  viewingOwnProfile;
  userLoggedInFollowsUser;
  userFollowsUserLoggedIn;

  usersThatFollowUser;
  usersFollowing;

  modelsCreated;
  modelsSubscribed;
  constructor(private userListService: UserListService, private userService: UserService, private authenticationService: AuthenticationService, private modelPickerService: ModelPickerService, private modelService: ModelService, private route: Router, public activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    //console.log("map: ", this.activatedRoute.snapshot.paramMap)
    this.userId = this.activatedRoute.snapshot.paramMap.get('userId');
    //console.log("Coming to profile view with this userid: ", this.userId);
    this.authenticationService.currentUser.subscribe(user => {
      if (user && user.username) {
        //console.log("Got user logged in: ", user);
        this.userLoggedIn=user;

        //first, check if the user is viewing their own profile.
        this.viewingOwnProfile = (this.userLoggedIn.username == this.userId);
        //console.log("Viewing own profile? ", this.viewingOwnProfile)

        this.getProfileDetails();
      }
    });

      this.getProfileDetails();
      this.getModelsCreated();
      this.getModelsSubscribed();
  }

  getProfileDetails() {
    //console.log("Loading user info...")
    this.userService.getUserInfo(this.userId).subscribe(userDetails => {
      //console.log("User details found: ", userDetails);
      if (userDetails.error) {
        //console.log("Error getting user details: ", userDetails.error);
      } else {
        this.userDetails = userDetails;
        //need to check if user logged in is currently following this user.
        this.usersThatFollowUser = this.userDetails.usersThatFollow;
        this.usersFollowing = this.userDetails.usersFollowing;

        if (this.userLoggedIn && this.userLoggedIn.username) {
          this.userLoggedInFollowsUser = (this.usersThatFollowUser.includes(this.userLoggedIn.username));
          //console.log("User logged in follows user?: ", this.userLoggedInFollowsUser);
          this.userFollowsUserLoggedIn = (this.usersFollowing.includes(this.userLoggedIn.username));
          //console.log("User follows user logged in?: ", this.userFollowsUserLoggedIn);
        } else {
          this.userLoggedInFollowsUser = false;
          this.userFollowsUserLoggedIn = false;
        }
      }
    }, error => {
      //console.log("Error getting user details: ", error);
    })
  }

  getModelsCreated() {
    //console.log("Loading user models created...");

    if (this.viewingOwnProfile) {
      this.userService.currentModelsCreated.subscribe(modelsCreated => {
        //console.log("Got response from models created in viewing on profile detail view: ", modelsCreated);
        this.modelsCreated = modelsCreated;
      }, error => {
        //console.log("Error retrieving models from database: ", error);
      })
    } else {
      this.userService.getModelsCreatedByUser(this.userId).subscribe(modelsCreated => {
        //console.log("Got response from models created in api call profile detail view: ", modelsCreated);
        this.modelsCreated = modelsCreated.models;
      }, error => {
        //console.log("Error retrieving models from database: ", error);
      })
    }
  }

  getModelsSubscribed() {
    //console.log("Loading user models subscribed...")
    if (this.viewingOwnProfile) {
      this.userService.currentModelsSubscribed.subscribe(modelsSubscribed => {
        //console.log("Got response from models subscribed in viewing own profile detail view: ", modelsSubscribed)
        this.modelsSubscribed = modelsSubscribed;
      }, error => {
        //console.log("Error retrieving models from database: ", error);
      })
    } else {
      this.userService.getModelsSubscribed(this.userId).subscribe(modelsSubscribed => {
        //console.log("Got response from models subscribed in api profile detail view: ", modelsSubscribed);
        this.modelsSubscribed = modelsSubscribed.models;
      }, error => {
        //console.log("Error retrieving models from database: ", error);
      })
    }
  }

  followUser() {
    if (this.userLoggedIn && this.userLoggedIn.username) {
      //console.log("Following user...");
      this.userService.followUser(this.userLoggedIn, this.userId).subscribe(data => {
        //console.log("Done following user? : ", data);
        this.userLoggedInFollowsUser = true;
        this.usersThatFollowUser.push(this.userLoggedIn.username);
      }, error => {
        //console.log("Error following user: ", error);
      })
    } else {
      this.route.navigate(['/signin'])
    }
  }

  unfollowUser() {
    if (this.userLoggedIn && this.userLoggedIn.username) {
      //console.log("Unfollowing user...");
      this.userService.unfollowUser(this.userLoggedIn, this.userId).subscribe(data => {
        //console.log("Done following user? : ", data);
        this.userLoggedInFollowsUser = false;
        var index = this.usersThatFollowUser.indexOf(this.userLoggedIn.username);
        this.usersThatFollowUser.splice(index, 1);
      }, error => {
        //console.log("Error following user: ", error);
      })
    } else {
      this.route.navigate(['/signin'])
    }
  }

  editProfile() {
    //console.log("Editing profile...");
  }

  viewFollowers() {
    //console.log("Viewing users that follow user");
    this.userListService.setText("Followers");
    this.userListService.setUserList(this.usersThatFollowUser);
    this.route.navigate(["/user-list"]);
  }

  viewFollowing() {
    //console.log("Viewing users following");
    this.userListService.setText("Following");
    this.userListService.setUserList(this.usersFollowing);
    this.route.navigate(["/user-list"]);
  }
}
