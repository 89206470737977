import { Component, OnInit } from '@angular/core';
import { ModelService } from 'src/app/services/model.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { RequirementPickerService } from 'src/app/services/requirement-picker.service';
import { TeamMetadataService } from 'src/app/services/team-metadata.service';

@Component({
  selector: 'app-requirement-stat-string-comparison-picker',
  templateUrl: './requirement-stat-string-comparison-picker.component.html',
  styleUrls: ['./requirement-stat-string-comparison-picker.component.css']
})
export class RequirementStatStringComparisonPickerComponent implements OnInit {

  statToCompare;
  statForOpponent;
  stringEquals;
  canEdit;
  currentUser;
  possibleValues;

  constructor(private teamMetadataService: TeamMetadataService, private modelService: ModelService, private authenticationService: AuthenticationService, private route: Router, private modelPickerService: ModelPickerService, private requirementPickerService: RequirementPickerService) { }

  ngOnInit(): void {
    this.statToCompare = this.requirementPickerService.getStatToCompare();
    this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    })
    this.canEdit = this.requirementPickerService.canEditRequirement();

    console.log("Calling possible values for stat: " + this.statToCompare.statName);
    this.possibleValues = this.teamMetadataService.getPossibleValuesForStat(this.modelPickerService.getModelLeague(), this.statToCompare.statName);
    console.log("Possible values: " + this.possibleValues);
  
  }

  ngAfterContentInit() {
    this.requirementPickerService.currentRequirement.subscribe(requirement => {
      if (requirement.forOpponent != null) {
        var forOpponent = Boolean(requirement.forOpponent);
        if (forOpponent) {
          this.statForOpponent = true;
          var opposingTeamButton = document.getElementById("opposingTeamButton");
          if (opposingTeamButton) {
            opposingTeamButton.className = "buttonSelected";
          }

          var secondTeamButton = document.getElementById("secondTeamButton");
          if (secondTeamButton) {
            secondTeamButton.innerText = "My Team's " + this.statToCompare.statName;
          }
        } else {
          this.statForOpponent = false;
          var myTeamButton = document.getElementById("myTeamButton");
          var opposingTeamButton = document.getElementById("opposingTeamButton");
          if (myTeamButton) {
            myTeamButton.className = "buttonSelected";
          }

          if (opposingTeamButton) {
            opposingTeamButton.className = "";
          }

          this.statForOpponent = false;

          var secondTeamButton = document.getElementById("secondTeamButton");
          if (secondTeamButton) {
            secondTeamButton.innerText = "The Other Team's " + this.statToCompare.statName;
          }
        }
      }
    })
  }

  selectMyTeam() {
    var myTeamButton = document.getElementById("myTeamButton");
    var opposingTeamButton = document.getElementById("opposingTeamButton");
    if (myTeamButton) {
      myTeamButton.className = "buttonSelected";
    }

    if (opposingTeamButton) {
      opposingTeamButton.className = "";
    }

    this.statForOpponent = false;
  }

  selectOpposingTeam() {
    var myTeamButton = document.getElementById("myTeamButton");
    var opposingTeamButton = document.getElementById("opposingTeamButton");
    if (opposingTeamButton) {
      opposingTeamButton.className = "buttonSelected";
    }

    if (myTeamButton) {
      myTeamButton.className = "";
    }
    
    this.statForOpponent = true;
  }

  updateStringEquals() {
    if (this.stringEquals == "") {
      this.stringEquals = "";
    }
  }


  saveRequirement() {
    var statsUsed = new Array();
    statsUsed.push(this.statToCompare.statName);

    var description = "";
    if (this.statForOpponent) {
      description += "Other team ";
    } else {
      description += "My team ";
    }

    description += this.statToCompare.statName + " must be " + this.stringEquals;

    var requirement = {
      "description": description,
      "forOpponent": this.statForOpponent,
      "filterType": "STRING_COMPARISON",
      "statsUsed": statsUsed,
      "acceptedExactValues": [this.stringEquals]
    }

    //console.log("Saving requirement: ", requirement);

    if (this.requirementPickerService.editingRequirement()) {
      this.modelPickerService.updateRequirement(requirement);
      var currentModel = this.modelPickerService.getModelToEdit();
      if (this.canEdit) {
        this.modelService.updateModel(this.currentUser, this.modelPickerService.getModelIdToEdit(), currentModel).subscribe(data => {
          if (data.error) {
            //console.log("Error updating requirement: ", data.error);
            window.alert(data.error);
          } else {
            var routeToGo = "/models/" + this.modelPickerService.getModelIdToEdit();
            this.route.navigate([routeToGo]);
          }
        })
      } else {
        this.route.navigate(["/model-picker"])
      }
    } else { 
      this.modelPickerService.addRequirement(requirement);
      this.route.navigate(["/model-picker"]);
    }
  }

}
