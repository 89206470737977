<div class='title'>Select Your Stat</div>
<div class="statList" id="statList">
    <div class="list">
        <mat-grid-list cols="2">
            <mat-grid-tile *ngFor="let stat of statsAvailable">
                <button mat-raised-button (click)="selectStat(stat)" class='tile' id={{stat}}>
                    <app-requirement-category-picker title={{stat.statDescription}} statName={{stat.statName}}></app-requirement-category-picker>
                </button>
            </mat-grid-tile>
        </mat-grid-list>    
    </div>
</div>