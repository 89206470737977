<div id="tabGroup">
    <mat-tab-group #tabGroup animationDuration="0ms" mat-align-tabs="center">
        <mat-tab label="Performance">
            <ng-template matTabContent>
                <app-model-detail-performance-tab-view></app-model-detail-performance-tab-view>
            </ng-template>
        </mat-tab>
        <mat-tab label="Upcoming">
            <ng-template matTabContent>
                <app-upcoming-games-for-model></app-upcoming-games-for-model>
            </ng-template>
        </mat-tab>
        <mat-tab label="History">
            <ng-template matTabContent>
                <app-model-detail-game-view></app-model-detail-game-view>
            </ng-template>
        </mat-tab>
        <mat-tab label="Requirements">
            <ng-template matTabContent>
                <app-model-detail-requirements-view></app-model-detail-requirements-view>
            </ng-template>
        </mat-tab>
        <!-- <mat-tab label="History">
            <ng-template matTabContent>
                <app-model-detail-history-view></app-model-detail-history-view>
            </ng-template>
        </mat-tab> -->
    </mat-tab-group>
</div>