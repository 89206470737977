import { Component, OnInit } from '@angular/core';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { ModelService } from 'src/app/services/model.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GameService } from 'src/app/services/game.service';
import {MatDialog} from '@angular/material/dialog';
import { GameDetailsDialogComponent } from '../game-details-dialog/game-details-dialog.component';
import { LeagueService } from 'src/app/services/league.service';

@Component({
  selector: 'app-model-detail-game-view',
  templateUrl: './model-detail-game-view.component.html',
  styleUrls: ['./model-detail-game-view.component.css']
})
export class ModelDetailGameViewComponent implements OnInit {

  gamesStored;
  finalGamesArray;
  nflTeams;
  nbaTeams;
  mlbTeams;
  seasonShowing;
  gamesMap;
  currentUser;
  currentModel;
  constructor(private leagueService: LeagueService, public dialog: MatDialog, private gameService: GameService, private authenticationiService: AuthenticationService, private modelService: ModelService, private modelPickerService: ModelPickerService) { 
    this.nflTeams = this.leagueService.getTeams("NFL");
    this.nbaTeams = this.leagueService.getTeams("NBA");
    this.mlbTeams = this.leagueService.getTeams("MLB");
  }

  ngOnInit(): void {
    this.gamesMap = {}
    this.authenticationiService.currentUser.subscribe(user => {
      this.currentUser = user;
    })

    this.modelPickerService.currentModel.subscribe(model => {
      console.log("Model loaded into games detail view component: ", model);
      this.currentModel = model;
      this.gamesStored = model.gamesStored;
    })
    // this.gamesArray = this.modelPickerService.getGamesThatMeetModel();
    this.finalGamesArray = new Array();

    var previousGamesStored = this.gamesStored;

    const equals = (a, b) => {
      if (a.length !== b.length) return false;
      const uniqueValues = new Set([...a, ...b]);
      for (const v of uniqueValues) {
        const aCount = a.filter(e => e === v).length;
        const bCount = b.filter(e => e === v).length;
        if (aCount !== bCount) return false;
      }
      return true;
    }


    if (this.modelPickerService.didJustRunModel()) {
      var gamesAfterRun = this.modelPickerService.getGamesThatMeetModelAfterRun();
      console.log("Games already exist after running...: ", gamesAfterRun);
      this.gamesMap = gamesAfterRun;
      Object.keys(this.gamesMap).forEach(year => {
        this.sortGames(year);
      })
    }

    var newGamesStored = this.modelPickerService.getGamesStored();
    //var newGamesArray = this.modelPickerService.getGamesThatMeetModel();
    if (!previousGamesStored || (previousGamesStored && !equals(previousGamesStored, newGamesStored))) {
      //window.alert("Have to update games array: " + (previousGamesArray ? "previous games array existed too." : "previous games array did not exist."));
      console.log("Have to update games array: ", newGamesStored.length , " old length: ", (previousGamesStored ? previousGamesStored.length:0));
      this.gamesStored = newGamesStored;
      this.gamesStored = this.gamesStored.slice().reverse();
    }

    this.seasonShowing = this.gamesStored[0];
    //now we need to get the games for the first season to show.

    if (!this.gamesMap[this.seasonShowing]) {
      console.log("Games map did not contain games IN GAME VIEW COMPONENT: ", this.seasonShowing);
      this.getGamesForSeason(this.seasonShowing);
    }



    //this.sortGames();

    //this.seasonShowing = this.finalGamesArray[0];

    var self = this;
    setTimeout(function () {
      var button = document.getElementById(self.seasonShowing);
      //console.log("year to show?: ", self.seasonShowing);
      if (button) {
        //console.log("button found")
        button.className += " selected";
      } else {
        //console.log("no button found")
      }
    }, 50)
  }

  getGamesForSeason(season) {
    console.log("Attempting to get GAMES FOR SEASON");
    this.modelService.getGamesThatMeetModel(this.modelPickerService.getModelIdToEdit(), season).subscribe(games => {
      console.log("CHANGES HAV OCCURED GAME VIEW: ");
      console.log("Games found for season: ", games);
      //console.log("BEFORE UPDATING GAMES MAP: ", this.modelPickerService.getGamesThatMeetModelAfterRun());
      this.gamesMap[season] = JSON.parse(games['games']);
      //console.log("MIDDLE UPDATING GAMES: ", this.modelPickerService.getGamesThatMeetModelAfterRun());
      this.sortGames(season);
      //console.log("AFTER UPDATING GAMES: ", this.modelPickerService.getGamesThatMeetModelAfterRun());
    }, error => {
      console.log("Error getting games for season: ", error);
    })
  }

  /*
    Get games to go from API format of games to this format:
  {
    "season": 2019,
    "games": [
      {
        "gameData": {},
        "bets": [
          {
            "betName": OVER,
            "win": true
          }
        ]
      }
    ]
  }


    }
  */
  sortGames(season) {
    //console.log("in here....");
    var seasonArray = new Array();
    //console.log("SEASON GAMES: ", this.gamesMap[season]);
    for (var x = 0; x < this.gamesMap[season].length; x ++) {
      var game = this.gamesMap[season][x];
      console.log("Game: ", game);

      var bets = game.bets;
      var homeTeamStats = game.homeTeamStats;
      var awayTeamStats = game.awayTeamStats;
      var gameStats = game.gameStats;
      var bettingStats = game.bettingStats;

      var gameJson = {};
      gameJson["bets"] = new Array();
      
      Object.keys(bets).forEach(bet => {
        var betJson = {};
        betJson["betName"] = bet;
        betJson['win'] = bets[bet];
        gameJson['bets'].push(betJson);
      })

      ////console.log("in here 3")
      // current format: game: [{OVER: WIN}, {UNDER: WIN}, {gameData: {gameData}}]

      var homeTeam = gameStats['homeTeam'];
      var awayTeam = gameStats['awayTeam'];
      var gameDate = gameStats['gameDate'];
      var homeTeamPoints = homeTeamStats['Final'];
      var awayTeamPoints = awayTeamStats['Final'];

      var obj = {
        "homeTeamAbbr": this.getTeamAbbreviation(homeTeam),
        "homeTeamName": homeTeam,
        "homeTeamLogo": this.getTeamLogo(homeTeam),
        "awayTeamAbbr": this.getTeamAbbreviation(awayTeam),
        "awayTeamName": awayTeam,
        "awayTeamLogo": this.getTeamLogo(awayTeam),
        "homeTeamPoints": homeTeamPoints,
        "awayTeamPoints": awayTeamPoints,
        "date": gameDate,
        "extraStats": {
          "homeTeamStats": homeTeamStats,
          "awayTeamStats": awayTeamStats,
          "gameStats": gameStats,
          "bettingStats": bettingStats
        }
      }

      if ('numGameForDay' in gameStats) {
        obj['numGameForDay'] = gameStats['numGameForDay'];
      }

      gameJson['gameData'] = obj;

      seasonArray.push(gameJson);
    }

    this.gamesMap[season] = seasonArray;
  }

  showGameDetails(game) {
    var gameData = game.gameData;
    var homeTeam = gameData.homeTeamName;
    var awayTeam = gameData.awayTeamName;

    var gameDate = gameData.date;

    var key = homeTeam + "|" + awayTeam + "|" + gameDate

    if ("numGameForDay" in gameData) {
      key += "|" + gameData.numGameForDay;
    }

    this.gameService.getGameDetails(this.currentModel.league, key).subscribe(data => {
      //console.log("Got data returned from game details: ", data)
      if (data.game) {
        var game = data.game;
        var gameStats = game.gameStats;
        var homeTeamStats = game.homeTeamStats;
        var awayTeamStats = game.awayTeamStats;
        var bettingStats = game.bettingStats;

        var homeML = (Number(bettingStats.homeTeamMoneyLine) > 0 ? "+" + Number(bettingStats.homeTeamMoneyLine): Number(bettingStats.homeTeamMoneyLine));
        var awayML = (Number(bettingStats.awayTeamMoneyLine) > 0 ? "+" + Number(bettingStats.awayTeamMoneyLine): Number(bettingStats.awayTeamMoneyLine));
        
        var homeMoneyLine = Number(bettingStats.homeTeamMoneyLine);
        var awayMoneyLine = Number(bettingStats.awayTeamMoneyLine);
        //console.log("Home ml: " + homeMoneyLine);
        //console.log("Away ml: " + awayMoneyLine);
        if (homeMoneyLine < awayMoneyLine) {
          bettingStats.favorite = homeTeam;
          bettingStats.favoriteSpread = bettingStats.homeTeamSpread;
        } else {
          bettingStats.favorite = awayTeam;
          bettingStats.favoriteSpread = bettingStats.awayTeamSpread;
        }
        //bettingStats.favorite = this.getTeamAbbreviation(bettingStats.favorite);
        bettingStats.homeMoneyLine = homeML;
        data.game.awayMoneyLine = awayML;
        data.game.homeTeamAbbr = gameData.homeTeamAbbr;
        data.game.awayTeamAbbr = gameData.awayTeamAbbr;
        data.game.homeTeamLogo = gameData.homeTeamLogo;
        data.game.awayTeamLogo = gameData.awayTeamLogo;
        data.game.extraFields = gameData.extraFields;
        data.game.homeTeamStats = homeTeamStats;
        data.game.awayTeamStats = awayTeamStats;
        data.game.gameStats = gameStats;
        data.game.bettingStats = bettingStats;

        data.game.extraStats = gameData.extraStats;
        this.dialog.open(GameDetailsDialogComponent, {
          data: data.game
        });
      }
    }, error => {
      //console.log("Got error trying to get game data: ", error)
    })

  }


  toggleSeason(season) {
    //console.log("Toggling season: " + season);

    for (var x = 0; x < this.gamesStored.length; x ++) {
      var year = this.gamesStored[x];
      if (year == season) {
        var button = document.getElementById(year);
        if (button) {
          button.className += " selected";
        }

        this.seasonShowing = this.gamesStored[x];
      } else {
        var button = document.getElementById(year);
        if (button) {
          button.className = "sideNavSeason";
        }
      }


    }

    //need to get the games for this season
    if (!this.gamesMap[season]) {
      this.getGamesForSeason(season);
    }
  }

  getTeamAbbreviation(team) {
    //console.log("Getting team abbreviation: " + team);
    return team;
  }

  getTeamLogo(team) {
    if (this.currentModel.league == "NFL") {
      return this.nflTeams[team]['logo'];
    } else if (this.currentModel.league == "NBA") {
      return this.nbaTeams[team]['logo'];
    } else if (this.currentModel.league == "MLB") {
      return this.mlbTeams[team]['logo'];
    }
  }

}
