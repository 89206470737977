<!-- <div id="favorite">
    <button (click)="selectFavorite()" id='favoriteButton'>
        <app-requirement-category-picker title="favorite" image="assets/images/favorite.jpeg"></app-requirement-category-picker>
    </button>
</div>

<div id="underdog">
    <button (click)="selectUnderdog()" id='underdogButton'>
        <app-requirement-category-picker title="underdog" image="assets/images/underdog.jpeg"></app-requirement-category-picker>
    </button>
</div> -->

<div class="grid-container">
    <div class="title">Favorite or Underdog</div>
    <div class="favorite">
        <button class="picker" mat-raised-button (click)="selectFavorite()" id="favoriteButton">
            <!-- <app-requirement-category-picker title="favorite" image="assets/images/favorite.jpeg"></app-requirement-category-picker> -->
            Favorite
        </button>
    </div>
    <div class="underdog">
        <button class="picker" mat-raised-button (click)="selectUnderdog()" id="underdogButton">
            <!-- <app-requirement-category-picker title="underdog" image="assets/images/underdog.jpeg"></app-requirement-category-picker> -->
            Underdog
        </button>
    </div>
</div>