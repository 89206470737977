<h1 mat-dialog-title style="text-align: center;">Game Details</h1>
<div mat-dialog-content>

    <div class="grid-container">
        <div class="awayName">{{data.awayTeam}}</div>
        <div class="awayMoneyLine">({{data.bettingStats.awayTeamMoneyLine}})</div>
        <div class="awayLogo">
            <img class="teamLogo" src="{{data.awayLogo}}">
        </div>
        <div class="vs">vs.</div>
        <div class="homeLogo">
            <img class="teamLogo" src="{{data.homeLogo}}">
        </div>
        <div class="homeMoneyLine">({{data.bettingStats.homeTeamMoneyLine}})</div>
        <div class="homeName">{{data.homeTeam}}</div>
        <div class="line">Line: {{data.bettingStats.favorite}} -{{data.bettingStats.favoriteSpread}}</div>
        <div class="total">O/U: {{data.bettingStats.overUnder}}</div>

        <div class="boxScore">
            <div class="q1Head">Q1</div>
            <div class="q2Head">Q2</div>
            <div class="q3Head">Q3</div>
            <div class="q4Head">Q4</div>
            <div class="totalHead">Total</div>
            <div class="awayLogoBox">
              <img class="teamLogoBox" src="{{data.awayLogo}}">
            </div>
            <div class="homeLogoBox">
              <img class="teamLogoBox" src="{{data.homeLogo}}">
            </div>
            <div class="awayNameBox">{{data.awayTeam}}</div>
            <div class="homeNameBox">{{data.homeTeam}}</div>
            <div class="awayQ1">{{data.awayTeamScores.Q1}}</div>
            <div class="awayQ2">{{data.awayTeamScores.Q2}}</div>
            <div class="awayQ3">{{data.awayTeamScores.Q3}}</div>
            <div class="awayQ4">{{data.awayTeamScores.Q4}}</div>
            <div class="homeQ1">{{data.homeTeamScores.Q1}}</div>
            <div class="homeQ2">{{data.homeTeamScores.Q2}}</div>
            <div class="homeQ3">{{data.homeTeamScores.Q3}}</div>
            <div class="homeQ4">{{data.homeTeamScores.Q4}}</div>
            <div class="awayTotal">{{data.awayTeamScores.F}}</div>
            <div class="homeTotal">{{data.homeTeamScores.F}}</div>
        </div>
    </div>
</div>