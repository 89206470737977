<div *ngIf="upcomingGames && upcomingGames.length == 0">
    <p>No upcoming games.</p>
</div>
<div *ngFor="let game of upcomingGames">
    <div class="container">
        <div class="awayLogo">
            <img class="teamLogo" src="{{game.awayLogo}}">
        </div>
        <div class="awayName">{{game.awayTeam}}</div>
        <div class="homeLogo">
            <img class="teamLogo" src="{{game.homeLogo}}">
        </div>
        <div class="homeName">{{game.homeTeam}}</div>
        <div class="gameStartTime">{{game.gameStartTime}}</div>
    </div>
</div>