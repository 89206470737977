import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-stat-standings-row',
  templateUrl: './stat-standings-row.component.html',
  styleUrls: ['./stat-standings-row.component.css']
})
export class StatStandingsRowComponent implements OnInit {

  @Input() name;
  @Input() logo;
  @Input() value;
  @Input() rank;
  constructor() { }

  ngOnInit(): void {
  }

}
