import { Component, OnInit, Input } from '@angular/core';
import { GameService } from '../../services/game.service';
import { LeagueService } from '../../services/league.service';

@Component({
  selector: 'app-upcoming-games-list',
  templateUrl: './upcoming-games-list.component.html',
  styleUrls: ['./upcoming-games-list.component.css']
})
export class UpcomingGamesListComponent implements OnInit {

  @Input() league;
  upcomingGames;
  nflTeams;
  nbaTeams;
  mlbTeams;
  constructor(private leagueService: LeagueService, private gameService: GameService) {
    this.nflTeams = this.leagueService.getTeams("NFL");
    this.nbaTeams = this.leagueService.getTeams("NBA");
    this.mlbTeams = this.leagueService.getTeams("MLB");
  }

  ngOnInit(): void {
    this.gameService.getUpcomingGamesForLeague(this.league).subscribe(data => {
      console.log("Upcoming games response: ", data);
      var games = data.upcomingGames;
      this.upcomingGames = [];
      for (var i = 0; i < games.length; i ++) {
        var game = games[i];
        var gameData = {
          "homeTeam": game['gameStats']['homeTeam'],
          "awayTeam": game['gameStats']['awayTeam'],
          "homeLogo": this.getTeamLogo(game['gameStats']['homeTeam']),
          "awayLogo": this.getTeamLogo(game['gameStats']['awayTeam']),
          "gameStartTime": this.getStartTime(game['gameStartTime'])
        }

        this.upcomingGames.push(gameData);
      }
    })
  }

  getStartTime(startTime) {
    var d = new Date(startTime);

    var minutes = d.getUTCMinutes().toString();
    if (minutes.length == 1) {
      minutes = "0" + minutes;
    }

    return (d.getUTCHours() % 12)+ ":" + minutes;
  }

  getTeamLogo(team) {
    if (this.league == "NFL") {
      return this.nflTeams[team]['logo'];
    } else if (this.league == "NBA") {
      return this.nbaTeams[team]['logo'];
    } else if (this.league == "MLB") {
      return this.mlbTeams[team]['logo'];
    }
  }


}
