import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LeagueService } from 'src/app/services/league.service';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { RequirementPickerService } from 'src/app/services/requirement-picker.service';

@Component({
  selector: 'app-requirement-stats-picker',
  templateUrl: './requirement-stats-picker.component.html',
  styleUrls: ['./requirement-stats-picker.component.css']
})
export class RequirementStatsPickerComponent implements OnInit {

  leagueChosen;
  statsAvailable;
  currentRequirement;
  constructor(private route: Router, private requirementPickerService: RequirementPickerService, private leagueService: LeagueService, private modelPickerService: ModelPickerService) { }

  ngOnInit(): void {
    this.leagueChosen = this.modelPickerService.getModel().league;
    if (this.leagueChosen) {
      this.leagueService.getStats(this.leagueChosen).subscribe(data => {
        //console.log("Retreived stats from api: " + data);
        this.statsAvailable = [];
        var statsReturned = data.stats;
        for (var i = 0; i < statsReturned.length; i ++) {
          var stat = statsReturned[i];
          console.log("Stat: ", stat);
          if (stat['publicStat'] == "true" && stat['statName'] != "underdog" && stat['statName'] != "favorite") {
            this.statsAvailable.push(stat)
          }
        }
      });
    } else {
      //console.log("League has not been chosen yet!");
    }
  }

  ngAfterViewInit() {
    this.requirementPickerService.currentRequirement.subscribe(requirement => {
      //console.log("Requirement loaded in: ", requirement);
      if (requirement.filterType && requirement.filterType == "statComparison") {
        if (requirement.statsUsed) {
          var statUsed = requirement.statsUsed;

          var statButton = document.getElementById(statUsed[0]);
          if (statButton) {
            statButton.className = "tile buttonSelected";
          }

        }
      }
    })
  }

  selectStat(stat) {
    //console.log("Stat chosen: ", stat);
    this.requirementPickerService.setStatToCompare(stat);

    var statConfig = JSON.parse(stat.stat);
    var dataType = statConfig.dataType;
    if (dataType == "BOOLEAN") {
      this.route.navigate(['/stat-boolean-picker']);
    } else if (dataType == "STRING") {
      this.route.navigate(["/stat-string-picker"]);
    } else {
      //console.log("Not sure what to do here. Sending to stat comparison picker page: " + dataType);
      this.route.navigate(["/stat-comparison-picker"]);
    }
  }
}
