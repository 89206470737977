import { Component, OnInit } from '@angular/core';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { RequirementPickerService } from 'src/app/services/requirement-picker.service';
import { ModelService } from 'src/app/services/model.service';
import { UserService } from 'src/app/services/user.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { element } from 'protractor';

@Component({
  selector: 'app-model-picker',
  templateUrl: './model-picker.component.html',
  styleUrls: ['./model-picker.component.css']
})
export class ModelPickerComponent implements OnInit {

  leagueSelected;
  timeRangeSelected;
  betsTakenSelected;
  wagerSelected;
  descriptionSelected;

  modelRequirements;
  currentModel;

  editingModel;
  editingModelId;
  creatingModel;

  currentUser;
  constructor(private userService: UserService, private requirementPickerService: RequirementPickerService, private route: Router, private authenticationService: AuthenticationService, private modelService: ModelService, private modelPickerService: ModelPickerService) { }

  ngOnInit(): void {
    var x = document.getElementById("leagueSelector");
    if (x) {
      x.style.display = "none";
    }
    //this.modelPickerService.setModelToEdit({});
    this.leagueSelected = false
    this.timeRangeSelected = false;
    this.betsTakenSelected = false;
    this.wagerSelected = false;
    this.descriptionSelected = false;

    this.authenticationService.currentUser.subscribe(user => {
      if (user && user.username) {
        this.currentUser = user;
      }
    })

    this.modelPickerService.currentModel.subscribe(model => {
      //console.log("Model loaded in: ", model);
      if (model.modelConfiguration) {
        //console.log("Has model configuration, setting model correctly.");
        this.editingModelId = model.modelId;
        this.currentModel = model.modelConfiguration;
      } else {
        //console.log("Does not have model configuration.");
        this.currentModel = model;
      }
      if (this.currentModel.league) {
        this.leagueSelected = true;
        this.closeLeagueView();
      }

      if (this.currentModel.modelStartRange && this.currentModel.modelEndRange) {
        this.timeRangeSelected = true;
        this.closeTimeRangeView();
      }

      if (this.currentModel.betsTaken) {
        this.betsTakenSelected = true;
        //console.log("Bets taken: ", this.currentModel.betsTaken);
        if (this.currentModel.betsTaken.length == 2) {
          //need to grab the 2 unselected bets and make the buttons disabled.
          if (!this.currentModel.betsTaken.includes('OVER')) {
            var x = document.getElementById('OVER');
            if (x) {
              x.setAttribute('disabled', "true");
            }
          }

          if (!this.currentModel.betsTaken.includes('UNDER')) {
            var x = document.getElementById('UNDER');
            if (x) {
              x.setAttribute('disabled', "true");
            }
          }

          if (!this.currentModel.betsTaken.includes('MONEY_LINE')) {
            var x = document.getElementById('MONEY_LINE');
            if (x) {
              x.setAttribute('disabled', "true");
            }
          }

          if (!this.currentModel.betsTaken.includes('SPREAD')) {
            var x = document.getElementById('SPREAD');
            if (x) {
              x.setAttribute('disabled', "true");
            }
          }
          this.closeBetsTakenView();
        } else {
          //enable all buttons 
          var x = document.getElementById('OVER');
          if (x) {
            x.removeAttribute('disabled');
          }

          x = document.getElementById('UNDER');
          if (x) {
            x.removeAttribute('disabled');
          }

          x = document.getElementById('MONEY_LINE');
          if (x) {
            x.removeAttribute('disabled');
          }

          x = document.getElementById('SPREAD');
          if (x) {
            x.removeAttribute('disabled');
          }
        }

        if (this.currentModel.betsTaken.includes('OVER')) {
          var x = document.getElementById("UNDER");
          if (x) {
            x.setAttribute('disabled', 'true');
          }
        }

        if (this.currentModel.betsTaken.includes('UNDER')) {
          var x = document.getElementById("OVER");
          if (x) {
            x.setAttribute('disabled', 'true');
          }
        }
      }

      if (this.currentModel.betType) {
        this.wagerSelected = true;
        this.closeWagerView();
      }

      if (this.currentModel.modelDescription) {
        //console.log("Model has model description");
        if (this.currentModel.modelDescription.length > 0 && this.currentModel.modelDescription.length <= 150) {
          this.descriptionSelected = true;
        } else {
          this.descriptionSelected = false;
        }
      }

      this.modelRequirements = this.currentModel.modelRequirements;

      //now we decide if the submit button should be enabled or disabled. It can only be enabled if there are
      //all of the metadata submitted as well as at least 1 requirement.

      var submitButton = document.getElementById("submitModelButton");
      if (this.leagueSelected && this.timeRangeSelected && this.betsTakenSelected && this.wagerSelected && this.descriptionSelected && this.modelRequirements && this.modelRequirements.length > 0) {
        if (submitButton) {
          submitButton.removeAttribute("disabled");
        }
      } else {
        if (submitButton) {
          submitButton.setAttribute("disabled", "true");
        }
      }
    })

    this.editingModel = this.modelPickerService.editingModel()
    //console.log("Editing model?: ", this.editingModel);
    if (this.editingModel) {
      var submitModelButton = document.getElementById("submitModelButton");
      if (submitModelButton) {
        submitModelButton.innerText = "Save Model"
      }
      this.creatingModel = false;
    } else {
      this.creatingModel = true;
    }
  }


  openLeagueView() {
    var x = document.getElementById("leagueSelector");
    if (x) {
      if (x.style.display == "block") {
        x.style.display = "none";
      } else {
        x.style.display = "block";
      }
    }
  }

  closeLeagueView() {
    var x = document.getElementById("leagueSelector");
    if (x) {
      x.style.display = "none";
    }
  }

  openTimeRangeView() {
    var x = document.getElementById("timeRangeSelector");
    if (x) {
      if (x.style.display == "block") {
        x.style.display = "none";
      } else {
        x.style.display = "block";
      }
    }
  }

  closeTimeRangeView() {
    var x = document.getElementById("timeRangeSelector");
    if (x) {
      x.style.display = "none";
    }
  }

  openDescriptionView() {
    var x = document.getElementById("descriptionSelector");
    if (x) {
      if (x.style.display == "block") {
        x.style.display = "none";
      } else {
        x.style.display = "block";
      }
    }
  }

  closeDescriptionView() {
    var x = document.getElementById("descriptionSelector");
    if (x) {
      x.style.display = "none";
    }
  }

  openBetsTakenView() {
    var x = document.getElementById("betsTakenSelector");
    if (x) {
      if (x.style.display == "block") {
        x.style.display = "none";
      } else {
        x.style.display = "block";
      }
    }
  }

  closeBetsTakenView() {
    var x = document.getElementById("betsTakenSelector");
    if (x) {
      x.style.display = "none";
    }
  }

  openWagerView() {
    var x = document.getElementById("wagerSelector");
    if (x) {
      if (x.style.display == "block") {
        x.style.display = "none";
      } else {
        x.style.display = "block";
      }
    }
  }

  closeWagerView() {
    var x = document.getElementById("wagerSelector");
    if (x) {
      x.style.display = "none";
    }
  }

  addRequirement() {
    this.requirementPickerService.setRequirementToEdit({}, true);
    this.route.navigate(["requirement-picker"]);
  }

  cancelEdit() {
    if (this.creatingModel || !this.currentModel.modelId ) {
      this.route.navigate(['/']);
    } else {
      var routeToGo = "/models/"+this.currentModel.modelId;
      this.route.navigate([routeToGo]);
    }
  }

  submitModel() {
    if (this.currentUser && this.currentUser.username) {
      var currentDate = new Date().getTime();
      //var day = (currentDate.getMonth() + 1) + "/" + currentDate.getDate() + "/" + currentDate.getFullYear();
      //this.currentModel.creationTimestamp = currentDate;
      if (this.editingModel) {
        var currentModel = this.modelPickerService.getModel();
        this.modelService.updateModel(this.currentUser, this.modelPickerService.getModelIdToEdit(), currentModel).subscribe(data => {
          //console.log("Success response?: ", data);
          if (!data.error) {
            var routeToGo = "/models/" + this.modelPickerService.getModelIdToEdit();
            this.route.navigate([routeToGo]);
          } else {
            //console.log("Error from updating model: ", data.error);
            window.alert(data.error);
          }
        }, error => {
          //console.log("Error updating model: ", error);
        })
      } else {
        this.modelService.createModel(this.currentUser, this.currentModel).subscribe(data => {
          //console.log("Success response?: ", data);
          //need to grab the new models model id
          if (!data.error) {
            //console.log("Data has success.");
            var modelId = data.modelId;
            this.currentModel.modelId = modelId;
            this.currentModel.userCreatedBy = this.currentUser.username;
            //console.log("About to add to models created.");
            //this.modelService.addModelCreatedByUser(this.currentModel);
            this.userService.addLocalModelCreatedByUser(this.currentModel);
            //console.log("Added to models created.");
            var routeToGo = "/models/" + this.currentModel.modelId;
            //console.log("About to navigate...");
            this.route.navigate([routeToGo]);
            //console.log("Should have navigated?");
          } else {
            //console.log("Error from creating model: ", data.error);
            window.alert(data.error);
          }
        }, error => {
          //console.log("Error creating model: ", error);
        })
      }
    } else {
      //console.log("User is not logged in.");
        window.alert("Please sign in to save your model.");
    }
  }

}
