<ng-container *ngIf="gamesStored && gamesStored.length > 0">
    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" opened="true">
          <div class="sideNavSeason" *ngFor="let season of gamesStored" id={{season}} (click)="toggleSeason(season)">
              <span class="seasonItem">{{season}}</span>
          </div>
        </mat-drawer>
        <div class="example-sidenav-content">
            <div *ngIf="seasonShowing && gamesMap[seasonShowing]">
                <div *ngFor="let game of gamesMap[seasonShowing]" style="border-bottom: 1px solid lightgray;">
                    <div id={{game.gameData.awayTeamAbbr}} (click)="showGameDetails(game)">
                        <div class="grid-container">
                            <div class="gameDate">{{game.gameData.date}}</div>
                            <div class="awayTeamLogo">
                                <img class="teamLogo" src="{{game.gameData['awayTeamLogo']}}">
                            </div>
                            <div class="awayTeamScore">{{game.gameData.awayTeamPoints}}</div>
                            <div class="awayTeamName">{{game.gameData.awayTeamAbbr}}</div>
    
                            <div class="vs">vs.</div>
                            <div class="homeTeamScore">{{game.gameData.homeTeamPoints}}</div>
                            <div class="homeTeamLogo">
                                <img class="teamLogo" src="{{game.gameData['homeTeamLogo']}}">
                            </div>
                            <div class="homeTeamName">{{game.gameData.homeTeamAbbr}}</div>
                            <div class="bets">
                                <div class="bet1">
                                    {{game.bets[0].betName}}
                                </div>
                                <div class="bet2" *ngIf="game.bets[1]">{{game.bets[1].betName}}</div>
                            </div>
                            <div class="betResults">
                                <div class="win1">
                                    <mat-icon class='check' *ngIf="game.bets[0].win == 'WIN'">check</mat-icon>
                                    <mat-icon class='cancel' *ngIf="game.bets[0] && game.bets[0].win != 'WIN'">cancel</mat-icon>
                                </div>
                                <div class="win2">
                                    <mat-icon class='check' *ngIf="game.bets[1] && game.bets[1].win == 'WIN'">check</mat-icon>
                                    <mat-icon class='cancel' *ngIf="game.bets[1] && game.bets[1].win != 'WIN'">cancel</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </mat-drawer-container>
</ng-container>

<ng-container *ngIf="gamesStored && gamesStored.length == 0">
    <br>
    No games meet model requirements!
</ng-container>