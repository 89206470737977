import { Component, OnInit } from '@angular/core';
import { ModelService } from 'src/app/services/model.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { RequirementPickerService } from 'src/app/services/requirement-picker.service';

@Component({
  selector: 'app-requirement-stat-boolean-picker',
  templateUrl: './requirement-stat-boolean-picker.component.html',
  styleUrls: ['./requirement-stat-boolean-picker.component.css']
})
export class RequirementStatBooleanPickerComponent implements OnInit {

  statToCompare;
  statForOpponent;
  statIsTrue;
  canEdit;
  currentUser;
  constructor(private modelService: ModelService, private authenticationService: AuthenticationService, private route: Router, private modelPickerService: ModelPickerService, private requirementPickerService: RequirementPickerService) { 
  }

  ngOnInit(): void {
    this.statToCompare = this.requirementPickerService.getStatToCompare();
    this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    })
    this.canEdit = this.requirementPickerService.canEditRequirement();
  }

  ngAfterContentInit() {
    this.requirementPickerService.currentRequirement.subscribe(requirement => {
      if (requirement.forOpponent != null) {
        var forOpponent = Boolean(requirement.forOpponent);
        if (forOpponent) {
          this.statForOpponent = true;
          var opposingTeamButton = document.getElementById("opposingTeamButton");
          if (opposingTeamButton) {
            opposingTeamButton.className = "buttonSelected";
          }

          var secondTeamButton = document.getElementById("secondTeamButton");
          if (secondTeamButton) {
            secondTeamButton.innerText = "My Team's " + this.statToCompare.statName;
          }
        } else {
          this.statForOpponent = false;
          var myTeamButton = document.getElementById("myTeamButton");
          var opposingTeamButton = document.getElementById("opposingTeamButton");
          if (myTeamButton) {
            myTeamButton.className = "buttonSelected";
          }

          if (opposingTeamButton) {
            opposingTeamButton.className = "";
          }

          this.statForOpponent = false;

          var secondTeamButton = document.getElementById("secondTeamButton");
          if (secondTeamButton) {
            secondTeamButton.innerText = "The Other Team's " + this.statToCompare.statName;
          }
        }
      }

      if (requirement.statIsTrue != null) {
        var statIsTrue = Boolean(requirement.statIsTrue);
        if (statIsTrue) {
          this.statIsTrue = true;
          var yesButton = document.getElementById("yesButton");
          if (yesButton) {
            yesButton.className = "buttonSelected";
          }
        } else {
          this.statIsTrue = false;
          var noButton = document.getElementById("noButton");
          if (noButton) {
            noButton.className = "buttonSelected";
          }
        }
      }
    })
  }

  selectMyTeam() {
    var myTeamButton = document.getElementById("myTeamButton");
    var opposingTeamButton = document.getElementById("opposingTeamButton");
    if (myTeamButton) {
      myTeamButton.className = "buttonSelected";
    }

    if (opposingTeamButton) {
      opposingTeamButton.className = "";
    }

    this.statForOpponent = false;
  }

  selectOpposingTeam() {
    var myTeamButton = document.getElementById("myTeamButton");
    var opposingTeamButton = document.getElementById("opposingTeamButton");
    if (opposingTeamButton) {
      opposingTeamButton.className = "buttonSelected";
    }

    if (myTeamButton) {
      myTeamButton.className = "";
    }
    
    this.statForOpponent = true;
  }

  selectYes() {
    var yesButton = document.getElementById("yesButton");
    var noButton = document.getElementById("noButton");
    if (yesButton) {
      yesButton.className = "buttonSelected";
    }

    if (noButton) {
      noButton.className = "";
    }

    this.statIsTrue = true;
  }

  selectNo() {
    var noButton = document.getElementById("noButton");
    var yesButton = document.getElementById("yesButton");
    if (noButton) {
      noButton.className = "buttonSelected";
    }

    if (yesButton) {
      yesButton.className = "";
    }

    this.statIsTrue = false;
  }

  saveRequirement() {
    var statsUsed = new Array();
    statsUsed.push(this.statToCompare.statName);

    var description = "";
    if (this.statForOpponent) {
      description += "Other team ";
    } else {
      description += "My team ";
    }

    description += this.statToCompare.statName;

    if (this.statIsTrue) {
      description += " is true";
    } else {
      description += " is false";
    }

    var requirement = {
      "description": description,
      "forOpponent": this.statForOpponent,
      "filterType": "STAT",
      "statsUsed": statsUsed,
      "statExpectedValue": this.statIsTrue
    }

    //console.log("Saving requirement: ", requirement);

    if (this.requirementPickerService.editingRequirement()) {
      this.modelPickerService.updateRequirement(requirement);
      var currentModel = this.modelPickerService.getModelToEdit();
      var modelId = currentModel.modelId;
      if (this.canEdit) {
        this.modelService.updateModel(this.currentUser, this.modelPickerService.getModelIdToEdit(), currentModel).subscribe(data => {
          if (data.error) {
            //console.log("Error updating requirement: ", data.error);
            window.alert(data.error);
          } else {
            var routeToGo = "/models/" + this.modelPickerService.getModelIdToEdit();
            this.route.navigate([routeToGo]);
          }
        })
      } else {
        this.route.navigate(["/model-picker"])
      }
    } else { 
      this.modelPickerService.addRequirement(requirement);
      this.route.navigate(["/model-picker"]);
    }
  }
}
