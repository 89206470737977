import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModelPickerService } from '../../services/model-picker.service';
import { LeagueService } from '../../services/league.service'
import { AuthenticationService } from '../../services/authentication.service';
import { GameService } from '../../services/game.service';
import { ModelService } from '../../services/model.service';
import { UpcomingGamesPopupComponent } from '../upcoming-games-popup/upcoming-games-popup.component';


@Component({
  selector: 'app-upcoming-games-for-model',
  templateUrl: './upcoming-games-for-model.component.html',
  styleUrls: ['./upcoming-games-for-model.component.css']
})
export class UpcomingGamesForModelComponent implements OnInit {

  upcomingGames;
  currentModel;
  nflTeams;
  nbaTeams;
  mlbTeams;

  constructor(public leagueService: LeagueService, public dialog: MatDialog, private gameService: GameService, private authenticationiService: AuthenticationService, private modelService: ModelService, private modelPickerService: ModelPickerService) { 
    this.nflTeams = this.leagueService.getTeams("NFL");
    this.nbaTeams = this.leagueService.getTeams("NBA");
    this.mlbTeams = this.leagueService.getTeams("MLB");
  }

  ngOnInit(): void {
    this.modelPickerService.currentModel.subscribe(model => {
      console.log("Model loaded into games detail view component: ", model);
      this.currentModel = model;
      var modelId = this.modelPickerService.getModelIdToEdit();

      this.modelService.getUpcomingGamesThatMeetModel(modelId).subscribe(data => {
        console.log("Received upcoming games that meet model: ", data);
        var upcomingGames = data.upcomingGames;

        var newUpcomingGames:any[] = [];
        for (var i = 0; i < upcomingGames.length; i ++) {
          var upcomingGame = upcomingGames[i];
          var gameStats = upcomingGame.gameStats;
          var homeTeamStats = upcomingGame.homeTeamStats;
          var awayTeamStats = upcomingGame.awayTeamStats;
          var bettingStats = upcomingGame.bettingStats;
          var betsToTake = upcomingGame.betsToTake;

          var homeMoneyLine = Number(bettingStats.homeTeamMoneyLine);
          var awayMoneyLine = Number(bettingStats.awayTeamMoneyLine);
          //console.log("Home ml: " + homeMoneyLine);
          //console.log("Away ml: " + awayMoneyLine);
          if (homeMoneyLine < awayMoneyLine) {
            bettingStats.favorite = gameStats.homeTeam;
            bettingStats.favoriteSpread = bettingStats.homeTeamSpread;
          } else {
            bettingStats.favorite = gameStats.awayTeam;
            bettingStats.favoriteSpread = bettingStats.awayTeamSpread;
          }

          var gameData = {
            "homeTeamAbbr": gameStats.homeTeam,
            "awayTeamAbbr": gameStats.awayTeam,
            "date": gameStats.gameDate,
            "homeTeamLogo": this.getTeamLogo(gameStats.homeTeam),
            "awayTeamLogo": this.getTeamLogo(gameStats.awayTeam),
            "bet1": this.getBetToTake(betsToTake[0], gameStats, bettingStats),
            "bettingStats": bettingStats,
            "extraStats": {
              "homeTeamStats": homeTeamStats,
              "awayTeamStats": awayTeamStats
            }
          }

          if (betsToTake[1]) {
            gameData['bet2'] = this.getBetToTake(betsToTake[1], gameStats, bettingStats);
          }

          newUpcomingGames.push({
            "gameData": gameData
          });
        }

        this.upcomingGames = newUpcomingGames;


      })

    })
  }

  showGameDetails(game) {
    console.log("Show game details: ", game);
    this.dialog.open(UpcomingGamesPopupComponent, {
      data: game
    });
  }

  getTeamLogo(team) {
    if (this.currentModel.league == "NFL") {
      return this.nflTeams[team]['logo'];
    } else if (this.currentModel.league == "NBA") {
      return this.nbaTeams[team]['logo'];
    } else if (this.currentModel.league == "MLB") {
      return this.mlbTeams[team]['logo'];
    }
  }

  getBetToTake(bet, gameStats, bettingStats) {
    if (bet == "OVER") {
      return "Over " + bettingStats['overUnder'];
    }

    if (bet == "UNDER") {
      return "Under " + bettingStats['overUnder'];
    }

    if (bet == "HOME_TEAM_MONEYLINE") {
      var team = gameStats['homeTeam'];
      var line = bettingStats['homeTeamMoneyLine'];

      return team + " ML (" + line + ")";
    }

    if (bet == "AWAY_TEAM_MONEYLINE") {
      var team = gameStats['awayTeam'];
      var line = bettingStats["awayTeamMoneyLine"];

      return team + " ML (" + line + ")";
    }

    if (bet == "HOME_TEAM_SPREAD") {
      var team = gameStats['homeTeam'];
      var spread = bettingStats["homeTeamSpread"];
      if (Number(spread) > 0) {
        return team + " + " + spread
      } else {
        return team + " " + spread
      }
    }

    if (bet == "AWAY_TEAM_SPREAD") {
      var team = gameStats['awayTeam'];
      var spread = bettingStats["awayTeamSpread"];
      if (Number(spread) > 0) {
        return team + " + " + spread
      } else {
        return team + " " + spread
      }
    }

    return "";
  }
}
