<h1 mat-dialog-title style="text-align: center;">Game Details</h1>
<div mat-dialog-content>

    <div class="grid-container">
        <div class="gameDate">{{data.gameData.date}}</div>
        <div class="awayName">{{data.gameData.awayTeamAbbr}}</div>
        <div class="awayMoneyLine">({{data.gameData.bettingStats.awayTeamMoneyLine}})</div>
        <div class="awayLogo">
            <img class="teamLogo" src="{{data.gameData.awayTeamLogo}}">
        </div>
        <div class="vs">vs.</div>
        <div class="homeLogo">
            <img class="teamLogo" src="{{data.gameData.homeTeamLogo}}">
        </div>
        <div class="homeMoneyLine">({{data.gameData.bettingStats.homeTeamMoneyLine}})</div>
        <div class="homeName">{{data.gameData.homeTeamAbbr}}</div>
        <div class="line">Line: {{data.gameData.bettingStats.favorite}} -{{data.gameData.bettingStats.favoriteSpread}}</div>
        <div class="total">O/U: {{data.gameData.bettingStats.overUnder}}</div>
      </div>

      <div class="extraFields" *ngIf="fields.length > 0">
          <h3 style="text-align: center; margin-bottom: 0;">Specific Stats</h3>
        <div class="headerContainer">
            <div class="awayLogoHeader">
                <img class="teamLogoHeader" src="{{data.gameData.awayTeamLogo}}">
            </div>
            <div class="homeLogoHeader">
                <img class="teamLogoHeader" src="{{data.gameData.homeTeamLogo}}">
            </div>
        </div>

        <div class='extraFields'>
            <ng-container *ngFor='let extraField of fields'>
                <app-extra-field [home]="extraField.home" [away]="extraField.away" [stat]="extraField.stat"></app-extra-field>
            </ng-container>
        </div>
</div>