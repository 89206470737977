<div class="modelOverview">
    <mat-card class="example-card">
        <div class="mat-card-header-div">
          <!-- <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>@{{model.userCreatedBy}}</mat-card-title>
          <mat-card-subtitle>@{{model.userCreatedBy}}</mat-card-subtitle> -->



          <div class="header-container">
            <div mat-card-avatar class="example-header-image avatar"></div>
            <div class="name">
                <mat-card-title routerLink="/users/{{model.userCreatedBy}}">@{{model.userCreatedBy}}</mat-card-title>
            </div>
            <div class="header-followers">
                <button class="card-button" mat-stroked-button (click)="viewModelSubscribers()">{{modelSubscriberCountText}}</button>
            </div>
            <div class="header-date">{{modelCreationDate}}</div>
            <!-- <div class="follow" *ngIf="!userFollowsModel">
                <button mat-button (click)="followModel()">Follow</button>
            </div>
            <div class="unfollow" *ngIf="userFollowsModel">
                <button mat-button (click)="unfollowModel()">Following</button>
            </div> -->

            <div class="edit" *ngIf='userOwnsModel'>
                <button class="menuButton" mat-icon-button [matMenuTriggerFor]="menu"  aria-label="Example icon-button with a menu">
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class='editMenu' (click)="editModel()">
                        <mat-icon class="blackIcon">edit</mat-icon >
                        <span>Edit</span>
                    </button>
                    <button mat-menu-item class='deleteMenu' (click)="deleteModel()">
                        <mat-icon class="redIcon">delete_outline</mat-icon>
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </div>

            <!-- <div class="edit" *ngIf="userOwnsModel">
                <button mat-button (click)="editModel()">
                    <mat-icon>edit</mat-icon>
                    Edit
                </button>
            </div>
            <div class="delete" *ngIf="userOwnsModel">
                <button mat-button (click)="deleteModel()">
                    <mat-icon class="deleteIcon">delete</mat-icon>
                </button>
            </div> -->
            <div class="followOrUnfollow" *ngIf="!userOwnsModel">
                <div class="follow" *ngIf="!userFollowsModel">
                    <button mat-button (click)="followModel()">Follow</button>
                </div>
                <div class="unfollow" *ngIf="userFollowsModel">
                    <button mat-button (click)="unfollowModel()">Following</button>
                </div>
            </div>
          </div>
        </div>
        <mat-card-content>
            <ng-container *ngIf="model.gamesStored && model.gamesStored.length > 0">
                <div class="grid-container">
                    <div class="circle1" *ngIf="ddbModelPerformance && ddbModelPerformance.overallProfitability && modelPerformance[bet1]">
                        <app-model-detail-performance-circle-view value="{{modelPerformance[bet1]['winPercentage']}}" units="{{modelPerformance[bet1]['units']}}" name={{bet1}} color="blue"></app-model-detail-performance-circle-view>
                    </div>
                    <div class="circle2" *ngIf="bet2 && ddbModelPerformance && ddbModelPerformance.overallProfitability">
                        <app-model-detail-performance-circle-view value="{{modelPerformance[bet2]['winPercentage']}}" units="{{modelPerformance[bet2]['units']}}" name={{bet2}} color="limegreen"></app-model-detail-performance-circle-view>
                    </div>
                    <!-- <div class="bet2">Spread</div>
                    <div class="bet1">Under</div> -->
                    <div class="modelDescription"><p>{{modelDescription}}</p></div>
                    <div class="top3" *ngIf="model.top3Games"> 
                        <div class="header1">Recent Games</div>
                        <div class="win1">{{bet1Label}}</div>
                        <div class="win2" *ngIf="bet2">{{bet2Label}}</div>
                        <div class="date1">{{top3Games[0].date}}</div>
                        <div class="a">
                            {{top3Games[0]['awayTeamName']}}
                            <img class="teamLogo" src="{{top3Games[0]['awayTeamLogo']}}">
                        </div>
                        <div class="b">
                            vs
                        </div>
                        <div class="c">
                        <img class="teamLogo" src="{{top3Games[0]['homeTeamLogo']}}">
                        {{top3Games[0]['homeTeamName']}}
                        </div>
                        <div class="d">
                            <mat-icon class='check' *ngIf="top3Games[0]['bet1'] == 'WIN'">check</mat-icon>
                            <mat-icon class='cancel' *ngIf="top3Games[0]['bet1'] != 'WIN'">cancel</mat-icon>
                        </div>
                        <div class="e">
                            <mat-icon class='check' *ngIf="top3Games[0]['bet2'] && top3Games[0]['bet2'] == 'WIN'">check</mat-icon>
                            <mat-icon class='cancel' *ngIf="top3Games[0]['bet2'] && top3Games[0]['bet2'] != 'WIN'">cancel</mat-icon>
                        </div>
                        <div class="date2" *ngIf="top3Games[1]">{{top3Games[1].date}}</div>
                        <div class="f" *ngIf="top3Games[1]">
                            {{top3Games[1]['awayTeamName']}}
                            <img class="teamLogo" src="{{top3Games[1]['awayTeamLogo']}}">
                        </div>
                        <div class="g" *ngIf="top3Games[1]">vs</div>
                        <div class="h" *ngIf="top3Games[1]">
                        <img class="teamLogo" src="{{top3Games[1]['homeTeamLogo']}}">
                        {{top3Games[1]['homeTeamName']}}
                        </div>
                        <div class="i" *ngIf="top3Games[1]">
                            <mat-icon class='check' *ngIf="top3Games[1]['bet1'] && top3Games[1]['bet1'] == 'WIN'">check</mat-icon>
                            <mat-icon class='cancel' *ngIf="top3Games[1]['bet1'] && top3Games[1]['bet1'] != 'WIN'">cancel</mat-icon>
                        </div>
                        <div class="j" *ngIf="top3Games[1]">
                            <mat-icon class='check' *ngIf="top3Games[1]['bet2'] && top3Games[1]['bet2'] == 'WIN'">check</mat-icon>
                            <mat-icon class='cancel' *ngIf="top3Games[1]['bet2'] && top3Games[1]['bet2'] != 'WIN'">cancel</mat-icon>
                        </div>
                        <div class="date3" *ngIf="top3Games[2]">{{top3Games[2].date}}</div>
                        <div class="k" *ngIf="top3Games[2]">
                            {{top3Games[2]['awayTeamName']}}
                            <img class="teamLogo" src="{{top3Games[2]['awayTeamLogo']}}">
                        </div>
                        <div class="l" *ngIf="top3Games[2]">vs</div>
                        <div class="m" *ngIf="top3Games[2]">
                        <img class="teamLogo" src="{{top3Games[2]['homeTeamLogo']}}">
                        {{top3Games[2]['homeTeamName']}}
                        </div>
                        <div class="n" *ngIf="top3Games[2]">
                            <mat-icon class='check' *ngIf="top3Games[2]['bet1'] && top3Games[2]['bet1'] == 'WIN'">check</mat-icon>
                            <mat-icon class='cancel' *ngIf="top3Games[2]['bet1'] && top3Games[2]['bet1'] != 'WIN'">cancel</mat-icon>
                        </div>
                        <div class="o" *ngIf="top3Games[2]">
                            <mat-icon class='check' *ngIf="top3Games[2]['bet2'] && top3Games[2]['bet2'] == 'WIN'">check</mat-icon>
                            <mat-icon class='cancel' *ngIf="top3Games[2]['bet2'] && top3Games[2]['bet2'] != 'WIN'">cancel</mat-icon>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!model.gamesStored || model.gamesStored.length == 0">
                <br>
                No games meet model requirements!
            </ng-container>
        </mat-card-content>
        <mat-card-actions>
          <button class="view-model-button" mat-raised-button routerLink="/models/{{model.modelId}}">VIEW MODEL</button>
        </mat-card-actions>
    </mat-card>
</div>

<!-- <div class="grid-container">
    <div class="circle1">circle1</div>
    <div class="circle2">circle2</div>
    <div class="modelDescription">modelDescription</div>
    <div class="top3GamesHeader">top3GamesHeader</div>
    <div class="win1">win1</div>
    <div class="win2">win2</div>
    <div class="bet1Game">bet1Game</div>
    <div class="bet2Game">bet2Game</div>
    <div class="bet3Game">bet3Game</div>
    <div class="bet1Win1">bet1Win1</div>
    <div class="bet1Win2">bet1Win2</div>
    <div class="bet2Win1">bet2Win1</div>
    <div class="bet2Win2">bet2Win2</div>
    <div class="bet3Win1">bet3Win1</div>
    <div class="bet3Win2">bet3Win2</div>
    <div class="followerCount">followerCount</div>
    <div class="dateCreated">dateCreated</div>
  </div> -->