import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './components/home-page/home-page.component';
import { RequirementPickerComponent } from './components/requirement-picker/requirement-picker.component';
import { ModelPickerComponent } from './components/model-picker/model-picker.component';
import { ModelDetailPerformanceViewComponent } from './components/model-detail-performance-view/model-detail-performance-view.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SignOutComponent } from './components/sign-out/sign-out.component';
import { ProfileDetailViewComponent } from './components/profile-detail-view/profile-detail-view.component';
import { ModelDetailViewComponent } from './components/model-detail-view/model-detail-view.component';
import { RequirementHomeAwayPickerComponent } from './components/requirement-home-away-picker/requirement-home-away-picker.component';
import { RequirementFavoriteUnderdogPickerComponent } from './components/requirement-favorite-underdog-picker/requirement-favorite-underdog-picker.component';
import { RequirementGameVariablesPickerComponent } from './components/requirement-game-variables-picker/requirement-game-variables-picker.component';
import { RequirementSpecificTeamsPickerComponent } from './components/requirement-specific-teams-picker/requirement-specific-teams-picker.component';
import { RequirementStatsPickerComponent } from './components/requirement-stats-picker/requirement-stats-picker.component';
import { RequirementStatComparisonPickerComponent } from './components/requirement-stat-comparison-picker/requirement-stat-comparison-picker.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { RequirementStatBooleanPickerComponent } from './components/requirement-stat-boolean-picker/requirement-stat-boolean-picker.component';
import { RequirementStatStringComparisonPickerComponent } from './components/requirement-stat-string-comparison-picker/requirement-stat-string-comparison-picker.component';

const routes: Routes = [
  { path: "", component: HomePageComponent },
  { path: "model-picker", component: ModelPickerComponent },
  { path: "requirement-picker", component: RequirementPickerComponent },
  { path: "model-results", component: ModelDetailPerformanceViewComponent},
  { path: "signin", component: SignInComponent},
  { path: "signup", component: SignUpComponent},
  { path: "signout", component: SignOutComponent},
  { path: "users/:userId", component: ProfileDetailViewComponent},
  { path: "models/:modelId", component: ModelDetailViewComponent},
  { path: "profile", component: ProfileDetailViewComponent},
  { path: "home-away-picker", component: RequirementHomeAwayPickerComponent},
  { path: "favorite-underdog-picker", component: RequirementFavoriteUnderdogPickerComponent},
  { path: "game-variables-picker", component: RequirementGameVariablesPickerComponent},
  { path: "specific-teams-picker", component: RequirementSpecificTeamsPickerComponent},
  { path: "stats-picker", component: RequirementStatsPickerComponent},
  { path: "stat-string-picker", component: RequirementStatStringComparisonPickerComponent},
  { path: "stat-boolean-picker", component: RequirementStatBooleanPickerComponent},
  { path: "stat-comparison-picker", component: RequirementStatComparisonPickerComponent},
  { path: "user-list", component: UserListComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
