import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ModelPickerComponent } from '../model-picker/model-picker.component';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { ModelService } from 'src/app/services/model.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ModelDetailTabViewComponent } from '../model-detail-tab-view/model-detail-tab-view.component';
import { UserService } from 'src/app/services/user.service';
import { UserListService } from 'src/app/services/user-list.service';

@Component({
  selector: 'app-model-detail-view',
  templateUrl: './model-detail-view.component.html',
  styleUrls: ['./model-detail-view.component.css']
})
export class ModelDetailViewComponent implements AfterViewInit {
  
  @ViewChild(ModelDetailTabViewComponent)
  private tabComponent: ModelDetailTabViewComponent;

  modelId;
  modelDetails;
  userLoggedIn;

  userOwnsModel;
  userFollowsModel;
  modelSubscribers;
  modelSubscriberCountText;
  modelCreationDate;

  constructor(private userListService: UserListService, private userService: UserService, private authenticationService: AuthenticationService, private modelPickerService: ModelPickerService, private modelService: ModelService, private route: Router, public activatedRoute: ActivatedRoute) { }

  ngAfterViewInit(): void {
    //console.log("map: ", this.activatedRoute.snapshot.paramMap)
    this.modelId = this.activatedRoute.snapshot.paramMap.get('modelId');
    //console.log("Routed to model detail with this id: ", this.modelId);
    this.authenticationService.currentUser.subscribe(user => {
      if (user && user.username) {
        //console.log("Got user logged in: ", user);
        this.userLoggedIn=user;
  
        this.getModelDetails();
        this.getModelSubscribers();
      }
    });

    this.getModelDetails();
    this.getModelSubscribers();
  }

  getModelDetails() {
    this.modelService.getModel(this.modelId).subscribe(model => {
      //console.log("Received model from database: ", model);
      this.modelDetails = model;
      if (model.modelPerformance) {
        this.modelDetails.modelPerformance = JSON.parse(model.modelPerformance);
      }

      if (model.top3Games) {
        this.modelDetails.top3Games = JSON.parse(model.top3Games);
      }

      this.modelDetails.modelConfiguration = JSON.parse(model.modelConfiguration);
      this.modelDetails.modelDescription = this.modelDetails.modelConfiguration.modelDescription;
      this.modelPickerService.setModelToEdit(this.modelDetails, false);
      this.modelPickerService.setModelIdToEdit(this.modelDetails.modelId);
      if (this.userLoggedIn && this.userLoggedIn.username) {
        this.userOwnsModel = (this.userLoggedIn.username == this.modelDetails.userCreatedBy);
        //console.log("user owns model?: ", this.userOwnsModel);
      }

      if (this.modelDetails.modelPerformance && this.modelDetails.modelPerformance != {}) {
        //console.log("Has model performance!!!");
        this.modelPickerService.setModelPerformance(this.modelDetails.modelPerformance);
        this.modelPickerService.setTop3Games(this.modelDetails.top3Games);
        this.modelPickerService.setGamesStored(this.modelDetails.gamesStored);
      } else {
        //console.log("No model performance found. We need to run the model!");
        this.runModel();
      }

      if (this.modelDetails.creationTimestamp) {
        var date = new Date(this.modelDetails.creationTimestamp);
        //console.log("Date:", date);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        this.modelCreationDate = month + "/" + day + "/" + year;
      }

      //console.log("Wager found: ", this.modelDetails.wager)
    })
  }

  getModelSubscribers() {
    this.modelService.getModelSubscribers(this.modelId).subscribe(subscribers => {
      //console.log("Got the subscribers: ", subscribers);
      this.modelSubscribers = subscribers;
      if (this.userLoggedIn && this.userLoggedIn.username) {
        this.userFollowsModel = (this.modelSubscribers.includes(this.userLoggedIn.username));
      }
      this.updateSubscriberText();
    }, error => {
      //console.log("Error getting model subscribers; ", error);
    })
  }

  editModel() {
    this.modelPickerService.setModelToEdit(this.modelDetails.modelConfiguration, false);
    this.modelPickerService.setModelIdToEdit(this.modelDetails.modelId);
    this.route.navigate(['/model-picker']);
  }

  runModel() {
    if (this.userLoggedIn && this.userLoggedIn.username) {
      var self = this;
      setTimeout(function() {
        self.tabComponent.viewPerformanceTab();
      }, 50)
      this.modelService.runModel(this.userLoggedIn, this.modelId).subscribe(data => {
        console.log("Response from running model: ", data);
        if (data.error) {
          console.log("Error running model: ", data.error);
          window.alert("Error running model: " + data.error)
        } else {
          console.log("Successful model run.");
          var performance = {
            "individualSeasonProfitability": data.modelPerformance.individualSeasonProfitability,
            "overallProfitability": data.modelPerformance.overallProfitability
          }
  
          this.modelDetails.modelPerformance = performance;
          this.modelDetails.top3Games = data.top3Games;
          this.modelDetails.gamesStored = data.gamesStored;
          this.modelDetails.gamesThatMeetModel = data.gamesThatMeetModel;
          this.modelPickerService.setModelToEdit(this.modelDetails, false);
          this.modelPickerService.setModelIdToEdit(this.modelDetails.modelId);
  
          this.modelPickerService.setModelPerformance(performance);
          this.modelPickerService.setTop3Games(data.top3Games);
          this.modelPickerService.setGamesStored(data.gamesStored);
          this.modelPickerService.setGamesThatMeetModelAfterRun(data.gamesThatMeetModel);
          console.log("See!!!! Things can run after we have already switched tabs!!!!")
          this.userService.setModelDetailsOnCreation(this.modelId, this.modelDetails);
        }
      }, error => {
        console.log("Error running model: ", error);
        window.alert("Error Running Model. Probably just a timeout error. Try Refreshing a couple times.");
      })
    } else {
      this.route.navigate(['/signin'])
    }
  }

  followModel() {
    if (this.userLoggedIn && this.userLoggedIn.username) {
      //console.log("Following model...");

      this.userService.subscribeToModel(this.userLoggedIn, this.modelId).subscribe(data => {
        //console.log("Successful subscribing to model?: ", data);
        this.userFollowsModel = true;
        this.modelSubscribers.push(this.userLoggedIn.username);
        this.updateSubscriberText();
      }, error => {
        //console.log("Error subscribing to model: ", error);
      })
    } else {
      this.route.navigate(['/signin'])
    }
  }

  unfollowModel() {
    if (this.userLoggedIn && this.userLoggedIn.username) {
      //console.log("Unfollowing model...");

      this.userService.unsubscribeFromModel(this.userLoggedIn, this.modelId).subscribe(data => {
        //console.log("Successful unsubscribing from model : ", data);
        this.userFollowsModel = false;
        var index = this.modelSubscribers.indexOf(this.userLoggedIn.username);
        this.modelSubscribers.splice(index, 1);
        this.updateSubscriberText();
      }, error => {
        //console.log("Error unsubscribing from model: ", error);
      })
    } else {
      this.route.navigate(['/signin'])
    }
  }

  viewModelSubscribers() {
    //console.log("Viewing model subscriber list.");
    this.userListService.setText("Model Followers");
    this.userListService.setUserList(this.modelSubscribers);
    this.route.navigate(["/user-list"]);
  }

  updateSubscriberText() {
    if (this.modelSubscribers.length == 1) {
      this.modelSubscriberCountText = "1 Follower";
    } else {
      this.modelSubscriberCountText = this.modelSubscribers.length + " Followers";
    }  
  }
}
