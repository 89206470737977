<!-- <div id="home">
    <button (click)="selectHome()" id="homeButton">
        <app-requirement-category-picker title="home" image="assets/images/house.png"></app-requirement-category-picker>
    </button>
</div>

<div id="away">
    <button (click)="selectAway()" id="awayButton">
        <app-requirement-category-picker title="away" image="assets/images/visitingTeam.jpeg"></app-requirement-category-picker>
    </button>
</div> -->

<div class="grid-container">
    <div class="title">Home or Away</div>
    <div class="home">
        <button class="picker" mat-raised-button (click)="selectHome()" id="homeButton">
            <!-- <app-requirement-category-picker title="home" image="assets/images/house.png"></app-requirement-category-picker> -->
            Home
        </button>
    </div>
    <div class="away">
        <button class="picker" mat-raised-button (click)="selectAway()" id="awayButton">
            <!-- <app-requirement-category-picker title="away" image="assets/images/visitingTeam.jpeg"></app-requirement-category-picker> -->
            Away
        </button>
    </div>
</div>