import { Component, OnInit, Input } from '@angular/core';
import { ModelService } from 'src/app/services/model.service';
import { UserService } from 'src/app/services/user.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { RequirementPickerService } from 'src/app/services/requirement-picker.service';
import { Router } from '@angular/router';
import { ModelPickerService } from 'src/app/services/model-picker.service';

@Component({
  selector: 'app-model-detail-requirements-view',
  templateUrl: './model-detail-requirements-view.component.html',
  styleUrls: ['./model-detail-requirements-view.component.css']
})
export class ModelDetailRequirementsViewComponent implements OnInit {

  modelDetails;
  canDelete;
  currentUser;
  modelRequirements;

  constructor(private authenticationService: AuthenticationService, private modelPickerService: ModelPickerService) { }

  ngOnInit(): void {
    this.modelPickerService.currentModel.subscribe(model => {
      if (model.modelConfiguration) {
        this.modelDetails = model.modelConfiguration;
      } else {
        this.modelDetails = model;
      }
      console.log("Model loaded in detail requirements view: ", this.modelDetails);
    
      this.modelRequirements = this.modelDetails.modelRequirements;
      //console.log("Loading model requirements: ", this.modelDetails);
      //console.log("model requirements: ", this.modelRequirements);
      this.authenticationService.currentUser.subscribe(user => {
        if (user && user.username) {
          this.currentUser = user;

          this.canDelete = (this.currentUser.username == this.modelDetails.userCreatedBy)
        }
      })
    })
  }

}
