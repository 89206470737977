<div *ngIf="liveGames && liveGames.length == 0">
    <p>No live games</p>
</div>
<div *ngFor="let game of liveGames">
    <div class="container" (click)="showGameDetails(game)">
        <div class="awayLogo">
            <img class="teamLogo" src="{{game.awayLogo}}">
        </div>
        <div class="awayName">{{game.awayTeam}}</div>
        <div class="awayScore">{{game.awayTeamScore}}</div>
        <div class="homeLogo">
            <img class="teamLogo" src="{{game.homeLogo}}">
        </div>
        <div class="homeName">{{game.homeTeam}}</div>
        <div class="homeScore">{{game.homeTeamScore}}</div>
        <div class="gameStatus">{{game.gameStatus}}</div>
    </div>
</div>