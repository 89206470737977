import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { RequirementPickerService } from 'src/app/services/requirement-picker.service';
import { ModelService } from 'src/app/services/model.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-requirement-favorite-underdog-picker',
  templateUrl: './requirement-favorite-underdog-picker.component.html',
  styleUrls: ['./requirement-favorite-underdog-picker.component.css']
})
export class RequirementFavoriteUnderdogPickerComponent implements OnInit {

  currentUser;
  canEdit;
  constructor(private authenticationService: AuthenticationService, private modelService: ModelService, private requirementPickerService: RequirementPickerService, private route: Router, private modelPickerService: ModelPickerService) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

    this.requirementPickerService.currentRequirement.subscribe(requirement =>{
      //console.log("Found current requirement: ", requirement);
      if (requirement.filterType && requirement.filterType == "STAT") {
        if (requirement.statsUsed) {
          if (requirement.statsUsed[0] == "favorite") {
            //console.log("Favorite stat required!");
            var favoriteButton = document.getElementById("favoriteButton");
            if (favoriteButton) {
              favoriteButton.className = "buttonSelected";
            }

            var underdogButton = document.getElementById("underdogButton");
            if (underdogButton) {
              underdogButton.className = "";
            }
          } else if (requirement.statsUsed[0] == "underdog") {
            //console.log("Underdog stat required!");
            var favoriteButton = document.getElementById("favoriteButton");
            if (favoriteButton) {
              favoriteButton.className = "";
            }

            var underdogButton = document.getElementById("underdogButton");
            if (underdogButton) {
              underdogButton.className = "buttonSelected";
            }
          }
        }
      }
    });

    this.canEdit = this.requirementPickerService.canEditRequirement();
    //console.log("Can edit? ", this.canEdit);
  }

  selectFavorite() {
    var requirement = {
      "forOpponent": false,
      "description": "Team must be the favorite",
      "filterType": "STAT",
      "statsUsed": ["favorite"]
    }
    if (this.requirementPickerService.editingRequirement()) {
      this.modelPickerService.updateRequirement(requirement);
      var currentModel = this.modelPickerService.getModelToEdit();
      var modelId = currentModel.modelId;
      if (this.canEdit) {
        this.modelService.updateModel(this.currentUser, this.modelPickerService.getModelIdToEdit(), currentModel).subscribe(data => {
          if (data.error) {
            //console.log("Error updating requirement: ", data.error);
            window.alert(data.error);
          } else {
            //console.log("Success response updating model: ", data);
            var routeToGo = "/models/" + this.modelPickerService.getModelIdToEdit();
            this.route.navigate([routeToGo]);
          }
        })
        // var routeToGo = "/models/" + modelId;
        // this.route.navigate([routeToGo]);
      } else {
        //console.log("Cant edit the model in database, moving on!");
        this.route.navigate(["/model-picker"])
      }
    } else {
      //console.log("Not editing requirement -> creating");
      this.modelPickerService.addRequirement(requirement);
      this.route.navigate(["/model-picker"]);
    }
  }

  selectUnderdog() {
    var requirement = {
      "forOpponent": false,
      "description": "Team must be the underdog",
      "filterType": "STAT",
      "statsUsed": ["underdog"]
    }

    if (this.requirementPickerService.editingRequirement()) {
      this.modelPickerService.updateRequirement(requirement);
      var currentModel = this.modelPickerService.getModelToEdit();
      var modelId = currentModel.modelId;
      if (this.canEdit) {
        this.modelService.updateModel(this.currentUser, this.modelPickerService.getModelIdToEdit(), currentModel).subscribe(data => {
          if (data.error) {
            //console.log("Error updating requirement: ", data.error);
            window.alert(data.error);
          } else {
            //console.log("Success response updating model: ", data);
            var routeToGo = "/models/" + this.modelPickerService.getModelIdToEdit();
            this.route.navigate([routeToGo]);
          }
        })
        // var routeToGo = "/models/" + modelId;
        // this.route.navigate([routeToGo]);
      } else {
        //console.log("Cant edit the model in database, moving on!");
        this.route.navigate(["/model-picker"])
      }
    } else {
      //console.log("Not editing requirement -> creating");
      this.modelPickerService.addRequirement(requirement);
      this.route.navigate(["/model-picker"]);
    }

  }
}
