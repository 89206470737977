<div class="container">
    <div class="title">
        {{statToCompare.statName}}
    </div>
    <div class="myTeam">
        <button (click)="selectMyTeam()" id="myTeamButton">The team I am betting on</button>
    </div>
    <div class="otherTeam">
        <button (click)="selectOpposingTeam()" id="opposingTeamButton">The other team</button>
    </div>
    <div class="statDescription">
        {{statToCompare.statName}}
    </div>
    <div class="stringEqualsLabel" id='stringEqualsLabel'>
        <label for="stringEquals">Is Equal To:</label>
    </div>
    <!-- <div class="stringEqualsDiv" id="stringEqualsDiv">
        <input type="text" id="stringEquals" [(ngModel)]="stringEquals" (ngModelChange)="updateStringEquals()" placeholder="Ex: EASTERN"/>    
    </div> -->

    <div class="stringEqualsDiv">
        <select [(ngModel)]="stringEquals">
            <option *ngFor="let possibleValue of possibleValues;" [ngValue]="possibleValue">{{possibleValue}}</option>
        </select>
    </div>


    <div class="submit">
        <button (click)="saveRequirement()">Save Requirement</button>
    </div>
  </div>