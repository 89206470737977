import { Component, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  signstatus: string = 'signup'
  toVerifyEmail: boolean = false;
  userName: string;

  constructor(private route: Router, private userService: UserService) { }

  ngOnInit(): void {
  }

  onSignUp() {
    this.signstatus = 'signup';
  }

  onSignIn() {
    this.signstatus = 'signin';
  }

  signUpToAWS(username: HTMLInputElement, email: HTMLInputElement, firstName: HTMLInputElement, lastName: HTMLInputElement, phoneNumber: HTMLInputElement, birthday: HTMLInputElement, password: HTMLInputElement) {

    this.userName = username.value;

    //console.log("Submitted form: ",  username.value)
    //console.log("Submitted form: ",  email.value)
    //console.log("Submitted form: ",  firstName.value)
    //console.log("Submitted form: ",  lastName.value)
    //console.log("Submitted form: ",  phoneNumber.value)
    //console.log("Submitted form:  ", birthday.value)
    //console.log("Submitted form: ",  password.value)
    const user = {
      username: username.value,
      password: password.value,
      attributes: {
          email: email.value,
          "custom:phoneNumber": "+1" + phoneNumber.value,   // optional - E.164 number convention
          "custom:firstName": firstName.value,
          "custom:lastName": lastName.value,
          "custom:birthday": birthday.value
      }
    }

    Auth.signUp(user)
      .then(data => {
        //console.log(data);
        this.toVerifyEmail = true;

        this.route.navigate(['signin'])
      })
      .catch(err => console.log(err));

  }

}
