/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_qosfC6sDu",
    "aws_user_pools_web_client_id": "3t3enh5l1hucfjvdbhp6qthafl",
    "oauth": {
        "domain": "authorize.justpikit.com.auth.us-east-1.amazoncognito.com"
    }
};


export default awsmobile;
