import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { LeagueService } from 'src/app/services/league.service';
import { RequirementPickerService } from 'src/app/services/requirement-picker.service';
import { ModelService } from 'src/app/services/model.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-requirement-specific-teams-picker',
  templateUrl: './requirement-specific-teams-picker.component.html',
  styleUrls: ['./requirement-specific-teams-picker.component.css']
})
export class RequirementSpecificTeamsPickerComponent implements OnInit {

  leagueChosen;
  teamsAvailable;
  teamsChosen;
  canEdit;
  currentUser;
  constructor(private authenticationService: AuthenticationService, private modelService: ModelService, private requirementPickerService: RequirementPickerService, private route: Router, private leagueService: LeagueService, private modelPickerService: ModelPickerService) { }

  ngOnInit(): void {
    this.leagueChosen = this.modelPickerService.getModel().league;

    if (this.leagueChosen) {
      this.teamsAvailable = new Array();
      var leagueTeams = this.leagueService.getTeams(this.leagueChosen);
      //console.log("Teams available: ", this.teamsAvailable)
      Object.keys(leagueTeams).forEach (team => {
        var teamName = team;
        var logo = leagueTeams[team]['logo'];
        this.teamsAvailable.push({
          'teamName': teamName,
          'image': logo
        })
      })
      this.teamsChosen = new Array();
    } else {
      //console.log("League has not been chosen yet!");
    }

    this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    })
    this.canEdit = this.requirementPickerService.canEditRequirement();
  }

  ngAfterViewInit() {

    this.requirementPickerService.currentRequirement.subscribe(requirement => {

      //console.log("Requirement loaded in to home-away-picker: ", requirement);
      if (requirement.filterType && requirement.filterType == "SPECIFIC_TEAMS") {

        if (requirement.acceptedValues && requirement.acceptedValues.exactValues) {
          this.teamsChosen = requirement.acceptedValues.exactValues;
          //console.log("Teams chosen: ", this.teamsChosen);
          for (var i = 0; i < this.teamsChosen.length; i ++) {
            var team = this.teamsChosen[i];
            //console.log("team to find button for: ", team);
            var teamButton = document.getElementById(String(team));

            //console.log("idk: ", document.getElementById("Baltimore Ravens"));
            if (teamButton) {
              //console.log("found button");
              teamButton.className = "buttonSelected";
            } else {
              //console.log("No button found with this id: ", team);
            }
          }
        }
      }
    });
  }

  toggleTeam(team) {
    //console.log("idk 2: ", document.getElementById("Baltimore Ravens"))
    //console.log("Toggle team: ", team.teamName);
    var teamButton = document.getElementById(team.teamName);
    if (teamButton) {
      //console.log("Button exists: ", teamButton.className);
      if (!teamButton.className.includes("buttonSelected")) {
        //console.log("Adding button selected class");
        teamButton.className += " buttonSelected";
        //console.log("new class name: ", teamButton.className)
        //need to add this team to teams chosen list.
        this.teamsChosen.push(team.teamName);
      } else if (teamButton.className.includes("buttonSelected")) {
        teamButton.className.replace("buttonSelected", "");
        //need to remove team from teams chosen list
        var index = this.teamsChosen.indexOf(team.teamName);
        this.teamsChosen.splice(index, 1);
      }
    }
  }

  doneSelecting() {
    var requirement = {
      "forOpponent": false,
      "description": "Team must be one of these: " + this.teamsChosen,
      "filterType": "SPECIFIC_TEAMS",
      "acceptedExactValues": this.teamsChosen
    }

    //if we are editing requirement, we need to update it. otherwise add requirement.
    if (this.requirementPickerService.editingRequirement()) {
      this.modelPickerService.updateRequirement(requirement);
      var currentModel = this.modelPickerService.getModelToEdit();
      var modelId = currentModel.modelId;
      if (this.canEdit) {
        this.modelService.updateModel(this.currentUser, this.modelPickerService.getModelIdToEdit(), currentModel).subscribe(data => {
          if (data.error) {
            //console.log("Error updating requirement: ", data.error);
            window.alert(data.error);
          } else {
            var routeToGo = "/models/" + this.modelPickerService.getModelIdToEdit();
            this.route.navigate([routeToGo]);
          }
        })
      } else {
        this.route.navigate(["/model-picker"])
      }
    } else {
      this.modelPickerService.addRequirement(requirement);
      this.route.navigate(["/model-picker"]);
    }
  }
}
