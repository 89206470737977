<h1 mat-dialog-title style="text-align: center;">Game Details</h1>
<div mat-dialog-content>

    <div class="grid-container">
        <div class="gameDate">{{data.gameDate}}</div>
        <div class="awayName">{{data.awayTeamAbbr}}</div>
        <div class="awayMoneyLine">({{data.bettingStats.awayTeamMoneyLine}})</div>
        <div class="awayLogo">
            <img class="teamLogo" src="{{data.awayTeamLogo}}">
        </div>
        <div class="awayScore">{{data.awayTeamStats.Final}}</div>
        <div class="vs">vs.</div>
        <div class="homeScore">{{data.homeTeamStats.Final}}</div>
        <div class="homeLogo">
            <img class="teamLogo" src="{{data.homeTeamLogo}}">
        </div>
        <div class="homeMoneyLine">({{data.bettingStats.homeTeamMoneyLine}})</div>
        <div class="homeName">{{data.homeTeamAbbr}}</div>
        <div class="line">Line: {{data.bettingStats.favorite}} -{{data.bettingStats.favoriteSpread}}</div>
        <div class="total">O/U: {{data.bettingStats.overUnder}}</div>
        <div class="boxScore">
          <div class="q1Head">Q1</div>
          <div class="q2Head">Q2</div>
          <div class="q3Head">Q3</div>
          <div class="q4Head">Q4</div>
          <div class="totalHead">Total</div>
          <div class="awayLogoBox">
            <img class="teamLogoBox" src="{{data.awayTeamLogo}}">
          </div>
          <div class="homeLogoBox">
            <img class="teamLogoBox" src="{{data.homeTeamLogo}}">
          </div>
          <div class="awayNameBox">{{data.awayTeamAbbr}}</div>
          <div class="homeNameBox">{{data.homeTeamAbbr}}</div>
          <div class="awayQ1">{{data.awayTeamStats.Q1}}</div>
          <div class="awayQ2">{{data.awayTeamStats.Q2}}</div>
          <div class="awayQ3">{{data.awayTeamStats.Q3}}</div>
          <div class="awayQ4">{{data.awayTeamStats.Q4}}</div>
          <div class="homeQ1">{{data.homeTeamStats.Q1}}</div>
          <div class="homeQ2">{{data.homeTeamStats.Q2}}</div>
          <div class="homeQ3">{{data.homeTeamStats.Q3}}</div>
          <div class="homeQ4">{{data.homeTeamStats.Q4}}</div>
          <div class="awayTotal">{{data.awayTeamStats.Final}}</div>
          <div class="homeTotal">{{data.homeTeamStats.Final}}</div>
        </div>
      </div>

      <div class="extraFields" *ngIf="fields.length > 0">
          <h3 style="text-align: center; margin-bottom: 0;">Specific Stats</h3>
        <div class="headerContainer">
            <div class="awayLogoHeader">
                <img class="teamLogoHeader" src="{{data.awayTeamLogo}}">
            </div>
            <div class="homeLogoHeader">
                <img class="teamLogoHeader" src="{{data.homeTeamLogo}}">
            </div>
        </div>

        <div class='extraFields'>
            <ng-container *ngFor='let extraField of fields'>
                <app-extra-field [home]="extraField.home" [away]="extraField.away" [stat]="extraField.stat"></app-extra-field>
            </ng-container>
        </div>
</div>