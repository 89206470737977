<div *ngIf="upcomingGames">
    <div *ngFor="let game of upcomingGames" style="border-bottom: 1px solid lightgray;">
        <div id={{game.gameData.awayTeamAbbr}} (click)="showGameDetails(game)">
            <div class="container">
                <div class="betsToTakeHeader">Bets to Take</div>
                <div class="betToTake1">{{game.gameData.bet1}}</div>
                <div class="betToTake2" *ngIf="game.gameData.bet2">{{game.gameData.bet2}}</div>
                <div class="gameDate">{{game.gameData.date}}</div>
                <div class="awayTeamLogo">
                    <img class="teamLogo" src="{{game.gameData['awayTeamLogo']}}">
                </div>
                <div class="awayTeamName">{{game.gameData.awayTeamAbbr}}</div>
                <div class="vs">vs.</div>
                <div class="homeTeamLogo">
                    <img class="teamLogo" src="{{game.gameData['homeTeamLogo']}}">
                </div>
                <div class="homeTeamName">{{game.gameData.homeTeamAbbr}}</div>
            </div>
        </div>
    </div>
    <div *ngIf="upcomingGames.length == 0">
        <p>No upcoming games for this model.</p>
    </div>
</div>