import { Component, OnInit, Input } from '@angular/core';
import { GameService } from '../../services/game.service';
import { LeagueService } from '../../services/league.service';
import { MatDialog } from '@angular/material/dialog';
import { LiveGamesPopupComponent } from '../live-games-popup/live-games-popup.component';

@Component({
  selector: 'app-live-games-list',
  templateUrl: './live-games-list.component.html',
  styleUrls: ['./live-games-list.component.css']
})
export class LiveGamesListComponent implements OnInit {

  @Input() league;

  liveGames;
  nflTeams;
  nbaTeams;
  mlbTeams;
  constructor(public dialog: MatDialog, private leagueService: LeagueService, private gameService: GameService) {
    this.nflTeams = this.leagueService.getTeams("NFL");
    this.nbaTeams = this.leagueService.getTeams("NBA");
    this.mlbTeams = this.leagueService.getTeams("MLB");
   }

  ngOnInit(): void {
    console.log("Loading games for league: "+ this.league);
    this.gameService.getLiveGamesForLeague(this.league).subscribe(data => {
      console.log("Data from getting live games: ", data);
      //this.liveGames = data.liveGames;
      var games = data.liveGames;

      this.liveGames = [];
      for (var i = 0; i < games.length; i ++) {
        var game = games[i];
        var currentHomeScore = this.getCurrentScore(games[i]['homeTeamStats']);
        var currentAwayScore = this.getCurrentScore(games[i]['awayTeamStats']);

        var homeScores = game['homeTeamStats'];
        var awayScores = game['awayTeamStats'];

        var gameStats = game['gameStats'];
        var bettingStats = game['bettingStats'];

        var homeMoneyLine = Number(bettingStats.homeTeamMoneyLine);
        var awayMoneyLine = Number(bettingStats.awayTeamMoneyLine);
        //console.log("Home ml: " + homeMoneyLine);
        //console.log("Away ml: " + awayMoneyLine);
        if (homeMoneyLine < awayMoneyLine) {
          bettingStats.favorite = gameStats.homeTeam;
          bettingStats.favoriteSpread = bettingStats.homeTeamSpread;
        } else {
          bettingStats.favorite = gameStats.awayTeam;
          bettingStats.favoriteSpread = bettingStats.awayTeamSpread;
        }

        var gameData = {
          "homeTeam": gameStats['homeTeam'],
          "awayTeam": gameStats['awayTeam'],
          "homeTeamScore": currentHomeScore,
          "awayTeamScore": currentAwayScore,
          "homeLogo": this.getTeamLogo(gameStats['homeTeam']),
          "awayLogo": this.getTeamLogo(gameStats['awayTeam']),
          "gameStatus": game['rawStatus'],
          "homeTeamScores": this.getTeamScores(homeScores),
          "awayTeamScores": this.getTeamScores(awayScores),
          "bettingStats": bettingStats,
          "league": this.league
        }

        this.liveGames.push(gameData);
      }

    })
  }

  getTeamScores(teamScores) {
    var returnStats = {};
    if (teamScores['Q1']) {
      returnStats['Q1'] = teamScores['Q1'];
      returnStats['F'] = teamScores['Q1'];
    }

    if (teamScores['Q2']) {
      returnStats['Q2'] = teamScores['Q2'] - teamScores['Q1'];
      returnStats['F'] = teamScores['Q2'];
    }

    if (teamScores['Q3']) {
      returnStats['Q3'] = teamScores['Q3'] - teamScores['Q2'];
      returnStats['F'] = teamScores['Q3'];
    }

    if (teamScores['Q4']) {
      returnStats['Q4'] = teamScores['Q4'] - teamScores['Q3'];
      returnStats['F'] = teamScores['Q4'];
    }

    if (teamScores['I1']) {
      returnStats['I1'] = teamScores['I1'];
      returnStats['F'] = teamScores['I1'];
    }

    if (teamScores['I2']) {
      returnStats['I2'] = teamScores['I2'] - teamScores['I1'];
      returnStats['F'] = teamScores['I2'];
    }

    if (teamScores['I3']) {
      returnStats['I3'] = teamScores['I3'] - teamScores['I2'];
      returnStats['F'] = teamScores['I3'];
    }

    if (teamScores['I4']) {
      returnStats['I4'] = teamScores['I4'] - teamScores['I3'];
      returnStats['F'] = teamScores['I4'];
    }

    if (teamScores['I5']) {
      returnStats['I5'] = teamScores['I5'] - teamScores['I4'];
      returnStats['F'] = teamScores['I5'];
    }

    if (teamScores['I6']) {
      returnStats['I6'] = teamScores['I6'] - teamScores['I5'];
      returnStats['F'] = teamScores['I6'];
    }

    if (teamScores['I7']) {
      returnStats['I7'] = teamScores['I7'] - teamScores['I6'];
      returnStats['F'] = teamScores['I7'];
    }

    if (teamScores['I8']) {
      returnStats['I8'] = teamScores['I8'] - teamScores['I7'];
      returnStats['F'] = teamScores['I8'];
    }

    if (teamScores['I9']) {
      returnStats['I9'] = teamScores['I9'] - teamScores['I8'];
      returnStats['F'] = teamScores['I9'];
    }

    if (teamScores['F']) {
      returnStats['F'] = teamScores['F'];
      returnStats['F'] = teamScores['F'];
    }

    return returnStats;
  }

  getCurrentScore(teamStats) {
    if (teamStats['F']) {
      return teamStats['F'];
    }

    if (teamStats['Q4']) {
      return teamStats['Q4'];
    }

    if (teamStats['Q3']) {
      return teamStats['Q3'];
    }

    if (teamStats['Q2']) {
      return teamStats['Q2'];
    }

    if (teamStats['Q1']) {
      return teamStats['Q1'];
    }    

    if (teamStats['I9']) {
      return teamStats['I9'];
    }

    if (teamStats['I8']) {
      return teamStats['I8'];
    }

    if (teamStats['I7']) {
      return teamStats['I7'];
    }

    if (teamStats['I6']) {
      return teamStats['I6'];
    } 

    if (teamStats['I5']) {
      return teamStats['I5'];
    }

    if (teamStats['I4']) {
      return teamStats['I4'];
    }

    if (teamStats['I3']) {
      return teamStats['I3'];
    }

    if (teamStats['I2']) {
      return teamStats['I2'];
    } 

    if (teamStats['I1']) {
      return teamStats['I1'];
    } 

    return "0";
  }

  getTeamLogo(team) {
    if (this.league == "NFL") {
      return this.nflTeams[team]['logo'];
    } else if (this.league == "NBA") {
      return this.nbaTeams[team]['logo'];
    } else if (this.league == "MLB") {
      return this.mlbTeams[team]['logo'];
    }
  }

  showGameDetails(game) {
    console.log("Show game details: ", game);
    this.dialog.open(LiveGamesPopupComponent, {
      data: game
    });
  }


}
