<div class="container">

    <div class="row sign-in row-center" *ngIf="signstatus == 'signin'">
        <div class="col-sm-6">
                <div class="form-group">
                  <label for="exampleInputEmail1">Username</label>
                  <input type="text" class="form-control" id="exampleInputUsername1" placeholder="Enter username" #username>
                  <small id="emailHelp" class="form-text text-muted"></small>
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">Password</label>
                  <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" #password>
                </div>
                <button type="button" class="btn btn-primary" (click)="signInToAWS(username, password)">Sign In</button>
        </div>
    </div>
</div>