import { Component, OnInit, Input } from '@angular/core';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { Router } from '@angular/router';
import { RequirementPickerService } from 'src/app/services/requirement-picker.service';
import { ModelService } from 'src/app/services/model.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-requirement-home-away-picker',
  templateUrl: './requirement-home-away-picker.component.html',
  styleUrls: ['./requirement-home-away-picker.component.css']
})
export class RequirementHomeAwayPickerComponent implements OnInit {

  currentUser;
  canEdit;
  constructor(private authenticationService: AuthenticationService, private modelService: ModelService, private requirementPickerService: RequirementPickerService, private route: Router, private modelPickerService: ModelPickerService) { }

  ngOnInit(): void {

    this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    })

    this.requirementPickerService.currentRequirement.subscribe(requirement => {

      console.log("Requirement loaded in to home-away-picker: ", requirement);
      if (requirement.filterType && requirement.filterType == "TEAM_IDENTIFIER") {
        if (requirement.statsUsed) {
          if (requirement.statsUsed[0] == "homeTeam") {
            var homeButton = document.getElementById("homeButton");
            if (homeButton) {
              homeButton.className = "picker buttonSelected";
            }

            var awayButton = document.getElementById("awayButton");
            if (awayButton) {
              awayButton.className = "picker";
            }
          } else if (requirement.statsUsed[0] == "awayTeam") {
            var homeButton = document.getElementById("homeButton");
            if (homeButton) {
              homeButton.className = "picker";
            }

            var awayButton = document.getElementById("awayButton");
            if (awayButton) {
              awayButton.className = "picker buttonSelected";
            }
          }
        }
      }
    });

    this.canEdit = this.requirementPickerService.canEditRequirement();
  }

  addRequirement() {
    this.modelPickerService.addRequirement({"test": "hello"});
    this.route.navigate(["/model-picker"]);
  }

  selectHome() {
    var requirement = {
      "forOpponent": false,
        "description": "Team must be a home team",
        "filterType": "TEAM_IDENTIFIER",
        "statsUsed": ["homeTeam"]
    }

    if (this.requirementPickerService.editingRequirement()) {
      this.modelPickerService.updateRequirement(requirement);
      var currentModel = this.modelPickerService.getModelToEdit();
      var modelId = currentModel.modelId;
      if (this.canEdit) {
        this.modelService.updateModel(this.currentUser, this.modelPickerService.getModelIdToEdit(), currentModel).subscribe(data => {
          if (data.error) {
            //console.log("Error updating requirement: ", data.error);
            window.alert(data.error);
          } else {
            //console.log("Success response updating model: ", data);
            var routeToGo = "/models/" + this.modelPickerService.getModelIdToEdit();
            this.route.navigate([routeToGo]);
          }
        })
        // var routeToGo = "/models/" + modelId;
        // this.route.navigate([routeToGo]);
      } else {
        //console.log("Couldn't update the requirement in db. moving on.");
        this.route.navigate(["/model-picker"])
      }
    } else {
      //console.log("Not editing requirement -> creating");
      this.modelPickerService.addRequirement(requirement);
      this.route.navigate(["/model-picker"]);
    }
  }

  selectAway() {
    var requirement = {
      "forOpponent": false,
      "description": "Team must be an away team",
      "filterType": "TEAM_IDENTIFIER",
      "statsUsed": ["awayTeam"]
    }

    if (this.requirementPickerService.editingRequirement()) {
      this.modelPickerService.updateRequirement(requirement);
      var currentModel = this.modelPickerService.getModelToEdit();
      var modelId = currentModel.modelId;
      if (this.canEdit) {
        this.modelService.updateModel(this.currentUser, this.modelPickerService.getModelIdToEdit(), currentModel).subscribe(data => {
          if (data.error) {
            //console.log("Error updating requirement: ", data.error);
            window.alert(data.error);
          } else {
            //console.log("Success response updating model: ", data);
            var routeToGo = "/models/" + this.modelPickerService.getModelIdToEdit();
            this.route.navigate([routeToGo]);
          }
        })
        // var routeToGo = "/models/" + modelId;
        // this.route.navigate([routeToGo]);
      } else {
        //console.log("Couldn't update in database.");
        this.route.navigate(["/model-picker"])
      }
    } else {

      //console.log("Not editing requirement -> creating");
      this.modelPickerService.addRequirement(requirement);
      this.route.navigate(["/model-picker"]);
    }
  }

}
