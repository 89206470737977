<div class="grid-container">
    <div class="title">
        {{statToCompare.statDescription}}
    </div>
    <div class="myTeam">
        <button (click)="selectMyTeam()" id="myTeamButton">The team I am betting on</button>
    </div>
    <div class="otherTeam">
        <button (click)="selectOpposingTeam()" id="opposingTeamButton">The other team</button>
    </div>
    <div class="description">
        Has {{statToCompare.statDescription}}
    </div>
    <div class="comparison">
        <select [(ngModel)]="statComparisonOperation">
            <option value="GREATER_THAN">&gt;</option>
            <option value="LESS_THAN">&lt;</option>
            <option value="EQUAL_TO">=</option>
            <option value="GREATER_THAN_OR_EQUAL_TO">&gt;=</option>
            <option value="LESS_THAN_OR_EQUAL_TO">&lt;=</option>
        </select>
    </div>
    <div class="otherTeamDescription">
        <button (click)="toggleSecondTeam()" id="secondTeamButton">The Other Team's {{statToCompare.statDescription}}</button>
    </div>
    <div class="otherValueLabel">
        <label for="otherValue">Or enter other value:</label>
    </div>
    <div class="otherValue">
        <input type="text" id="otherStatValue" [(ngModel)]="otherStatValue" (ngModelChange)="updateOtherValue()" placeholder="Enter other value here.."/>
    </div>
    <div class="optionalValueLabel" id='optionalValueLabel'>
        <label for="optionalValue">By (optional):</label>
    </div>
    <div class="optionalValue">
        <div id="operationValueDiv">
            <input type="text" id="operationValue" [(ngModel)]="operationValue" (ngModelChange)="updateOperationValue()" placeholder="By..."/>    
        </div>
    </div>
    <div class="submit">
        <button 
        (click)="saveRequirement()">Save Requirement</button>
    </div>
  </div>