
<!-- <div *ngIf="userDetails">
    <strong>{{userDetails.firstName}} {{userDetails.lastName}}</strong>
    <p>@{{userId}}</p>
    <p>{{userDetails.bio}}</p>

    <div>
        <div *ngIf="usersThatFollowUser">
            <button (click)="viewFollowers()">{{usersThatFollowUser.length}} followers</button>
        </div>
    
        <div *ngIf="usersFollowing">
            <button (click)="viewFollowing()">{{usersFollowing.length}} following</button>
        </div>
    </div>

    <div *ngIf="!viewingOwnProfile">
        <div id="folloowOrUnfollowUser">
            <button *ngIf="userLoggedInFollowsUser" (click)="unfollowUser()">Unfollow</button>
            <button *ngIf="!userLoggedInFollowsUser" (click)="followUser()">Follow</button>
        </div>
    
        <div id="followsUserLoggedIn">
            <p *ngIf="userFollowsUserLoggedIn">Follows you!</p>
        </div>
    </div>
    
    <div *ngIf="viewingOwnProfile">
        <div id="editProfile">
            <button (click)="editProfile()">Edit</button>
        </div>
    </div>

    <h2>==========================================================</h2>

    <div *ngFor="let model of userDetails.modelsCreated">
        <app-model-overview [model]="model"></app-model-overview>
    </div>
</div> -->


<div *ngIf="userDetails">
    <mat-card class="example-card">
        <div class="mat-card-header-div">
            <div class="header-container">
                <div mat-card-avatar class="example-header-image avatar"></div>
                <div class="name">{{userDetails.firstName}} {{userDetails.lastName}}</div>
                <div class="username">@{{userId}}</div>
                <div class="follow">
                    <div class="edit" *ngIf="viewingOwnProfile">
                        <button mat-button (click)="editProfile()">
                            <mat-icon>edit</mat-icon>
                            Edit
                        </button>
                    </div>

                    <div class="followOrUnfollow" *ngIf="!viewingOwnProfile">
                        <div class="follow" *ngIf="!userLoggedInFollowsUser">
                            <button mat-button (click)="followUser()">Follow</button>
                        </div>
                        <div class="unfollow" *ngIf="userLoggedInFollowsUser">
                            <button mat-button (click)="unfollowUser()">Following</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-card-content>
            <div class="profile-container">
                <div class="bio">{{userDetails.bio}}</div>
                <div *ngIf="usersFollowing" class="following">
                    <button (click)="viewFollowing()">{{usersFollowing.length}} Following</button>
                </div>
                <div *ngIf="usersThatFollowUser" class="followers">
                    <button (click)="viewFollowers()">{{usersThatFollowUser.length}} Followers</button>
                </div>
            </div>

            <div id="tabGroup">
                <mat-tab-group #tabGroup animationDuration="0ms" mat-align-tabs="center">
                    <mat-tab label="Models Created">
                        <div style="height: 200px; overflow: scroll; border: 2px solid black;">
                            <ng-template matTabContent style='height: 200px; overflow: scroll;'>
                                <div *ngFor="let model of modelsCreated">
                                    <app-model-overview [model]="model" class="modelSubContent"></app-model-overview>
                                    <!-- <mat-divider class="divider"></mat-divider> -->
                                    
                                </div>
                            </ng-template>
                        </div>
                    </mat-tab>
                    <mat-tab label="Models Following">
                        <div style="height: 200px; overflow: scroll; border: 2px solid black;">
                            <ng-template matTabContent style='height: 200px; overflow: scroll;'>
                                <div *ngFor="let model of modelsSubscribed">
                                    <app-model-overview [model]="model" class="modelSubContent"></app-model-overview>
                                </div>
                            </ng-template>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>


        </mat-card-content>
    </mat-card>
</div>



