<div class="grid-container">
    <div class="over">
        <button class="betTaken" mat-raised-button (click)="toggleOver()" id='OVER' selected>Over</button>
    </div>
    <div class="under">
        <button class="betTaken" mat-raised-button (click)="toggleUnder()" id='UNDER'>Under</button>
    </div>
    <div class="spread" *ngIf="league != 'MLB'">
        <button class="betTaken" mat-raised-button (click)="toggleSpread()" id='SPREAD'>Spread</button>
    </div>
    <div class="moneyLine">
        <button class="betTaken" mat-raised-button (click)="toggleMoneyLine()" id='MONEY_LINE'>Money Line</button>
    </div>
  </div>