import { Component, OnInit } from '@angular/core';
import { UserListService } from 'src/app/services/user-list.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  text;
  userList;
  constructor(private userListService: UserListService) { }

  ngOnInit(): void {
    this.text = this.userListService.getText();
    this.userList = this.userListService.getUserList();
  }

}
