import { Component, OnInit, Input, ComponentFactoryResolver } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  allUsers;
  autoCompleteItems;
  searchText;
  currentUser;
  userSignedIn;
  constructor(private userService: UserService, private route: Router, private authenticationService: AuthenticationService) { 
    this.autoCompleteItems = new Array();
  }

  ngOnInit() {
    this.userService.getAllUsers().subscribe(users => {
      this.allUsers = users.users;
      //console.log("Loaded in all users: ", this.allUsers);
    })

    this.authenticationService.currentUser.subscribe(user => {
      if (user && user.signInUserSession) {
        this.currentUser = user;
        this.userSignedIn = true;
        //console.log("Current user in Nav Bar: ", user);        
      } else {
        this.userSignedIn = false;
        //console.log("User has logged out.");
      }
    })
  }

  logout() {
    this.authenticationService.logout();
    this.route.navigate(['/'])
  }

  viewProfile() {
    var self = this;
    this.route.navigate(["/"]).then(function() {
      var url = "/users/" + self.currentUser.username;
      self.route.navigate([url]).then(() => {console.log("Re-routing..."); self.route.navigate([url])})
    });
  }

  goToUserProfile(user) {
    var self = this;
    this.route.navigate(["/"]).then(function() {
      var url = "/users/" + user;
      self.route.navigate([url]).then(() => {console.log("Re-routing..."); self.route.navigate([url])})
    });
  }

  updateSearch() {
    //console.log("Search has been updated to : ", this.searchText);
    //set the array back to empty.
    this.autoCompleteItems = new Array();
    if (this.searchText != "") {
      for (var x = 0; x < this.allUsers.length; x ++) {
        var user = this.allUsers[x];
        if ((user.substr(0, this.searchText.length).toUpperCase()) == this.searchText.toUpperCase()) {
          //console.log("Found a match");
          this.autoCompleteItems.push(user);
        }
      }
    }
    /*execute a function when someone clicks in the document:*/
    var self = this;
    document.addEventListener("click", function (e) {
      //closeAllLists(e.target);
      self.searchText = "";
      self.autoCompleteItems = [];
    });

    //console.log("List of users: ", this.autoCompleteItems);
  }

}
