import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserListService {

  text;
  userList;
  constructor() { }

  setUserList(list) {
    this.userList = list;
  }

  getUserList() {
    return this.userList;
  }

  setText(text) {
    this.text = text;
  }

  getText() {
    return this.text;
  }
}
