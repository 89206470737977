<div class='modelPickerContainer'>
    <div class="title">Edit Model</div>
    <div class='modelPickerMetadataPicker'>
        <button mat-raised-button class='collapsible' (click)="openLeagueView()">
            <div class="collapsibleDiv">
                <p class="collapsibleText">Pick your league</p>
            </div>
            <mat-icon class='expandIcon'>expand_more</mat-icon>
            <mat-icon class="collapsibleIcon check" *ngIf="leagueSelected">check</mat-icon>
            <mat-icon class="collapsibleIcon cancel" *ngIf="!leagueSelected">cancel</mat-icon>
        </button>
        <div class="content" id="leagueSelector">
            <app-league-selector></app-league-selector>
        </div>

        <button mat-raised-button class='collapsible' (click)="openTimeRangeView()">
            <div class="collapsibleDiv">
                <p class="collapsibleText">Pick your time range</p>
            </div>
            <mat-icon class='expandIcon'>expand_more</mat-icon>
            <mat-icon class="collapsibleIcon check" *ngIf="timeRangeSelected">check</mat-icon>
            <mat-icon class="collapsibleIcon cancel" *ngIf="!timeRangeSelected">cancel</mat-icon>
        </button>
        <div class="content" id="timeRangeSelector">
            <app-time-range-selector></app-time-range-selector>
        </div>

        <button mat-raised-button class='collapsible' (click)="openBetsTakenView()">
            <div class="collapsibleDiv">
                <p class="collapsibleText">Pick your bets</p>
            </div>
            <mat-icon class='expandIcon'>expand_more</mat-icon>
            <mat-icon class="collapsibleIcon check" *ngIf="betsTakenSelected">check</mat-icon>
            <mat-icon class="collapsibleIcon cancel" *ngIf="!betsTakenSelected">cancel</mat-icon>
        </button>
        
        <div class="content" id="betsTakenSelector">
            <app-bets-taken-selector></app-bets-taken-selector>
        </div>

        <button mat-raised-button class='collapsible' (click)="openWagerView()">
            <div class="collapsibleDiv">
                <p class="collapsibleText">Pick your wager</p>
            </div>
            <mat-icon class='expandIcon'>expand_more</mat-icon>
            <mat-icon class="collapsibleIcon check" *ngIf="wagerSelected">check</mat-icon>
            <mat-icon class="collapsibleIcon cancel" *ngIf="!wagerSelected">cancel</mat-icon>
        </button>
        <div class="content" id="wagerSelector">
            <app-wager-selector></app-wager-selector>
        </div>

        <button mat-raised-button class='collapsible' (click)="openDescriptionView()">
            <div class="collapsibleDiv">
                <p class="collapsibleText">Describe your model</p>
            </div>
            <mat-icon class='expandIcon'>expand_more</mat-icon>
            <mat-icon class="collapsibleIcon check" *ngIf="descriptionSelected">check</mat-icon>
            <mat-icon class="collapsibleIcon cancel" *ngIf="!descriptionSelected">cancel</mat-icon>
        </button>
        <div class="content" id="descriptionSelector">
            <app-description-selector></app-description-selector>
        </div>
    </div>

    <div class="requirementPicker" id="requirementPicker">
        <mat-divider></mat-divider>
        <h3 style="font-weight: bolder;">Model Requirements</h3>
        <div *ngFor="let requirement of modelRequirements; let i = index;">
            <app-requirement-overview [canDelete]="false" [creatingModel]="creatingModel" [index]="i"></app-requirement-overview>
        </div>

        <button class="addIcon" mat-fab (click)="addRequirement()">
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <div style="display: inline-flex; margin-top: 3%;">
        <div id="cancelEdit" style="margin-right: 10px;">
            <button (click)="cancelEdit()">Cancel</button>
        </div>
    
        <div id="modelSubmit">
            <button (click)="submitModel()" id="submitModelButton">Create Model</button>
        </div>
    </div>
</div>
