<div class="grid-container">
    <div class="nfl">
        <button mat-raised-button class="leagueOption" (click)="selectNFL()" id='NFL'>
            <img src="assets/images/nflLogo.png">
        </button>
    </div>
    <div class="mlb">
        <button mat-raised-button class="leagueOption mlb" (click)="selectMLB()" id="MLB">
            <img src="assets/images/mlbLogo.png">
        </button>
    </div>
    <div class="nba">
        <button mat-raised-button class="leagueOption nba" (click)="selectNBA()" id="NBA">
            <img src="assets/images/nbaLogo.png">
        </button>
    </div>
    <!-- <div class="ncaam">
        <button mat-raised-button class="leagueOption" (click)="selectNCAAM()" id="NCAAM">
            <img src="assets/images/ncaamLogo.png">
        </button>
    </div> -->
</div>