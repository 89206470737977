import { Component, OnInit } from '@angular/core';
import { ModelPickerService } from 'src/app/services/model-picker.service';

@Component({
  selector: 'app-league-selector',
  templateUrl: './league-selector.component.html',
  styleUrls: ['./league-selector.component.css']
})
export class LeagueSelectorComponent implements OnInit {

  leagueSelected;
  constructor(private modelPickerService: ModelPickerService) { }

  ngOnInit(): void {

    this.modelPickerService.currentModel.subscribe(model => {
      //console.log("Model loaded into league selector: ", model);
      this.leagueSelected = model.league;
      var nflButton = document.getElementById("NFL");
      var mlbButton = document.getElementById("MLB");
      var nbaButton = document.getElementById("NBA");
      var ncaamButton = document.getElementById("NCAAM");

      if (nflButton) {
        nflButton.className = "leagueOption";
      }

      if (mlbButton) {
        mlbButton.className = "leagueOption";
      }

      if (nbaButton) {
        nbaButton.className = "leagueOption";
      }

      if (ncaamButton) {
        ncaamButton.className = "leagueOption";
      }

      if (this.leagueSelected) {
        //console.log("League is selected? " + this.leagueSelected);
        var buttonToActivate = document.getElementById(this.leagueSelected);
        if (buttonToActivate) {
          buttonToActivate.className = "leagueOption buttonSelected";
        }
      }
    })

  }

  selectNFL() {
    this.modelPickerService.setModelLeague("NFL");
  }

  selectMLB() {
    this.modelPickerService.setModelLeague("MLB");
  }

  selectNBA() {
    this.modelPickerService.setModelLeague("NBA");
  }

  selectNCAAM() {
    this.modelPickerService.setModelLeague("NCAAM");
  }

}
