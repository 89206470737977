import { Component, OnInit } from '@angular/core';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { RequirementPickerService } from 'src/app/services/requirement-picker.service';
import { Router } from '@angular/router';
import { ModelService } from 'src/app/services/model.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-requirement-stat-comparison-picker',
  templateUrl: './requirement-stat-comparison-picker.component.html',
  styleUrls: ['./requirement-stat-comparison-picker.component.css']
})
export class RequirementStatComparisonPickerComponent implements OnInit {
  statToCompare;
  statForOpponent;
  statComparisonOperation;
  secondTeamSelected;
  otherStatValue;
  operationValue;
  canEdit;
  currentUser;
  constructor(private modelService: ModelService, private authenticationService: AuthenticationService, private route: Router, private modelPickerService: ModelPickerService, private requirementPickerService: RequirementPickerService) { 
    this.secondTeamSelected = false;
    this.operationValue = 0;
  }

  ngOnInit(): void {
    this.statToCompare = this.requirementPickerService.getStatToCompare();
    this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    })
    this.canEdit = this.requirementPickerService.canEditRequirement();
    this.statComparisonOperation = "GREATER_THAN_OR_EQUAL_TO";

    var operationValueDiv = document.getElementById("operationValueDiv");
    //console.log("Operation value found: ", operationValueDiv)
    if (operationValueDiv) {
      operationValueDiv.style.display = "none";
    }

    var optionalValueLabel = document.getElementById("optionalValueLabel");
    //console.log("Optional value label found: ", optionalValueLabel)
    if (optionalValueLabel) {
      optionalValueLabel.style.display = "none";
    }
  }

  ngAfterContentInit() {
    this.requirementPickerService.currentRequirement.subscribe(requirement => {
      if (requirement.forOpponent != null) {
        var forOpponent = Boolean(requirement.forOpponent);
        if (forOpponent) {
          this.statForOpponent = true;
          var opposingTeamButton = document.getElementById("opposingTeamButton");
          if (opposingTeamButton) {
            opposingTeamButton.className = "buttonSelected";
          }

          var secondTeamButton = document.getElementById("secondTeamButton");
          if (secondTeamButton) {
            secondTeamButton.innerText = "My Team's " + this.statToCompare.statName;
          }
        } else {
          this.statForOpponent = false;
          var myTeamButton = document.getElementById("myTeamButton");
          var opposingTeamButton = document.getElementById("opposingTeamButton");
          if (myTeamButton) {
            myTeamButton.className = "buttonSelected";
          }

          if (opposingTeamButton) {
            opposingTeamButton.className = "";
          }

          this.statForOpponent = false;

          var secondTeamButton = document.getElementById("secondTeamButton");
          if (secondTeamButton) {
            secondTeamButton.innerText = "The Other Team's " + this.statToCompare.statName;
          }
        }
      }

      if (requirement.compare && requirement.compare.conditionRequirement) {
        var conditionRequirement = requirement.compare.conditionRequirement;
        this.statComparisonOperation = conditionRequirement;
      }

      if (requirement.statsUsed) {
        var secondStat = requirement.statsUsed;
        if (secondStat.length == 1) {
          var secondTeamButton = document.getElementById("secondTeamButton");
          if (secondTeamButton) {
            secondTeamButton.className = "buttonSelected";
          }
        } else {
          var secondValue = secondStat[1];
          if (secondValue) {
            //console.log("Second value found: disabling div")
            this.otherStatValue = secondValue;

            var operationValueDiv = document.getElementById("operationValueDiv");
            if (operationValueDiv) {
              operationValueDiv.style.display = "none";
            }

            var optionalValueLabel = document.getElementById("optionalValueLabel");

            //console.log("Operation value div: ", operationValueDiv)
            //console.log("optional value div: ", optionalValueLabel)
            if (optionalValueLabel) {
              optionalValueLabel.style.display = "none";
            }
          }
        }
      }

      if (requirement.conditionValue) {
        this.operationValue = requirement.conditionValue;
      }
    })
  }

  selectMyTeam() {
    var myTeamButton = document.getElementById("myTeamButton");
    var opposingTeamButton = document.getElementById("opposingTeamButton");
    if (myTeamButton) {
      myTeamButton.className = "buttonSelected";
    }

    if (opposingTeamButton) {
      opposingTeamButton.className = "";
    }

    this.statForOpponent = false;

    var secondTeamButton = document.getElementById("secondTeamButton");
    if (secondTeamButton) {
      secondTeamButton.innerText = "The Other Team's " + this.statToCompare.statName;
    }
  }

  selectOpposingTeam() {
    var myTeamButton = document.getElementById("myTeamButton");
    var opposingTeamButton = document.getElementById("opposingTeamButton");
    if (opposingTeamButton) {
      opposingTeamButton.className = "buttonSelected";
    }

    if (myTeamButton) {
      myTeamButton.className = "";
    }
    
    this.statForOpponent = true;

    var secondTeamButton = document.getElementById("secondTeamButton");
    if (secondTeamButton) {
      secondTeamButton.innerText = "My Team's " + this.statToCompare.statName;
    }
  }

  toggleSecondTeam() {
    var button = document.getElementById("secondTeamButton");
    if (button) {
      if (button.className == "buttonSelected") {
        button.className = "";
        this.secondTeamSelected = false;
      } else if (button.className == "") {
        button.className = "buttonSelected";
        this.secondTeamSelected = true;
        this.otherStatValue = "";
      }

      //console.log("Trying to make operation value div invisible...");
      var operationValueDiv = document.getElementById("operationValueDiv");
      if (operationValueDiv) {
        //console.log("here");
        operationValueDiv.style.display = "block";
      }

      var optionalValueLabel = document.getElementById("optionalValueLabel");
      if (optionalValueLabel) {
        optionalValueLabel.style.display = "block";
      }
    }
  }

  updateOtherValue(){
    var button = document.getElementById("secondTeamButton");
    if (button) {
      button.className = "";
    }

    this.secondTeamSelected = false;

    //console.log("Trying to make operation value div invisible...");
    var operationValueDiv = document.getElementById("operationValueDiv");
    if (operationValueDiv) {
      //console.log("here");
      operationValueDiv.style.display = "none";
    }

    var optionalValueLabel = document.getElementById("optionalValueLabel");
    if (optionalValueLabel) {
      optionalValueLabel.style.display = "none";
    }
  }

  updateOperationValue() {
    if (this.operationValue == "") {
      this.operationValue = "0";
    }
  }

  saveRequirement() {
    var statsUsed = new Array();
    statsUsed.push(this.statToCompare.statName);
    if (!this.secondTeamSelected) {
      statsUsed.push(this.otherStatValue);
    }

    
    var compare = {
      "operation": "subtract",
      "conditionRequirement": this.statComparisonOperation,
      "conditionValue": String(this.operationValue)
    }

    var description = "";
    if (this.statForOpponent) {
      description += "Other team's ";
    } else {
      description += "My team's ";
    }

    description += this.statToCompare.statName;

    switch (this.statComparisonOperation) {
      case "GREATER_THAN": description += " >";
      break;
      case "LESS_THAN": description += " <";
      break;
      case "GREATER_THAN_OR_EQUAL_TO": description += " >=";
      break;
      case "LESS_THAN_OR_EQUAL_TO": description += " <=";
      break;
      case "EQUAL_TO": description += " =";
      break;
    }

    if (this.secondTeamSelected) {
      if (this.statForOpponent) {
        description += " My team's " + this.statToCompare.statName;
      } else {
        description += " Other team's " + this.statToCompare.statName;
      }
    } else {
      description += " " + this.otherStatValue
    }

    if (Number(this.operationValue) > 0) {
      description += " by " + this.operationValue;
    }

    var requirement = {
      "description": description,
      "forOpponent": this.statForOpponent,
      "filterType": "STAT_COMPARISON",
      "statsUsed": statsUsed,
      "comparisonOperation": "SUBTRACT",
      "conditionRequirement": this.statComparisonOperation,
      "conditionValue": String(this.operationValue)
    }
    //console.log("Saving requirement: ", requirement);

    if (this.requirementPickerService.editingRequirement()) {
      this.modelPickerService.updateRequirement(requirement);
      var currentModel = this.modelPickerService.getModelToEdit();
      var modelId = currentModel.modelId;
      if (this.canEdit) {
        this.modelService.updateModel(this.currentUser, this.modelPickerService.getModelIdToEdit(), currentModel).subscribe(data => {
          if (data.error) {
            //console.log("Error updating requirement: ", data.error);
            window.alert(data.error);
          } else {
            var routeToGo = "/models/" + this.modelPickerService.getModelIdToEdit();
            this.route.navigate([routeToGo]);
          }
        })
      } else {
        this.route.navigate(["/model-picker"])
      }
    } else { 
      this.modelPickerService.addRequirement(requirement);
      this.route.navigate(["/model-picker"]);
    }
  }


}
