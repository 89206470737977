import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-model-detail-performance-circle-view',
  templateUrl: './model-detail-performance-circle-view.component.html',
  styleUrls: ['./model-detail-performance-circle-view.component.css']
})
export class ModelDetailPerformanceCircleViewComponent implements OnInit {

  @Input() value;
  @Input() units;
  @Input() name;
  @Input() color;
  radius = 54;
  circumference = 2 * Math.PI * this.radius;
  dashoffset: number;
  constructor() { 
    this.progress(0);
  }

  ngOnInit(): void {
    //console.log("Inside performance circle stuff.");
    //console.log("Value passed here: ", this.value);
    this.emulateProgress(this.value).subscribe(newValue => {
      this.progress(newValue);
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value.currentValue !== changes.value.previousValue) {
      this.progress(changes.value.currentValue);
    }
  }

  private progress(value: number) {
    ////console.log("Called progress function");
    const progress = value / 100;
    this.dashoffset = this.circumference * (1 - progress);
  }

  emulateProgress(cap) {
    return new Observable<number>(observer => {
      let val = 0;
      const interval = setInterval(() => {
        if (val < cap) {
          val++;
        } else {
          return;
        }

        observer.next(val);
      }, 20);

      return () => {
        clearInterval(interval);
      };
    });
  }
}
