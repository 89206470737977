import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { userInfo } from 'os';
import { BehaviorSubject, Observable } from 'rxjs';
import { Auth } from 'aws-amplify';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  private currentModelsCreatedSubject: BehaviorSubject<any>;
  private currentModelsSubscribedSubject: BehaviorSubject<any>;
  private currentModelsDeletedSubject: BehaviorSubject<any>;

  public currentModelsCreated: Observable<any>;
  public currentModelsSubscribed: Observable<any>;
  public currentModelsDeleted: Observable<any>;

  localModelsCreated;
  localModelsSubscribed;
  userLoggedIn;

  constructor(private authenticationService: AuthenticationService, private http: HttpClient) {

    this.currentModelsCreatedSubject = new BehaviorSubject<any>([]);
    this.currentModelsSubscribedSubject = new BehaviorSubject<any>([]);
    this.currentModelsDeletedSubject = new BehaviorSubject<any>({});

    this.authenticationService.currentUser.subscribe(user => {
      if (user && user.signInUserSession) {
        //console.log("User logged in to user service: ", user);
        this.userLoggedIn = user;
        this.getModelsCreatedByUser(this.userLoggedIn.username).subscribe(data => {
          //console.log("Got models created by user in user service: ", data);
          this.localModelsCreated = data.models;
          this.currentModelsCreatedSubject.next(this.localModelsCreated);
        }, error => {
          //console.log("Error getting models created in user service: ", error);
        })
  
        this.getModelsSubscribed(this.userLoggedIn.username).subscribe(data => {
          //console.log("Got models the user is subscribed to: ", data);
          this.localModelsSubscribed = data.models;
          this.currentModelsSubscribedSubject.next(this.localModelsSubscribed);
        }, error => {
          //console.log("Error getting models subscribed in user service: ", error);
        })
  
        this.currentModelsCreated = this.currentModelsCreatedSubject.asObservable();
        this.currentModelsSubscribed = this.currentModelsSubscribedSubject.asObservable();
        this.currentModelsDeleted = this.currentModelsDeletedSubject.asObservable();
      }
    })
   }

   public get currentUserValue(): any {
    //console.log("current user in service: " + this.currentUserSubject.value);
    return this.currentUserSubject.value;
  }

  public get currentModelsCreatedValue(): any {
    return this.currentModelsCreatedSubject.value;
  }

  public get currentModelsSubscribedValue(): any {
    return this.currentModelsSubscribedSubject.value;
  }

  public get currentModelsDeletedValue(): any {
    return this.currentModelsDeletedSubject.value;
  }



  createUser(user) {
    var headers = {
      "Authorization": user.signInUserSession.idToken.jwtToken
    }

    var body = {
      "firstName": user.attributes['custom:firstName'],
      "lastName": user.attributes['custom:lastName'],
      "bio": "",
      "birthday": "",
      "phoneNumber": user.attributes['custom:phoneNumber'],
      "email": user.attributes['email']
    }


    var userId = user.username;

    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/users/" + userId;

    return this.http.post<any>(url, body, {headers});
  }

  getUserInfo(userToGet) {

    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/users/" + userToGet + "/info";

    return this.http.get<any>(url, {});
  }

  getModelsSubscribed(userToGet) {

    var url = " https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/users/" + userToGet + "/modelssubscribed";

    return this.http.get<any>(url, {});
  }

  /*
    This method will retrive all of the users models that they have created. This will only contain 
    the high level data of that model to display to the user.
  */
  getModelsCreatedByUser(userToGet) {

    var url = " https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/users/" + userToGet + "/models";

    return this.http.get<any>(url, {});
  }

  addLocalModelCreatedByUser(model) {
    //console.log("Adding local model created by user.");
    this.localModelsCreated.push(model);
    //console.log("Current models created by user: ", this.localModelsCreated)
    this.currentModelsCreatedSubject.next(this.localModelsCreated);
  }

  deleteLocalModelCreatedByUser(model) {
    //console.log("Current models: " , this.localModelsCreated);
    //console.log("Trying to delete model: ",model);
    var index = this.localModelsCreated.indexOf(model);
    //console.log("Just told all of the listeners we deleted something at index: ", index);
    this.localModelsCreated.splice(index, 1);
    this.currentModelsCreatedSubject.next(this.localModelsCreated);
    this.currentModelsDeletedSubject.next(model);
  }


  setModelDetailsOnCreation(modelId, modelDetails) {
    //console.log("Setting model performance after run in user service: ", performance)
    for (var i = 0; i < this.localModelsCreated.length; i ++) {
      var model = this.localModelsCreated[i];
      if (model.modelId == modelId) {
        this.localModelsCreated[i] = modelDetails;
      }
    }
    this.currentModelsCreatedSubject.next(this.localModelsCreated);
  }



  addLocalModelSubscribedByUser(model) {
    this.localModelsSubscribed.push(model);
    this.currentModelsSubscribedSubject.next(this.localModelsSubscribed);
  }

  deleteLocalModelSubscribedByUser(model) {
    var index = this.localModelsSubscribed.indexOf(model);
    this.localModelsSubscribed.splice(index, 1);
    this.currentModelsSubscribedSubject.next(this.localModelsSubscribed);
  }

  subscribeToModel(userLoggedIn, modelToSubscribe) {
    var headers = {
      "Authorization": userLoggedIn.signInUserSession.idToken.jwtToken
    }

    var userId = userLoggedIn.username;

    var body = {
      "modelToSubscribe": modelToSubscribe
    }

    var url = " https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/users/" + userId + "/modelssubscribed";

    return this.http.post(url, body, {headers});
  }

  unsubscribeFromModel(userLoggedIn, modelToUnsubscribe) {
    var headers = {
      "Authorization": userLoggedIn.signInUserSession.idToken.jwtToken
    }

    var userId = userLoggedIn.username;

    var body = {
      "modelToUnsubscribe": modelToUnsubscribe
    }

    var url = " https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/users/" + userId + "/modelssubscribed";

    return this.http.post(url, body, {headers});
  }

  followUser(user, userIdToFollow) {
    var headers = {
      "Authorization": user.signInUserSession.idToken.jwtToken
    }

    var body = {
      "userToFollowId": userIdToFollow
    }

    var userId = user.username;

    var url = " https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/users/" + userId + "/followuser";

    return this.http.post<any>(url, body, {headers});
  }

  unfollowUser(user, userIdToUnfollow) {
    var headers = {
      "Authorization": user.signInUserSession.idToken.jwtToken
    }

    var body = {
      "userToUnfollowId": userIdToUnfollow
    }

    var userId = user.username;

    var url = " https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/users/" + userId + "/unfollowuser";

    return this.http.post<any>(url, body, {headers});
  }

  updateUserInfo(user, info) {
    var headers = {
      "Authorization": user.signInUserSession.idToken.jwtToken
    }

    var body = {
      "bio": info.bio,
      "firstName": info.firstName,
      "lastName": info.lastName,
      "email": info.email,
      "phoneNumber": info.phoneNumber,
      "birthday": info.birthday
    }

    var userId = user.username;

    var url = " https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/users/" + userId + "/info";

    return this.http.post<any>(url, body, {headers});
  }

  getAllUsers() {
    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/users";
    return this.http.get<any>(url, {});
  }
}
