<div>
  <app-nav-bar></app-nav-bar>
</div>
<div class="container">
  <router-outlet></router-outlet>
</div>

<script type="text/javascript" src="app/lib/apiGatewayCore/sigV4Client.js"></script>
    <script type="text/javascript" src="lib/apiGatewayCore/apiGatewayClient.js"></script>
    <script type="text/javascript" src="lib/apiGatewayCore/simpleHttpClient.js"></script>
    <script type="text/javascript" src="lib/apiGatewayCore/utils.js"></script>