import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-stat-standings-popup',
  templateUrl: './stat-standings-popup.component.html',
  styleUrls: ['./stat-standings-popup.component.css']
})
export class StatStandingsPopupComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {



  }

}
