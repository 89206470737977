import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-upcoming-games-popup',
  templateUrl: './upcoming-games-popup.component.html',
  styleUrls: ['./upcoming-games-popup.component.css']
})

export class UpcomingGamesPopupComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  fields;
  ngOnInit(): void {
    console.log("Data received: " , this.data);
    this.fields = [];
    var extraStats = this.data.gameData.extraStats;

    var homeTeamExtraStats = extraStats.homeTeamStats;
    var awayTeamExtraStats = extraStats.awayTeamStats;
    var gameExtraStats = extraStats.gameStats;
    var bettingExtraStats = extraStats.bettingStats;

    Object.keys(homeTeamExtraStats).forEach(extraStat => {
      if (extraStat != "Final") {
        var home = homeTeamExtraStats[extraStat];
        var away = awayTeamExtraStats[extraStat];
        var field = {
          'home': Math.round(100*home)/100,
          'away': Math.round(100*away)/100,
          'stat': extraStat
        }
        this.fields.push(field);
      }
    })
  }

}
