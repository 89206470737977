import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-extra-field',
  templateUrl: './extra-field.component.html',
  styleUrls: ['./extra-field.component.css']
})
export class ExtraFieldComponent implements OnInit {

  @Input() home: any;
  @Input() away: any;
  @Input() stat;
  constructor() { }

  ngOnInit(): void {
  }

}
