<div class="progress">
	<svg class="progress__svg" width="100%" height="100%" viewBox="0 0 120 120">
		<circle class="progress__meter" cx="60" cy="60" [attr.r]="radius" stroke-width="12" />
		<circle class="progress__value" [style.strokeDasharray]="circumference" [style.strokeDashoffset]="dashoffset" cx="60" cy="60"
         [attr.r]="radius" stroke-width="12" [style.stroke]="color"/>
         <text x="50%" y="-60%" text-anchor="middle" stroke-width="2px"transform="rotate(90)" class="progress__value-text">
             {{name}}
        </text>
        <text x="50%" y="-45%" text-anchor="middle" stroke-width="2px"transform="rotate(90)" class="progress__value-text">
            {{value}}% Win
       </text>
       <text x="50%" y="-30%" text-anchor="middle" stroke-width="2px"transform="rotate(90)" class="progress__value-text">
            {{units}} Units
        </text>
         
    </svg>
    <!-- <div class="progress__value-text">
        {{name}}
    </div>
  
    <div class="progress__value-text">
        {{value}} Win %
    </div>
  
    <div class="progress__value-text">
        {{units}} Units
    </div> -->
</div>