import { Component, OnInit, Input } from '@angular/core';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { Router } from '@angular/router';
import { RequirementPickerService } from 'src/app/services/requirement-picker.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ModelService } from 'src/app/services/model.service';

@Component({
  selector: 'app-requirement-overview',
  templateUrl: './requirement-overview.component.html',
  styleUrls: ['./requirement-overview.component.css']
})
export class RequirementOverviewComponent implements OnInit {

  //@Input() currentModel;
  @Input() index;
  @Input() canDelete;
  @Input() creatingModel;
  userLoggedIn;
  modelDetails;
  requirement;
  modelAlreadyCreated;
  constructor(private modelPickerService: ModelPickerService, private route: Router, private requirementPickerService: RequirementPickerService, private authenticationService: AuthenticationService, private modelService: ModelService) { }

  ngOnInit(): void {
    //console.log("CAN DELETE??: ", this.canDelete);
    this.authenticationService.currentUser.subscribe(user => {
      this.userLoggedIn = user;
    })

    this.modelPickerService.currentModel.subscribe(model => {
      console.log("Model loaded into Requirement overview component: ", model);
      this.modelDetails = model;
      console.log("model league: " + model['league']);
      if (this.modelDetails.modelConfiguration) {
        console.log("Had model configuration, so loading model config now.");
        this.modelDetails.modelRequirements = this.modelDetails.modelConfiguration.modelRequirements;
        this.requirement = this.modelDetails.modelConfiguration.modelRequirements[this.index];
      } else {
        if (this.modelDetails.modelRequirements) {
          console.log("Has model requirements: ", this.modelDetails.modelRequirements);
          this.requirement = this.modelDetails.modelRequirements[this.index];
        }
      }
      //console.log("Current model details: " , this.modelDetails);
      //console.log("Index given: ", this.index);
      this.modelAlreadyCreated = (this.modelDetails.userCreatedBy);
      //console.log("Requirement has been loaded into requirement overview component: '", this.requirement);
    })
  }

  toggleRequirement(requirement) {
    //console.log("Toggling requirement: ", requirement);
    var requirementButton = document.getElementById(requirement.description);
    if (requirementButton) {
      if (requirementButton.style.display == "block") {
        requirementButton.style.display = "none";
      } else {
        requirementButton.style.display = "block";
      }
    }
  }

  editRequirement() {
    console.log("Edit requirement model: ", this.modelDetails);
    var requirement = this.modelDetails.modelRequirements[this.index];
    this.modelPickerService.setModelToEdit(this.modelDetails, false);
    this.modelPickerService.setModelIdToEdit(this.modelPickerService.getModelIdToEdit());
    this.modelPickerService.setRequirementIndexToEdit(this.index);
    this.requirementPickerService.setRequirementToEdit(requirement, false);

    //console.log("Inside edit requirement button, can delete? ", this.canDelete);
    if (this.canDelete) {
      this.requirementPickerService.setCanEdit(true);
    } else {
      this.requirementPickerService.setCanEdit(false);
    }
    this.route.navigate(["/requirement-picker"]);
  }

  deleteRequirement() {
    console.log("User attempting to delete requirement from model.");
    this.modelPickerService.removeRequirement(this.requirement);
    if (this.canDelete && this.modelDetails.userCreatedBy) {
      if (this.userLoggedIn.username == this.modelDetails.userCreatedBy) {
        //console.log("User is authenticated.");
        var modelId = this.modelDetails.modelId;
        //since we are editing a model, that means it already exists in the database. We should update that here.
        console.log("Going to delete requirement from model: " + this.modelPickerService.getModelIdToEdit());
        console.log("Sending this as the updated model: ", this.modelDetails.modelConfiguration);
        this.modelService.updateModel(this.userLoggedIn, this.modelPickerService.getModelIdToEdit(), this.modelDetails).subscribe(data => {
          //console.log("Success?: ", data);
          if (data.error) {
            window.alert(data.error);
          }
        }, error => {
          //console.log("Error deleting requirement: ", error);
          window.alert(error);
        })
      }
    } else {
      //console.log("No need to authenticate user before deleting, The model doesn't exist yet.");
    }
  }

}
