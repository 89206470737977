<!-- <div id="teamsList">
    <div *ngFor="let team of teamsAvailable">
        <button (click)="toggleTeam(team)" id={{team.teamName}}>
            <app-requirement-category-picker title={{team.teamName}} image={{team.image}}></app-requirement-category-picker>
        </button>
    </div>

    <button (click)="doneSelecting()">Done</button>
</div> -->

<div class='title'>Select Your Teams</div>
<div class="teamsList" id="teamsList">
    <div class="list">
        <mat-grid-list cols="2">
            <mat-grid-tile *ngFor="let team of teamsAvailable">
                <button mat-raised-button (click)="toggleTeam(team)" class='tile' id={{team.teamName}}>
                    <app-requirement-category-picker title={{team.teamName}} image={{team.image}}></app-requirement-category-picker>
                </button>
            </mat-grid-tile>
        </mat-grid-list>    
    </div>
    <button class='doneButton' (click)="doneSelecting()">Done</button>
</div>