import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequirementPickerService {

  private currentRequirementSubject: BehaviorSubject<any>;
  public currentRequirement: Observable<any>;

  statToCompare;
  requirementToEdit;
  newRequirement;
  canEdit;
  constructor() { 
    this.currentRequirementSubject = new BehaviorSubject<any>({});
    this.currentRequirement = this.currentRequirementSubject.asObservable();
  }

  getRequirement() {
    return this.currentRequirementSubject.value;
  }

  editingRequirement() {
    return (this.newRequirement == false);
  }

  getRequirementToEdit() {
    return this.requirementToEdit;
  }

  setRequirementToEdit(requirementToEdit, newRequirement) {
    //console.log("Setting requirement to edit");
    this.requirementToEdit = requirementToEdit;
    this.newRequirement = newRequirement;
    this.currentRequirementSubject.next(requirementToEdit);
  }

  setStatToCompare(stat) {
    //console.log("Choosing stat to compare: ", stat.statName);
    this.statToCompare = stat;
  }

  /*
    This function allows both the model creation phase and the edit model phase to be able to decide whether
    the updates a user makes to a requirement will then be reflected immediately in the database, or if the user 
    will have to confirm the update (example: user clicks on model, then updates model requirement, this should be reflected
    in the database immediately. Compared to a user creating a model, and then during the creation phase, the user changes one
    of the requirements.)
  */
  setCanEdit(canEdit) {
    this.canEdit = canEdit;
  }

  canEditRequirement() {
    return this.canEdit;
  }

  getStatToCompare() {
    return this.statToCompare;
  }
}
