import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LeagueService {

  leagueMetadata;
  constructor(private http: HttpClient) { 
    this.leagueMetadata = {
      "NFL": {
        "firstSeasonAvailable": "2007",
        "lastSeasonAvailable": "2021",
        "teams": {
          "ARI": {
            "logo": "../assets/images/NFLImages/ari.svg",
            "abbr": "ARI"
          },
          "ATL": {
            "logo": "../assets/images/NFLImages/atl.svg",
            "abbr": "ATL"
          },
          "BAL": {
            "logo": "../assets/images/NFLImages/bal.svg",
            "abbr": "BAL"
          },
          "BUF": {
            "logo": "../assets/images/NFLImages/buf.svg",
            "abbr": "BUF"
          },
          "CAR": {
            "logo": "../assets/images/NFLImages/car.svg",
            "abbr": "CAR"
          },
          "CHI": {
            "logo": "../assets/images/NFLImages/chi.svg",
            "abbr": "CHI"
          },
          "CIN": {
            "logo": "../assets/images/NFLImages/cin.svg",
            "abbr": "CIN"
          },
          "CLE": {
            "logo": "../assets/images/NFLImages/cle.svg",
            "abbr": "CLE"
          },
          "DAL": {
            "logo": "../assets/images/NFLImages/dal.svg",
            "abbr": "DAL"
          },
          "DEN": {
            "logo": "../assets/images/NFLImages/den.svg",
            "abbr": "DEN"
          },
          "DET": {
            "logo": "../assets/images/NFLImages/det.svg",
            "abbr": "DET"
          },
          "GB": {
            "logo": "../assets/images/NFLImages/gb.svg",
            "abbr": "GB"
          },
          "HOU": {
            "logo": "../assets/images/NFLImages/hou.svg",
            "abbr": "HOU"
          },
          "IND": {
            "logo": "../assets/images/NFLImages/ind.svg",
            "abbr": "IND"
          },
          "JAX": {
            "logo": "../assets/images/NFLImages/jax.svg",
            "abbr": "JAX"
          },
          "KC": {
            "logo": "../assets/images/NFLImages/kc.svg",
            "abbr": "KC"
          },
          "LV": {
            "logo": "../assets/images/NFLImages/lv.svg",
            "abbr": "LV"
          },
          "LAC": {
            "logo": "../assets/images/NFLImages/lac.svg",
            "abbr": "LAC"
          },
          "LAR": {
            "logo": "../assets/images/NFLImages/lar.svg",
            "abbr": "LAR"
          },
          "MIA": {
            "logo": "../assets/images/NFLImages/mia.svg",
            "abbr": "MIA"
          },
          "MIN": {
            "logo": "../assets/images/NFLImages/min.svg",
            "abbr": "MIN"
          },
          "NE": {
            "logo": "../assets/images/NFLImages/ne.svg",
            "abbr": "NE"
          },
          "NO": {
            "logo": "../assets/images/NFLImages/no.svg",
            "abbr": "NO"
          },
          "NYG": {
            "logo": "../assets/images/NFLImages/nyg.svg",
            "abbr": "NYG"
          },
          "NYJ": {
            "logo": "../assets/images/NFLImages/nyj.svg",
            "abbr": "NYJ"
          },
          "OAK": {
            "logo": "../assets/images/NFLImages/lv.svg",
            "abbr": "LV"
          },
          "PIT": {
            "logo": "../assets/images/NFLImages/pit.svg",
            "abbr": "PIT"
          },
          "PHI": {
            "logo": "../assets/images/NFLImages/phi.svg",
            "abbr": "PHI"
          },
          "SD": {
            "logo": "../assets/images/NFLImages/lac.svg",
            "abbr": "SD"
          },
          "SF": {
            "logo": "../assets/images/NFLImages/sf.svg",
            "abbr": "SF"
          },
          "SEA": {
            "logo": "../assets/images/NFLImages/sea.svg",
            "abbr": "SEA"
          },
          "STL": {
            "logo": "../assets/images/NFLImages/lar.svg",
            "abbr": "STL"
          },
          "TB": {
            "logo": "../assets/images/NFLImages/tb.svg",
            "abbr": "TB"
          },
          "TEN": {
            "logo": "../assets/images/NFLImages/ten.svg",
            "abbr": "TEN"
          },
          "WAS": {
            "logo": "../assets/images/NFLImages/was.svg",
            "abbr": "WAS"
          },
          "Washingtom": {
            "logo": "../assets/images/NFLImages/was.svg",
            "abbr": "WAS"
          }
        }
      },
      "MLB": {
        "firstSeasonAvailable": "2010",
        "lastSeasonAvailable": "2022",
        "teams": {
          "ARI": {
            "logo": "../assets/images/MLBImages/ari.svg",
            "abbr": "ARI"
          },
          "ATL": {
            "logo": "../assets/images/MLBImages/atl.svg",
            "abbr": "ATL"
          },
          "BAL": {
            "logo": "../assets/images/MLBImages/bal.svg",
            "abbr": "BAL"
          },
          "BOS": {
            "logo": "../assets/images/MLBImages/bos.svg",
            "abbr": "CHI"
          },
          "CIN": {
            "logo": "../assets/images/MLBImages/cin.svg",
            "abbr": "CIN"
          },
          "CLE": {
            "logo": "../assets/images/MLBImages/cle.svg",
            "abbr": "CLE"
          },
          "COL": {
            "logo": "../assets/images/MLBImages/col.svg",
            "abbr": "COL"
          },
          "CHW": {
            "logo": "../assets/images/MLBImages/chw.svg",
            "abbr": "CHW"
          },
          "CHC": {
            "logo": "../assets/images/MLBImages/chc.svg",
            "abbr": "CHC"
          },
          "DET": {
            "logo": "../assets/images/MLBImages/det.svg",
            "abbr": "DET"
          },
          "HOU": {
            "logo": "../assets/images/MLBImages/hou.svg",
            "abbr": "HOU"
          },
          "KC": {
            "logo": "../assets/images/MLBImages/kc.svg",
            "abbr": "KC"
          },
          "LAA": {
            "logo": "../assets/images/MLBImages/laa.svg",
            "abbr": "LAA"
          },
          "LAD": {
            "logo": "../assets/images/MLBImages/lad.svg",
            "abbr": "LAD"
          },
          "MIA": {
            "logo": "../assets/images/MLBImages/mia.svg",
            "abbr": "MIA"
          },
          "MIL": {
            "logo": "../assets/images/MLBImages/mil.svg",
            "abbr": "MIL"
          },
          "MIN": {
            "logo": "../assets/images/MLBImages/min.svg",
            "abbr": "MIN"
          },
          "NYM": {
            "logo": "../assets/images/MLBImages/nym.svg",
            "abbr": "NYM"
          },
          "NYY": {
            "logo": "../assets/images/MLBImages/nyy.svg",
            "abbr": "NYY"
          },
          "OAK": {
            "logo": "../assets/images/MLBImages/oak.svg",
            "abbr": "OAK"
          },
          "PHI": {
            "logo": "../assets/images/MLBImages/phi.svg",
            "abbr": "PHI"
          },
          "PIT": {
            "logo": "../assets/images/MLBImages/pit.svg",
            "abbr": "PIT"
          },
          "SD": {
            "logo": "../assets/images/MLBImages/sd.svg",
            "abbr": "SD"
          },
          "SEA": {
            "logo": "../assets/images/MLBImages/sea.svg",
            "abbr": "SEA"
          },
          "SF": {
            "logo": "../assets/images/MLBImages/sf.svg",
            "abbr": "SF"
          },
          "STL": {
            "logo": "../assets/images/MLBImages/stl.svg",
            "abbr": "STL"
          },
          "TB": {
            "logo": "../assets/images/MLBImages/tb.svg",
            "abbr": "TB"
          },
          "TEX": {
            "logo": "../assets/images/MLBImages/tex.svg",
            "abbr": "TEX"
          },
          "TOR": {
            "logo": "../assets/images/MLBImages/tor.svg",
            "abbr": ""
          },
          "WSH": {
            "logo": "../assets/images/MLBImages/wsh.svg",
            "abbr": "WSH"
          }
        }
      },
      "NBA": {
        "firstSeasonAvailable": "2010",
        "lastSeasonAvailable": "2021",
        "teams": {
          "ATL": {
            "logo": "../assets/images/NBAImages/atl.svg",
            "abbr": "ATL"
          },
          "BOS": {
            "logo": "../assets/images/NBAImages/bos.svg",
            "abbr": "BOS"
          },
          "BKN": {
            "logo": "../assets/images/NBAImages/bkn.svg",
            "abbr": "BKN"
          },
          "CHA": {
            "logo": "../assets/images/NBAImages/cha.svg",
            "abbr": "CHA"
          },
          "CHI": {
            "logo": "../assets/images/NBAImages/chi.svg",
            "abbr": "CHI"
          },
          "CLE": {
            "logo": "../assets/images/NBAImages/cle.svg",
            "abbr": "CLE"
          },
          "DAL": {
            "logo": "../assets/images/NBAImages/dal.svg",
            "abbr": "DAL"
          },
          "DEN": {
            "logo": "../assets/images/NBAImages/den.svg",
            "abbr": "DEN"
          },
          "DET": {
            "logo": "../assets/images/NBAImages/det.svg",
            "abbr": "DET"
          },
          "HOU": {
            "logo": "../assets/images/NBAImages/hou.svg",
            "abbr": "HOU"
          },
          "IND": {
            "logo": "../assets/images/NBAImages/ind.svg",
            "abbr": "IND"
          },
          "LAC": {
            "logo": "../assets/images/NBAImages/lac.svg",
            "abbr": "LAC"
          },
          "LAL": {
            "logo": "../assets/images/NBAImages/lal.svg",
            "abbr": "LAL"
          },
          "MEM": {
            "logo": "../assets/images/NBAImages/mem.svg",
            "abbr": "MEM"
          },
          "MIA": {
            "logo": "../assets/images/NBAImages/mia.svg",
            "abbr": "MIA"
          },
          "MIL": {
            "logo": "../assets/images/NBAImages/mil.svg",
            "abbr": "MIL"
          },
          "OKC": {
            "logo": "../assets/images/NBAImages/okc.svg",
            "abbr": "OKC"
          },
          "NO": {
            "logo": "../assets/images/NBAImages/no.svg",
            "abbr": "NO"
          },
          "PHO": {
            "logo": "../assets/images/NBAImages/pho.svg",
            "abbr": "PHO"
          },
          "POR": {
            "logo": "../assets/images/NBAImages/por.svg",
            "abbr": "POR"
          },
          "SAC": {
            "logo": "../assets/images/NBAImages/sac.svg",
            "abbr": "SAC"
          },
          "SA": {
            "logo": "../assets/images/NBAImages/sa.svg",
            "abbr": "SA"
          },
          "MIN": {
            "logo": "../assets/images/NBAImages/min.svg",
            "abbr": "MIN"
          },
          "TOR": {
            "logo": "../assets/images/NBAImages/tor.svg",
            "abbr": "TOR"
          },
          "UTA": {
            "logo": "../assets/images/NBAImages/uta.svg",
            "abbr": "UTA"
          },
          "GS": {
            "logo": "../assets/images/NBAImages/gs.svg",
            "abbr": "GS"
          },
          "WAS": {
            "logo": "../assets/images/NBAImages/was.svg",
            "abbr": "WAS"
          },
          "NY": {
            "logo": "../assets/images/NBAImages/ny.svg",
            "abbr": "NY"
          },
          "ORL": {
            "logo": "../assets/images/NBAImages/orl.svg",
            "abbr": "ORL"
          },
          "PHI": {
            "logo": "../assets/images/NBAImages/phi.svg",
            "abbr": "PHI"
          }
        }
      },
      "NHL": {
        "firstSeasonAvailable": "2007",
        "lastSeasonAvailable": "2020"
      }
    }
  }



  getFirstSeasonAvailable(league) {
    return this.leagueMetadata[league]["firstSeasonAvailable"];
  }

  getLastSeasonAvailable(league) {
    return this.leagueMetadata[league]["lastSeasonAvailable"];
  }

  getTeams(league) {
    return this.leagueMetadata[league]["teams"];
  }

  getStats(league) {
    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/stats/" + league;

    return this.http.get<any>(url, {});
  }
}
