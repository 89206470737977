import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModelPickerService {

  private currentModelSubject: BehaviorSubject<any>;
  public currentModel: Observable<any>;
  
  modelToEdit;
  newModel; //field so that we can determine if the user is editing a model or creating a new one.
  requirementIndexToEdit;

  modelPerformance;
  gamesThatMeetModel = {};
  gamesThatMeetModelAfterRun = {};
  gamesStored;
  top3Games;
  betsTaken;

  modelIdToEdit;
  userCreatedBy;

  justRanModel = false;

  constructor() { 
    this.currentModelSubject = new BehaviorSubject<any>({});
    this.currentModel = this.currentModelSubject.asObservable();
  }

  getModel() {
    return this.currentModelSubject.value;
  }

  setModelToEdit(modelToEdit, newModel) {
    console.log("Setting model to edit( In picker service: ", modelToEdit);
    if (modelToEdit.modelConfiguration && !('modelPerformance' in modelToEdit)) {
      console.log("In here....");
      this.modelToEdit = modelToEdit.modelConfiguration;
      this.modelToEdit.userCreatedBy = modelToEdit.userCreatedBy;
    } else if (modelToEdit.modelConfiguration) {
      this.modelToEdit = modelToEdit;
      this.modelToEdit.betsTaken = modelToEdit.modelConfiguration.betsTaken;
    } else {
      this.modelToEdit = modelToEdit;
    }

    this.top3Games = modelToEdit.top3Games;
    this.gamesStored = modelToEdit.gamesStored;
    this.gamesThatMeetModel = modelToEdit.gamesThatMeetModel;
    this.modelPerformance = modelToEdit.modelPerformance;
    
    this.newModel = newModel;
    this.currentModelSubject.next(modelToEdit);
  }

  getModelIdToEdit() {
    console.log("Getting model id to edit: " + this.modelToEdit);
    return this.modelIdToEdit;
  }

  setModelIdToEdit(modelId) {
    console.log("Setting modelid to edit: " + modelId);
    this.modelIdToEdit = modelId;
  }

  // setUserCreatedBy(user) {
  //   console.log("Setting userCreatedBy: " + user);
  //   this.userCreatedBy = user;
  // }

  // getUserCreatedBy() {
  //   return this.userCreatedBy;
  // }

  editingModel() {
    return (this.newModel == false);
  }

  getModelToEdit() {
    return this.modelToEdit;
  }


  setModelLeague(league) {
    this.modelToEdit.league = league;
    this.currentModelSubject.next(this.modelToEdit);
  }

  getModelLeague() {
    return this.modelToEdit.league;
  }

  setModelStartRagne(startRange) {
    this.modelToEdit.modelStartRange = startRange;
    this.currentModelSubject.next(this.modelToEdit);
  }
  
  setModelEndRange(endRange) {
    this.modelToEdit.modelEndRange = endRange;
    this.currentModelSubject.next(this.modelToEdit);
  }

  setModelTimeDescription(timeDescription) {
    this.modelToEdit.modelTimeRangeDescription = timeDescription;
    this.currentModelSubject.next(this.modelToEdit);
  }

  // getModelTimeRange() {
  //   return this.modelToEdit.timeRange;
  // }

  setModelBetsTaken(betsTaken) {
    this.modelToEdit.betsTaken = betsTaken;
    this.currentModelSubject.next(this.modelToEdit);
  }

  // getModelBetsTaken() {
  //   return this.modelToEdit.betsTaken;
  // }

  setModelWager(wager) {
    //console.log("Setting model wager to :", wager);
    this.modelToEdit.betType = wager;
    this.currentModelSubject.next(this.modelToEdit);
  }

  // getModelWager() {
  //   return this.modelToEdit.wager;
  // }

  setModelDescription(description) {
    this.modelToEdit.modelDescription = description;
    this.currentModelSubject.next(this.modelToEdit);
  }

  setRequirementIndexToEdit(index) {
    this.requirementIndexToEdit = index;
  }

  updateRequirement(requirement) {
    //console.log("Updating requirement?: ", requirement);
    this.modelToEdit.modelRequirements[this.requirementIndexToEdit] = requirement;
  }

  addRequirement(requirement) {
    if (this.modelToEdit.modelRequirements) {
      //first check to make sure this requirement doesn't already exist.

      if (this.modelToEdit.modelRequirements.indexOf(requirement) == -1) {

        //now we should make sure that this requirement doesn't interfere with other requirements.
        /*
          If they choose home team, and now they say away team, we should modify this to just say home team.
        */

        this.modelToEdit.modelRequirements.push(requirement);
      } else {
        //console.log("This requirement already exists!!");
      }
    } else {
      this.modelToEdit.modelRequirements = new Array();
      this.modelToEdit.modelRequirements.push(requirement);
    }
    this.currentModelSubject.next(this.modelToEdit);
  }

  removeRequirement(requirement) {
    if (this.modelToEdit.modelRequirements) {
      var index = this.modelToEdit.modelRequirements.indexOf(requirement);
      if (index != -1) {
        this.modelToEdit.modelRequirements.splice(index, 1);
        this.currentModelSubject.next(this.modelToEdit);
      }
    }
  }



  setModelPerformance(modelPerformance) {
    this.modelToEdit.modelPerformance = modelPerformance;
    this.currentModelSubject.next(this.modelToEdit);

  }

  setGamesThatMeetModelAfterRun(games) {
    this.justRanModel = true;
    console.log("Games sent to setGamesThatMeetModelAfterRun: ", games);
    this.gamesThatMeetModelAfterRun = games;
    // for (var x = 0; x < games.length; x ++) {
    //   var season = games[x];
    //   var year = season['year'];
    //   var gamesInSeason = season['games'];
    //   this.gamesThatMeetModelAfterRun[year] = gamesInSeason;
    // }
  }

  didJustRunModel() {
    return this.justRanModel;
  }

  setGamesThatMeetModel(year, games) {
    this.gamesThatMeetModel[year] = games;
  }

  setGamesStored(gamesStored) {
    this.gamesStored = gamesStored;
    this.currentModelSubject.next(this.modelToEdit);
  }

  setTop3Games(top3Games) {
    this.top3Games = top3Games;
    this.currentModelSubject.next(this.modelToEdit);
  }

  getModelPerformance() {
    return this.modelToEdit.modelPerformance;
  }

  getGamesThatMeetModelAfterRun() {
    //console.log("Calling getGamesThatMeetModel after run: ", this.gamesThatMeetModelAfterRun)
    this.justRanModel = false;
    return this.gamesThatMeetModelAfterRun;
  }

  getGamesThatMeetModel(year) {
    return this.gamesThatMeetModel[year];
  }

  getGamesStored() {
    return this.gamesStored;
  }

  getTop3Games() {
    return this.top3Games;
  }

  // getModelDescription() {
  //   return this.modelToEdit.description;
  // }
}
