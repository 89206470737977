import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';
import { UserListService } from 'src/app/services/user-list.service';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { ModelService } from 'src/app/services/model.service';
import { LeagueService } from 'src/app/services/league.service';

@Component({
  selector: 'app-model-overview',
  templateUrl: './model-overview.component.html',
  styleUrls: ['./model-overview.component.css']
})
export class ModelOverviewComponent implements OnInit {

  @Input() model;
  modelPerformance;
  top3Games;
  currentUser;

  ddbModelPerformance;

  modelConfiguration;
  modelSubscriberCountText;
  modelSubscribers;
  userOwnsModel;
  modelDescription;
  userFollowsModel;
  bet1;
  bet2;
  bet1Label;
  bet2Label;
  nflTeams;
  nbaTeams;
  mlbTeams;
  modelCreationDate;
  constructor(private leagueService: LeagueService, private modelService: ModelService, private modelPickerService: ModelPickerService, private userListService: UserListService, private route: Router, private authenticationService: AuthenticationService, private userService: UserService) { 
    this.top3Games = new Array();
    this.modelPerformance = {};
    this.nflTeams = this.leagueService.getTeams("NFL");
    this.nbaTeams = this.leagueService.getTeams("NBA");
    this.mlbTeams = this.leagueService.getTeams("MLB");
  }

  ngOnInit(): void {
    console.log("Model loaded into model overview: ", this.model);
    if (this.model.modelConfiguration) {
      console.log("Model configuration does exist?");
      if (typeof this.model.modelConfiguration == "string") {
        console.log("And it was a string!");
        this.modelConfiguration = JSON.parse(this.model.modelConfiguration);
      } else {
        console.log("It was a JSON object!");
        this.modelConfiguration = this.model.modelConfiguration;
      }
      this.modelDescription = this.modelConfiguration.modelDescription;
    } else {
      //console.log("Model config doesn't exist, using: " + this.model.modelDescription);
      this.modelDescription = this.model.modelDescription;
    }
    this.modelSubscribers = this.model.subscribers;
    this.updateSubscriberText();

    this.authenticationService.currentUser.subscribe(user => {
      if (user && user.username) {
        this.currentUser = user;

        //check to see if the current user either owns or follows the current model.
  
        this.userOwnsModel = (this.model.userCreatedBy == this.currentUser.username);
  
        this.userFollowsModel = (this.model.subscribers.includes(this.currentUser.username));
  
        //console.log("User owns model: ", this.userOwnsModel);
        //console.log("User follows model: ", this.userFollowsModel);
      }
    })

    if (this.model.creationTimestamp) {
      var date = new Date(this.model.creationTimestamp);
      //console.log("Date:", date);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      this.modelCreationDate = month + "/" + day + "/" + year;
    }

    //console.log('Model performance: ' + this.modelPerformance);
    if (this.model.modelPerformance) {
      if (typeof this.model.modelPerformance == "string") {
        this.ddbModelPerformance = JSON.parse(this.model.modelPerformance);
      } else {
        this.ddbModelPerformance = this.model.modelPerformance;
      }
    }

    if (this.ddbModelPerformance && this.ddbModelPerformance.overallProfitability != undefined) {
      //we need to grab the bet, the winning % and the units.
      //console.log("Filling out model performance....")
      var overallProfitability = this.ddbModelPerformance.overallProfitability;
      //console.log("Overall profitability: ", overallProfitability)
      for (var i = 0; i < overallProfitability.length; i ++) {
        var betTaken = overallProfitability[i];
        var betType = betTaken['bet'];
        var wins = betTaken['wins'];
        var losses = betTaken['losses'];
        var pushes = betTaken['pushes'];
        var units = Math.round(betTaken["units"]);
        var winPercentage = Math.round(Number(wins/(wins+losses)) * 100);

        var obj = {
          "winPercentage": winPercentage,
          "units": units
        }

        this.modelPerformance[betType] = obj;
      }
    }

    //console.log("Model performance object: ", this.modelPerformance);

    if (this.model.top3Games) {
      var top3Games;
      if (typeof this.model.top3Games == "string") {
        top3Games = JSON.parse(this.model.top3Games);
      } else {
        top3Games = this.model.top3Games;
      }
      // games = games.sort(function compare(gameA, gameB) {

      //   var gameAGame = gameA[2] != undefined ? gameA[2] : gameA[1]
      //   var gameBGame = gameB[2] != undefined ? gameB[2] : gameB[1]

      //   var gameADate = new Date(gameAGame['gameData']['gameDate']);
      //   var gameBDate = new Date(gameBGame['gameData']['gameDate']);
       
      //   ////console.log("Game a - game b: ", Number(Number(gameADate.getMilliseconds()) - Number(gameBDate.getMilliseconds())))
      //   return Number(Number(gameADate.getTime()) - Number(gameBDate.getTime()));
      // })

      // var first3Games = [games[0], games[1], games[2]];

      /*
        [
          {
            "team1Name": ravens,
            "team1Logo": ravens.png,
            "team2Name": patriots,
            "team2Logo": patriots.png,
            "date": 2/12/2021,
            "bet1": win,
            "bet2": lose
          }
        ]
      */
 
      for (var x = 0; x < top3Games.length; x ++) {
        var game = top3Games[x];

        var homeTeam = game['homeTeam'];
        var awayTeam = game['awayTeam'];
        var gameDate = game['gameDate'];
        var bets = game['bets'];

        //console.log("Game: " + game)
        //console.log("Date: " + gameDate);

        // var moneyLine = bets['MONEY_LINE'];
        // var spread = bets['SPREAD'];
        // var over = bets['OVER'];
        // var under = bets['UNDER'];

        var betsTaken: String[] = [];
        Object.keys(bets).forEach(key => {
          //console.log("Key: ", key);
          betsTaken.push(key);
        });

        this.bet1 = betsTaken[0];

        if (betsTaken[1] != undefined) {
          this.bet2 = betsTaken[1];
        }

        this.bet1Label = this.bet1.toLowerCase().replace("_", " ");
        var bet1Win = bets[this.bet1];
        var bet2Win;
        if (this.bet2 != undefined) {
          bet2Win = bets[this.bet2];
          this.bet2Label = this.bet2.toLowerCase().replace("_", "")
        }

        var newObj = {
          "homeTeamName": this.getTeamAbbreviation(homeTeam),
          "homeTeamLogo": this.getTeamLogo(homeTeam),
          "awayTeamName": this.getTeamAbbreviation(awayTeam),
          "awayTeamLogo": this.getTeamLogo(awayTeam),
          "date": gameDate,
          "bet1": bet1Win
        }

        if (bet2Win != undefined) {
          newObj['bet2'] = bet2Win;
        }

        this.top3Games.push(newObj);

      }
      //console.log("Top 3 games: ", this.top3Games);


    }
  }

  followModel() {
    //console.log("Trying to follow model: ", this.model);
    if (this.currentUser && this.currentUser.username) {
      this.userService.subscribeToModel(this.currentUser, this.model.modelId).subscribe(data => {
        //console.log("Success subscribing model: ", data);
        this.modelSubscribers.push(this.currentUser.username);
        this.updateSubscriberText();
        this.userFollowsModel = true;
        //console.log("Now need to update models subscribed by user.");
        this.userService.addLocalModelSubscribedByUser(this.model);
      }, error => {
        //console.log("Error subscribing to model: ", error);
      })
    } else {
      this.route.navigate(['/signin'])
    }
  }

  unfollowModel() {
    //console.log("Trying to unfollow model: ", this.model);
    this.userService.unsubscribeFromModel(this.currentUser, this.model.modelId).subscribe(data => {
      //console.log("Success unsubscribing from model: " , data);
      this.userFollowsModel = false;
      var index = this.modelSubscribers.indexOf(this.currentUser.username);
      this.modelSubscribers.splice(index, 1);
      this.updateSubscriberText();

      //console.log("Now we need to update the models subscribed by user and remove.");
      this.userService.deleteLocalModelSubscribedByUser(this.model);
    }, error => {
      //console.log("Error unsubscribing from model: ", error);
    })
  }

  editModel() {
    console.log("Setting model to edit!: ", this.modelConfiguration);
    this.modelPickerService.setModelToEdit(this.modelConfiguration, false);
    this.modelPickerService.setModelIdToEdit(this.model.modelId);
    this.route.navigate(['/model-picker']);
  }

  deleteModel() {
    if (confirm("Are you sure you would like to delete this model?")) {
      this.userService.deleteLocalModelCreatedByUser(this.model);
      this.modelService.deleteModel(this.currentUser, this.model.modelId).subscribe(data => {
        //console.log("Successful delete of model?: ", data);
        this.userService.deleteLocalModelCreatedByUser(this.model);
        //console.log("model has been removed from local models created.");
      }, error => {
        //console.log("Error deleting model: ", error);
      })
    }
  }

  updateSubscriberText() {
    if (! this.modelSubscribers) {
      this.modelSubscriberCountText = "0 Followers";
    } else {
      if (this.modelSubscribers.length == 1) {
        this.modelSubscriberCountText = "1 Follower";
      } else {
        this.modelSubscriberCountText = this.modelSubscribers.length + " Followers";
      }  
    }
  }


  getBet(betObj) {
    //console.log("Getting bet result for: ", betObj);
    var keys;
    Object.keys(betObj).forEach(key => {
      //console.log("Key: ", key);
      keys = key;
    })

    return keys;
  }

  getTeamAbbreviation(team) {
    return team;
  }

  getTeamLogo(team) {
    if (this.model.league == "NFL") {
      return this.nflTeams[team]['logo'];
    } else if (this.model.league == "NBA") {
      return this.nbaTeams[team]['logo'];
    } else if (this.model.league == "MLB") {
      return this.mlbTeams[team]['logo'];
    }
  }

  viewModelSubscribers() {
    //console.log("Viewing model subscribers");
    this.userListService.setText("Model Subscribers");
    this.userListService.setUserList(this.modelSubscribers);
    this.route.navigate(['/user-list'])
  }
}
