<div class="grid-container" *ngIf="bets && overallPerformance">
    <div class="circle1">
        <app-model-detail-performance-circle-view [color]="colors[0]" [value]="overallPerformance[0].winPercentage" [units]="overallPerformance[0].units" [name]="overallPerformance[0].bet"></app-model-detail-performance-circle-view>
    </div>
    <div class="circle2" *ngIf="overallPerformance[1]">
        <app-model-detail-performance-circle-view [color]="colors[1]" [value]="overallPerformance[1].winPercentage" [units]="overallPerformance[1].units" [name]="overallPerformance[1].bet"></app-model-detail-performance-circle-view>
    </div>
    <div class="betsTaken">
        <!-- <p>Bets taken: {{betsTakenString}}</p> -->
        <p>{{overallPerformance[0].bet}}: {{overallPerformance[0].wins}}-{{overallPerformance[0].losses}}-{{overallPerformance[0].pushes}}</p>
        <p *ngIf="overallPerformance[1]">{{overallPerformance[1].bet}}: {{overallPerformance[1].wins}}-{{overallPerformance[1].losses}}-{{overallPerformance[1].pushes}}</p>
    </div>
    <div class='individualSeason' *ngIf="chartComplete">
        <div style="display: block;">
            <canvas baseChart id="performanceChart"
                    [datasets]="chartData"
                    [labels]="chartLabels"
                    [options]="chartOptions"
                    [legend]="chartLegend"
                    [chartType]="chartType"
                    height="250"
                    width="200">
            </canvas>
        </div>
  </div>
</div>

<div *ngIf="!bets || !overallPerformance">
    No games found!
</div>