import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LeagueSelectorComponent } from './components/league-selector/league-selector.component';
import { TimeRangeSelectorComponent } from './components/time-range-selector/time-range-selector.component';
import { BetsTakenSelectorComponent } from './components/bets-taken-selector/bets-taken-selector.component';
import { WagerSelectorComponent } from './components/wager-selector/wager-selector.component';
import { DescriptionSelectorComponent } from './components/description-selector/description-selector.component';
import { HomeFeedComponent } from './components/home-feed/home-feed.component';
import { ModelOverviewComponent } from './components/model-overview/model-overview.component';
import { ModelDetailViewComponent } from './components/model-detail-view/model-detail-view.component';
import { ModelDetailMetadataViewComponent } from './components/model-detail-metadata-view/model-detail-metadata-view.component';
import { ModelDetailTabViewComponent } from './components/model-detail-tab-view/model-detail-tab-view.component';
import { ModelDetailRequirementsViewComponent } from './components/model-detail-requirements-view/model-detail-requirements-view.component';
import { ModelDetailPerformanceViewComponent } from './components/model-detail-performance-view/model-detail-performance-view.component';
import { ModelDetailHistoryViewComponent } from './components/model-detail-history-view/model-detail-history-view.component';
import { EditModelMetadataViewComponent } from './components/edit-model-metadata-view/edit-model-metadata-view.component';
import { EditModelRequirementsViewComponent } from './components/edit-model-requirements-view/edit-model-requirements-view.component';
import { RequirementDetailViewComponent } from './components/requirement-detail-view/requirement-detail-view.component';
import { RequirementPickerComponent } from './components/requirement-picker/requirement-picker.component';
import { RequirementCategoryPickerComponent } from './components/requirement-category-picker/requirement-category-picker.component';
import { RequirementHomeAwayPickerComponent } from './components/requirement-home-away-picker/requirement-home-away-picker.component';
import { RequirementFavoriteUnderdogPickerComponent } from './components/requirement-favorite-underdog-picker/requirement-favorite-underdog-picker.component';
import { RequirementGameVariablesPickerComponent } from './components/requirement-game-variables-picker/requirement-game-variables-picker.component';
import { RequirementSpecificTeamsPickerComponent } from './components/requirement-specific-teams-picker/requirement-specific-teams-picker.component';
import { RequirementStatsPickerComponent } from './components/requirement-stats-picker/requirement-stats-picker.component';
import { RequirementGameVariableCategoryPickerComponent } from './components/requirement-game-variable-category-picker/requirement-game-variable-category-picker.component';
import { RequirementStatComparisonPickerComponent } from './components/requirement-stat-comparison-picker/requirement-stat-comparison-picker.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { ProfileDetailViewComponent } from './components/profile-detail-view/profile-detail-view.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignOutComponent } from './components/sign-out/sign-out.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { ModelPickerComponent } from './components/model-picker/model-picker.component';
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { RequirementOverviewComponent } from './components/requirement-overview/requirement-overview.component';
import { ModelPickerService } from './services/model-picker.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import {MatTabsModule} from '@angular/material/tabs';
import { ModelDetailGameViewComponent } from './components/model-detail-game-view/model-detail-game-view.component';
import { ModelDetailPerformanceCircleViewComponent } from './components/model-detail-performance-circle-view/model-detail-performance-circle-view.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatDividerModule} from '@angular/material/divider';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule, MatIcon} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import { ChartsModule } from 'ng2-charts';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { GameDetailsDialogComponent } from './components/game-details-dialog/game-details-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { ExtraFieldComponent } from './components/extra-field/extra-field.component';
import { RequirementStatBooleanPickerComponent } from './components/requirement-stat-boolean-picker/requirement-stat-boolean-picker.component';
import { UpcomingGamesForModelComponent } from './components/upcoming-games-for-model/upcoming-games-for-model.component';
import { UpcomingGamesPopupComponent } from './components/upcoming-games-popup/upcoming-games-popup.component';
import { HomePageGameTabViewComponent } from './components/home-page-game-tab-view/home-page-game-tab-view.component';
import { UpcomingGamesListComponent } from './components/upcoming-games-list/upcoming-games-list.component';
import { LiveGamesListComponent } from './components/live-games-list/live-games-list.component';
import { LiveGamesPopupComponent } from './components/live-games-popup/live-games-popup.component';
import { ModelDetailPerformanceTabViewComponent } from './components/model-detail-performance-tab-view/model-detail-performance-tab-view.component';
import { RequirementStatStringComparisonPickerComponent } from './components/requirement-stat-string-comparison-picker/requirement-stat-string-comparison-picker.component';
import { StatStandingsPopupComponent } from './components/stat-standings-popup/stat-standings-popup.component';
import { StatStandingsRowComponent } from './components/stat-standings-row/stat-standings-row.component';
import { ModelDetailPerformanceViewOldComponent } from './components/model-detail-performance-view-old/model-detail-performance-view-old.component';

@NgModule({
  declarations: [
    AppComponent,
    LeagueSelectorComponent,
    TimeRangeSelectorComponent,
    BetsTakenSelectorComponent,
    WagerSelectorComponent,
    DescriptionSelectorComponent,
    HomeFeedComponent,
    ModelOverviewComponent,
    ModelDetailViewComponent,
    ModelDetailMetadataViewComponent,
    ModelDetailTabViewComponent,
    ModelDetailRequirementsViewComponent,
    ModelDetailPerformanceViewComponent,
    ModelDetailHistoryViewComponent,
    EditModelMetadataViewComponent,
    EditModelRequirementsViewComponent,
    RequirementDetailViewComponent,
    RequirementPickerComponent,
    RequirementCategoryPickerComponent,
    RequirementHomeAwayPickerComponent,
    RequirementFavoriteUnderdogPickerComponent,
    RequirementGameVariablesPickerComponent,
    RequirementSpecificTeamsPickerComponent,
    RequirementStatsPickerComponent,
    RequirementGameVariableCategoryPickerComponent,
    RequirementStatComparisonPickerComponent,
    NavBarComponent,
    ProfileDetailViewComponent,
    SignUpComponent,
    SignInComponent,
    SignOutComponent,
    HomePageComponent,
    ModelPickerComponent,
    RequirementOverviewComponent,
    ModelDetailGameViewComponent,
    ModelDetailPerformanceCircleViewComponent,
    UserListComponent,
    GameDetailsDialogComponent,
    ExtraFieldComponent,
    RequirementStatBooleanPickerComponent,
    UpcomingGamesForModelComponent,
    UpcomingGamesPopupComponent,
    HomePageGameTabViewComponent,
    UpcomingGamesListComponent,
    LiveGamesListComponent,
    LiveGamesPopupComponent,
    ModelDetailPerformanceTabViewComponent,
    RequirementStatStringComparisonPickerComponent,
    StatStandingsPopupComponent,
    StatStandingsRowComponent,
    ModelDetailPerformanceViewOldComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatTabsModule,
    NgbModule,
    MatDividerModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    ChartsModule,
    MatSidenavModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatDialogModule
  ],
  providers: [ModelPickerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
