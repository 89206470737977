import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Pikit';
  // user: CognitoUserInterface | undefined;
  // authState: AuthState;
  currentUser: any;
  
  constructor(private route: Router, private authenticateService: AuthenticationService) {
    this.authenticateService.currentUser.subscribe(x => {
      this.currentUser = x; 
      //console.log("Current user in app component: " + x)
    });
  }
}
