import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  modelsUserCreated: [any];
  modelsUserSubscribesTo: [any];

  constructor(private http: HttpClient) { }

  /*
      When we retrieve models from the database, we should save them in the service so we can easily
      retrieve them.
  */
  // saveModelsCreatedByUser(models) {
  //   //console.log("Saving models: " + models);

  //   this.modelsUserCreated = models;
  // }

  // // addModelCreatedByUser(model) {
  // //   this.modelsUserCreated.push(model);
  // // }

  // saveModelsUserSubscribesTo(models) {
  //   this.modelsUserSubscribesTo = models;
  // }

  createModel(user, model) {
    //console.log("inside create model.");
    var headers = {
      "Authorization": user.signInUserSession.idToken.jwtToken
    }

    var userId = user.username;


    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/users/" + userId + "/models";

    return this.http.post<any>(url, model, {headers});
  }

  updateModel(user, modelId, model) {
    var headers = {
      "Authorization": user.signInUserSession.idToken.jwtToken
    }

    if (model.userCreatedBy) {
      delete model['userCreatedBy'];
    }

    if (model.modelPerformance) {
      delete model['modelPerformance'];
    }

    if (model.top3Games) {
      delete model['top3Games'];
    }

    if (model.gamesStored) {
      delete model['gamesStored'];
    }
    
    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/models/" + modelId;

    return this.http.post<any>(url, model, {headers});
  }

  getModel(modelId) {

    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/models/" + modelId;

    return this.http.get<any>(url, {});
  }

  deleteModel(user, modelId) {
    var headers = {
      "Authorization": user.signInUserSession.idToken.jwtToken
    }

    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/models/" + modelId;

    return this.http.delete<any>(url, {headers});
  }
  

  getModelSubscribers(modelId) {

    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/models/" + modelId + "/followers";

    return this.http.get<any>(url, {});
  }

  getUpcomingGamesThatMeetModel(modelId) {

    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/models/" + modelId + "/upcominggames";

    return this.http.get<any>(url, {});
  }

  getModelHistory(modelId) {


    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/models/" + modelId + "/history";

    return this.http.get<any>(url, {});
  }

  runModel(user, modelId) {
    var headers = {
      "Authorization": user.signInUserSession.idToken.jwtToken
    }

    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/models/" + modelId + "/run";
    return this.http.post<any>(url, {}, {headers});
  }

  /*
      This method should be called when routing to a specific moodel and we want to retrieve
      the high level data of that model to display to the user.
  */
  getModelDetails(modelId) {
    //console.log("Trying to retrieve model details for id: " , modelId);
    // if (this.modelsUserCreated != null) {
    //   for (var i = 0; i < this.modelsUserCreated.length; i ++) {
    //     var model = this.modelsUserCreated[i];
    //     if (model.modelId == modelId) {
    //       return model;
    //     }
    //   }

    // }

    // if (this.modelsUserSubscribesTo != null) {
    //   for (var i = 0; i < this.modelsUserSubscribesTo.length; i ++) {
    //     var model = this.modelsUserSubscribesTo[i]; 
    //     if (model.modelId == modelId) {
    //       return model;
    //     }
    //   }
    // }

    //lets try searching for it instead.
  }

  getGamesThatMeetModel(modelId, season) {

    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/models/" + modelId + "/games/" + season;

    return this.http.get<any>(url, {});
  }

  getLatestModels(dateStart, timeStart) {

    var body = {
      'dateStart': dateStart,
      'timeStart': timeStart
    }

    var url = "https://vk2bvza2qg.execute-api.us-east-1.amazonaws.com/Pikit/models/recent";
    return this.http.post<any>(url, body, {});
  }
}
