<div *ngIf="requirement">
    <div class="grid-container">
        <div class="description">
            <p>{{requirement.description}}</p>
        </div>
        <div class="icon" *ngIf='canDelete'>
            <button class="menuButton" mat-icon-button [matMenuTriggerFor]="menu"  aria-label="Example icon-button with a menu">
                <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item class='editMenu' (click)="editRequirement()">
                    <mat-icon class="blackIcon">edit</mat-icon >
                    <span>Edit</span>
                </button>
                <button mat-menu-item class='deleteMenu' (click)="deleteRequirement()">
                    <mat-icon class="redIcon">delete_outline</mat-icon>
                    <span>Delete</span>
                </button>
            </mat-menu>
        </div>

        <div class="icon" *ngIf='!canDelete && creatingModel'>
            <button class="deleteOverview" (click)="deleteRequirement()">
                <mat-icon class="redIcon">delete_outline</mat-icon>
            </button>
        </div>
    </div>
    <!-- <div class='collapsible'>
        <p>{{requirement.description}}</p>
        <mat-icon class='iconCollabsible' (click)="toggleRequirement(requirement)">more_horiz</mat-icon>
    </div> -->
    <!-- <button class='collapsible' (click)="toggleRequirement(requirement)">{{requirement.description}}</button> -->
    <!-- <div class="content" id={{requirement.description}} *ngIf="userLoggedIn.username == modelDetails.userCreatedBy">
        <button *ngIf="modelAlreadyCreated" (click)="editRequirement(index)">Modify</button>
        <button (click)="deleteRequirement(requirement)">Delete</button>
    </div> -->
</div>