<div class="container">
    <div class="title">
        {{statToCompare.statName}}
    </div>
    <div class="myTeam">
        <button (click)="selectMyTeam()" id="myTeamButton">The team I am betting on</button>
    </div>
    <div class="otherTeam">
        <button (click)="selectOpposingTeam()" id="opposingTeamButton">The other team</button>
    </div>
    <div class="statDescription">
        {{statToCompare.statName}}
    </div>
    <div class="yes">
        <button (click)="selectYes()" id="yesButton">Yes</button>
    </div>
    <div class="no">
        <button (click)="selectNo()" id="noButton">No</button>
    </div>
    <div class="submit">
        <button (click)="saveRequirement()">Save Requirement</button>
    </div>
  </div>