import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  signstatus: string = 'signin'
  toVerifyEmail: boolean = false;
  userName: string;

  constructor(private userService: UserService, private route: Router, private authenticateService: AuthenticationService) { }

  ngOnInit(): void {
  }

  onSignUp() {
    this.signstatus = 'signup';
  }

  onSignIn() {
    this.signstatus = 'signin';
  }

  signInToAWS(username: HTMLInputElement, password: HTMLInputElement ) {

    this.authenticateService.login(username.value, password.value)
      .pipe(first()).subscribe(data => {
        //console.log("User has logged in: ", data);

        this.userService.getUserInfo(data.username).subscribe(info => {
          //console.log("Got user info after logging in: ", info);
          if (info.firstName == null && info.lastName == null) {
            //we need to create the user in our database.
            this.userService.createUser(data).subscribe(newUser => {
              //console.log("new user created: ", newUser);
            }, error => {
              //console.log("error creating new user: ", error);
            })
          }
        }, error => {
          //console.log("Error getting user info: ", error);
        })

        this.route.navigate(['/']);
      }, error => {
        //console.log("Error logging in: ", error);
        window.alert(error.message);
      })
  }

}
