<ng-container *ngIf="gamesStored && gamesStored.length > 0">
    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" opened="true">
          <div class="sideNavSeason" *ngFor="let season of gamesStored" id={{season}} (click)="toggleSeason(season)">
              <span class="seasonItem">{{season}}</span>
          </div>
        </mat-drawer>
        <div class="example-sidenav-content">
            <div *ngIf="seasonShowing">
                <div *ngIf="gamesMap[seasonShowing]">
                    <app-model-detail-performance-view-old [betsTaken]="gamesMap[seasonShowing].betsTaken" [bets]="gamesMap[seasonShowing].bets" [overallPerformance]="gamesMap[seasonShowing].overallPerformance"></app-model-detail-performance-view-old>
                </div>
            </div>
        </div>
      </mat-drawer-container>
</ng-container>
<div *ngIf="!gamesStored || !gamesMap[seasonShowing]">
    <div class='spinner' *ngIf="!performanceLoaded">
        <mat-spinner></mat-spinner>
    </div>
</div>

<ng-container *ngIf="gamesStored && gamesStored.length == 0">
    <br>
    No games meet model requirements!
</ng-container>