import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import * as CanvasJs  from '../../canvasjs.min.js';
import { Observable } from 'rxjs';
import { Chart, ChartType } from 'chart.js';

@Component({
  selector: 'app-model-detail-performance-view',
  templateUrl: './model-detail-performance-view.component.html',
  styleUrls: ['./model-detail-performance-view.component.css']
})
export class ModelDetailPerformanceViewComponent implements OnInit {

  modelDetails;
  modelPerformance;
  chart;
  betsTaken;
  modelBetsTaken;
  betsTakenString;
  wagerString
  colors;
  performanceLoaded;
  hasGames;
  seasonSelected;

  public chartLabels = new Array();
  public chartType: ChartType = 'line';
  public chartLegend = true;
  public chartData;
  public chartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{ offset:true, }],
      yAxes: [{
        ticks: {
          stepSize: 5,
          callback: function(value, index, values) {
            return value + '%';
        }
        },
        scaleLabel: {
          display: true,
          labelString: 'Win Percentage'
        }
      }]
    }
  }

  public chartComplete = false;
  @Input() modelInput?

  constructor(private modelPickerService: ModelPickerService) {
    this.colors = ["dodgerblue", "limegreen"]
    this.performanceLoaded = false;
   }
  

  ngOnInit(): void {

    this.modelPickerService.currentModel.subscribe(model => {
      console.log("Model found in performance view: ", model);
      if (model.modelConfiguration) {
        this.modelDetails = model.modelConfiguration;
      } else {
        this.modelDetails = model;
      }

      var wager = model.wager;
      if (wager == "TO_WIN") {
        this.wagerString = "to win";
      } else if (wager == "TO_WAGER") {
        this.wagerString = "to wager";
      }

      var modelPerformance = model.modelPerformance;
      if (modelPerformance) {
        if (modelPerformance.overallProfitability.length > 0) {
          this.hasGames = true;
        } else {
          this.hasGames = false;
        }
        this.modelPerformance = modelPerformance; 
        this.createOverallProfitabilityCharts();
        this.createIndividualSeasonProfitabilityChart();
        this.performanceLoaded = true;
      } else {
        this.performanceLoaded = false;
      }
    })
  }

  initChart() {
    var betDataPoints = new Map();

    if (this.betsTaken) {
      //console.log("Bets have been taken!");
      for(var i = 0; i < this.betsTaken.length; i ++) {
        var bet = this.betsTaken[i];
        betDataPoints.set(bet.bet, new Array());
      }
    }
    var performance = this.modelPerformance.individualSeasonProfitability;

    var seasons: any[] = [];
    Object.keys(performance).forEach(key => {
      seasons.push(key);
    })

    seasons = seasons.sort();

    seasons.forEach(season => {
      var betsForSeason = performance[season];
      var year = season;
      this.chartLabels.push(new Date(Number(year), 0).getFullYear());

      for (var y = 0; y < betsForSeason.length; y ++) {
        var betObject = betsForSeason[y];
        var bet = betObject.bet;
        var wins = betObject.wins;
        var losses = betObject.losses;
        var pushes = betObject.pushes;
        var winPercentage = Math.round(Number(wins/(wins+losses)) * 100);
        var arrayToAddPointTo = betDataPoints.get(bet);
        arrayToAddPointTo.push(winPercentage);
      } 
    });

    var data = new Array();
    var betsTaken = this.modelDetails.betsTaken;
    for(var i = 0; i < betsTaken.length; i ++) {
      var bet = betsTaken[i];
      var json = {
        data: betDataPoints.get(bet),
        label: bet,
        fill: false,
        borderColor: this.colors[i],
        pointBackgroundColor: this.colors[i]
      }
      data.push(json);
    }

    this.chartData = data;
    this.chartComplete = true;

  }

  createOverallProfitabilityCharts() {
    this.betsTaken = new Array();

    var bets = this.modelPerformance.overallProfitability;
    for (var i = 0; i < bets.length; i ++) {
      var betObj = {}
      var bet = bets[i];
      var wins = bet.wins;
      var losses = bet.losses;
      var pushes = bet.pushes;
      var units = bet.units;

      var winPercentage = Math.round(Number(wins/(wins+losses)) * 100);
      betObj["bet"] = bet.bet;
      betObj["winPercentage"] = winPercentage;
      betObj["units"] = Math.round(units);
      betObj["wins"] = wins;
      betObj["losses"] = losses;
      betObj["pushes"] = pushes;
      this.betsTaken.push(betObj);   
    }
  }

  createIndividualSeasonProfitabilityChart() {
    var self = this;
    setTimeout(function() {
      self.initChart();
    }, 100)
  }

  toggleSeason(season) {
    this.seasonSelected = season;
  }
}
