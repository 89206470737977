<div *ngIf="modelDetails">
    <mat-card class="example-card">
        <div class="mat-card-header-div">
          <div class="header-container">
            <div mat-card-avatar class="example-header-image avatar"></div>
            <div class="name">
                <mat-card-title routerLink="/users/{{modelDetails.userCreatedBy}}">@{{modelDetails.userCreatedBy}}</mat-card-title>
            </div>
            <div class="header-followers">
                <button class="card-button" mat-stroked-button (click)="viewModelSubscribers()">{{modelSubscriberCountText}}</button>
            </div>
            <div class="header-date">{{modelCreationDate}}</div>
            <div class="edit" *ngIf="userOwnsModel">
                <button mat-button (click)="editModel()">
                    <mat-icon>edit</mat-icon>
                    Edit
                </button>
            </div>
            <div class="followOrUnfollow" *ngIf="!userOwnsModel">
                <div class="follow" *ngIf="!userFollowsModel">
                    <button mat-button (click)="followModel()">Follow</button>
                </div>
                <div class="unfollow" *ngIf="userFollowsModel">
                    <button mat-button (click)="unfollowModel()">Following</button>
                </div>
            </div>
            <div class='run' *ngIf="userOwnsModel">
                <button mat-mini-fab (click)="runModel()"class='runButton'>
                    <mat-icon>play_arrow</mat-icon>
                </button>
            </div>
          </div>
        </div>
        <mat-card-content>
            <div class="modelDescription"><p>{{modelDetails.modelDescription}}</p></div>

            <div class="modelDetailTab">
                <app-model-detail-tab-view></app-model-detail-tab-view>
            </div>
        </mat-card-content>
    </mat-card>
</div>
