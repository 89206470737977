import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-live-games-popup',
  templateUrl: './live-games-popup.component.html',
  styleUrls: ['./live-games-popup.component.css']
})
export class LiveGamesPopupComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

}
