import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { Auth } from 'aws-amplify';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor() {
    this.currentUserSubject = new BehaviorSubject<any>({});
    Auth.currentAuthenticatedUser().then(data => {
      //console.log("User is logged in!"); 
      this.currentUserSubject.next(data);
    }).catch(error => { 
      //console.log("User not logged in"); return null;
    })
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    //console.log("current user in service: " + this.currentUserSubject.value);
    return this.currentUserSubject.value;
  }

  login(username, password) {
    var authInfo = {
      username: username,
      password: password
    }

    return from(Auth.signIn(authInfo)).pipe(map(user => {
      this.currentUserSubject.next(user);
      return user;
    }))
  }

  logout() {
    // remove user from local storage and set current user to null
    Auth.signOut();
    this.currentUserSubject.next(null);

  }
}
