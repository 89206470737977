import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-requirement-game-variable-category-picker',
  templateUrl: './requirement-game-variable-category-picker.component.html',
  styleUrls: ['./requirement-game-variable-category-picker.component.css']
})
export class RequirementGameVariableCategoryPickerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
