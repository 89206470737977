<div class="grid-container">
    <div class="allTime">
        <button class="timeRange" mat-raised-button (click)="selectAllTime()" id='allTime' selected>All Time</button>
    </div>
    <div class="thisSeason">
        <button class="timeRange" mat-raised-button (click)="selectThisSeason()" id='thisSeason'>This Season</button>
    </div>
    <div class="past5">
        <button class="timeRange" mat-raised-button (click)="selectPast5()" id='past5'>Past 5 Seasons</button>
    </div>
    <div class="custom">
        <button class="timeRange" *ngIf="league" (click)="selectCustom()" id='custom' disabled=true >{{customText}}</button>
        <button class="timeRange" *ngIf="league == null" (click)="selectPast10()" id='past10' disabled=true >Past 10 Seasons</button>
    </div>

    <div *ngIf="customTimeRangeSelected" class="customChoice">
        <label for="customTimeRange">Select start season:</label>
        <select class="form-control col-lg-8" name="customTimeRange" id="customTimeRange" [(ngModel)]="customTimeRange" (change)="selectCustomTimeRange()">
            <option *ngFor="let season of seasonsAvailableArray;" [ngValue]="season">{{season}}</option>
        </select>
    </div>
</div>