<div class="grid-container">
    <div class="title">Choose the Category of Your Requirement</div>
    <div class="homeAway">
        <button class="requirementCategory" mat-raised-button [routerLink]="['/home-away-picker']">
            <!-- <app-requirement-category-picker title="Home or Away" image="assets/images/house.png">
            </app-requirement-category-picker> -->
            <div class="button-container">
                <div class="home">Home</div>
                <div class="or">or</div>
                <div class="away">Away</div>
              </div>
        </button>
    </div>
    <div class="favoriteUnderdog">
        <button class="requirementCategory" mat-raised-button [routerLink]="['/favorite-underdog-picker']">
            <!-- <app-requirement-category-picker title="Favorite or Underdog" image="assets/images/underdog.jpeg">
            </app-requirement-category-picker> -->
            <div class="button-container">
                <div class="home">Favorite</div>
                <div class="or">or</div>
                <div class="away">Underdog</div>
              </div>
        </button>    
    </div>
    <!-- <div class="gameVariables" disabled=true>
        <button class="requirementCategory" mat-raised-button [routerLink]="['/game-variables-picker']">
            <app-requirement-category-picker title="Game Variables" image="assets/images/stopwatch.jpeg">
            </app-requirement-category-picker> -->
            <!-- Game Variables -->
        <!-- </button> -->
    <!-- </div> -->
    <div class="specificTeams">
        <button class="requirementCategory" mat-raised-button [routerLink]="['/specific-teams-picker']">
            <!-- <app-requirement-category-picker title="Specific Teams" image="assets/images/specificTeams.jpeg">
            </app-requirement-category-picker> -->
            Specific Teams
        </button>
    </div>
    <div class="stats">
        <button class="requirementCategory" mat-raised-button [routerLink]="['/stats-picker']">
            <!-- <app-requirement-category-picker title="Stats" image="assets/images/analytics.jpeg">
            </app-requirement-category-picker> -->
            Stats
        </button>
    </div>
  </div>