import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import * as CanvasJs  from '../../canvasjs.min.js';
import { Observable } from 'rxjs';
import { Chart, ChartType } from 'chart.js';

@Component({
  selector: 'app-model-detail-performance-view-old',
  templateUrl: './model-detail-performance-view-old.component.html',
  styleUrls: ['./model-detail-performance-view-old.component.css']
})
export class ModelDetailPerformanceViewOldComponent implements OnInit {

  @Input() betsTaken;
  @Input() bets;
  @Input() overallPerformance;

  colors;

  public chartLabels = new Array();
  public chartType: ChartType = 'line';
  public chartLegend = true;
  public chartData;
  public chartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{ offset:true, }],
      yAxes: [{
        ticks: {
          stepSize: 5,
          callback: function(value, index, values) {
            return value + '%';
        }
        },
        scaleLabel: {
          display: true,
          labelString: 'Win Percentage'
        }
      }]
    }
  }

  public chartComplete = false;

  constructor() {
    this.colors = ["dodgerblue", "limegreen"]
   }

  ngOnInit(): void {
    console.log("Loading performance detail view (OLD)");
    console.log("Bets: " + this.bets);
    console.log("Overall performance: " + this.overallPerformance);
    this.createChart();
  }

  ngOnChanges(): void {
    console.log("Changes have occured.");
    console.log("Bets taken: " + this.betsTaken);
    this.createChart();
  }

  createChart() {
    this.chartComplete = false;
    this.chartData = [];
    this.chartLabels = [];
    var betDataPoints = new Map();
    if (! this.betsTaken) {
      console.log("Bets taken not loaded yet");
      return;
    }
    
    for(var i = 0; i < this.betsTaken.length; i ++) {
      var bet = this.betsTaken[i];
      var winPercentagesForBet: any[] = [];
      var games = this.bets[bet];

      games.forEach((game) => {
        if (i == 0) {
          this.chartLabels.push(game.gameDate);
        }
        winPercentagesForBet.push(game.winPercentage);
      });

      betDataPoints.set(bet, winPercentagesForBet);
    }

    var data = new Array();

    for(var i = 0; i < this.betsTaken.length; i ++) {
      var bet = this.betsTaken[i];
      var json = {
        data: betDataPoints.get(bet),
        label: bet,
        fill: false,
        borderColor: this.colors[i],
        pointBackgroundColor: this.colors[i]
      };

      data.push(json);
    };

    this.chartLabels.reverse();
    this.chartData = data;
    this.chartComplete = true;
  }
}
