import { Component, OnInit } from '@angular/core';
import { ModelPickerService } from 'src/app/services/model-picker.service';

@Component({
  selector: 'app-wager-selector',
  templateUrl: './wager-selector.component.html',
  styleUrls: ['./wager-selector.component.css']
})
export class WagerSelectorComponent implements OnInit {

  constructor(private modelPickerService: ModelPickerService) { }

  ngOnInit(): void {
    this.modelPickerService.currentModel.subscribe(model => {
      var toWinButton = document.getElementById("TO_WIN");
      var toWagerButton = document.getElementById("TO_WAGER");

      if (toWinButton) {
        toWinButton.className = "wager";
      }

      if (toWagerButton) {
        toWagerButton.className = "wager";
      }

      var currentWager = model.wager;
      if (currentWager) {
        var wagerButton = document.getElementById(currentWager);
        if (wagerButton) {
          wagerButton.className = "wager buttonSelected";
        }
      }
    })
  }

  selectToWin() {
    this.modelPickerService.setModelWager("TO_WIN");
  }

  selectToWager() {
    this.modelPickerService.setModelWager("TO_WAGER");
  }

}
