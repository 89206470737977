import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModelPickerService } from 'src/app/services/model-picker.service';
import { UserService } from 'src/app/services/user.service';
import { ModelService } from 'src/app/services/model.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-home-feed',
  templateUrl: './home-feed.component.html',
  styleUrls: ['./home-feed.component.css']
})
export class HomeFeedComponent implements OnInit {

  recentModels = new Array();

  userLoggedIn;
  constructor(private route: Router, private modelPickerService: ModelPickerService, private userService: UserService, private modelService: ModelService, private http: HttpClient, private authenticateService: AuthenticationService) {
    
   }


  ngOnInit(): void {
    var todaysDate = new Date();
    //console.log("Todays date: ", todaysDate)
    todaysDate.getMilliseconds()
    var day = (todaysDate.getMonth() + 1) + "/" + todaysDate.getDate() + "/" + todaysDate.getFullYear();
    var currentTimestamp = Date.now();
    this.modelService.getLatestModels(day, currentTimestamp).subscribe(models => {
      //console.log("Models received: " , models)
      if (models != {}) {
        for (var x = 0; x < models.length; x ++) {
          this.recentModels.push(models[x]);
        }
      }
    }, error => {
      //console.log("Error retrieving recent models: ", error);
    })


    this.authenticateService.currentUser.subscribe(user => {
      this.userLoggedIn = user;
      //console.log("Current user in home page: " + user)

      if (user && user.username) {

        // this.userService.getModelsSubscribed(user, user.username).subscribe(models => {
        //   //var data = JSON.parse(response.models);
        //   //console.log("Models Subscribed found: " + JSON.stringify(models.models));
        //   this.modelsSubscribed = models.models;
        //   this.modelService.saveModelsUserSubscribesTo(models.models);
        //   //console.log("response.models", models.models);
        // },
        // error => {
        //   console.error('There was an error!', error);
        // })

        // this.userService.getModelsCreatedByUser(user, user.username).subscribe(models => {
        //   //console.log("Models created found: ", JSON.stringify(models.models));
        //   this.modelsCreated = models.models;
        //   this.modelService.saveModelsCreatedByUser(models.models);
        // })

        // this.userService.currentModelsCreated.subscribe(models => {
        //   if (models != {}) {
        //     //console.log("Models created loaded into home feed!!!!", models);
        //     this.modelsCreated = models
        //   }
        // })

        // this.userService.currentModelsSubscribed.subscribe(models => {
        //   if (models != {}) {
        //     //console.log("Models subscribed loaded into home feed!!", models);
        //     this.modelsSubscribed = models;
        //   }
        // })

        var todaysDate = new Date();
        //console.log("Todays date: ", todaysDate)
        todaysDate.getMilliseconds()
        var day = (todaysDate.getMonth() + 1) + "/" + todaysDate.getDate() + "/" + todaysDate.getFullYear();
        var currentTimestamp = Date.now();
        //console.log("Day: ", day);
        //console.log("Timestamp: " , Date.now());
        // this.modelService.getLatestModels(user, day, currentTimestamp).subscribe(models => {
        //   if (models != {}) {
        //     for (var x = 0; x < models.length; x ++) {
        //       this.recentModels.push(models[x]);
        //     }
        //   }
        // }, error => {
        //   //console.log("Error retrieving recent models: ", error);
        // })


        this.userService.currentModelsDeleted.subscribe(model => {
          if (model != {}) {
            //console.log("User has deleted a model. We should check to make sure we aren't displaying this model in the home feed.");
            var index = this.recentModels.indexOf(model);
            if (index != -1) {
              //console.log("Home feed contained a model that was just deleted.");
              this.recentModels.splice(index, 1);
            }
          }
        })


      } else {
        this.recentModels = [];
      }
    })
  }

}
