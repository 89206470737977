<mat-tab-group #tabGroup animationDuration="0ms" mat-align-tabs="center">
    <mat-tab label="MLB">
        <ng-template matTabContent>
            <div class='upcomingLiveGameTab'>
                <app-live-games-list league="MLB"></app-live-games-list>
                <app-upcoming-games-list league="MLB"></app-upcoming-games-list>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="NBA">
        <ng-template matTabContent>
            <div class='upcomingLiveGameTab'>
                <app-live-games-list league="NBA"></app-live-games-list>
                <app-upcoming-games-list league="NBA"></app-upcoming-games-list>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="NFL">
        <ng-template matTabContent>
            <div class='upcomingLiveGameTab'>
                <app-live-games-list league="NFL"></app-live-games-list>
                <app-upcoming-games-list league="NFL"></app-upcoming-games-list>
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>
